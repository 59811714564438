import { Card, Divider } from 'antd';

import { useAppSelector } from 'store';
import { questionnaireSelector } from 'store/QuestionnairesSlice/selectors/questionnaireSelector';

import { TestTimeForm } from './TestTimeForm';
import { Title } from './components/helperComponents';
import { SettingLinkForm } from './SettingLinkForm';

import styles from './Access.module.scss';

export const Access = () => {
  const questionnaire = useAppSelector(questionnaireSelector);
  const name = questionnaire.name;
  const settings = questionnaire.settings;

  return (
    <Card className={styles.rootCard} title={<Title>Время прохождения</Title>}>
      <TestTimeForm {...{ name, ...settings }} />
      <Divider />
      <SettingLinkForm />
    </Card>
  );
};
