import Qs from 'qs';
import axios, { AxiosResponse } from 'axios';
import { ResponseLoginData } from 'api/authApi/types';
import readFromLocalStorage from 'utils/readFromLocalStorage';
import { writeLocalStorage } from 'utils/writeLocalStorage';
import { toastify } from 'utils/toastify';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets' }),
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${JSON.parse(readFromLocalStorage('token')!)?.access_token || ''}`;
  return config;
});

// TODO: возможно потребуется request??
instance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 403 && error.config && !error.config._isRetry) {
      error.config._isRetry = true;

      try {
        const response = await axios.post<Omit<ResponseLoginData, 'access_token'>, AxiosResponse<ResponseLoginData>>(
          `${process.env.REACT_APP_API_URL}/auth/refresh`,
          {
            refresh_token: JSON.parse(readFromLocalStorage('token')!)?.refresh_token,
          },
        );

        writeLocalStorage<typeof response.data>('token', response.data);

        return instance.request(originalRequest);
      } catch (e) {
        toastify('error', 'Произошла ошибка при обновлении токена авторизации');
      }
    }

    throw error;
  },
);

export default instance;
