import { Controller } from 'react-hook-form';
import { Checkbox, Divider, InputNumber, Space } from 'antd';
import { QuestionnairesBlocksSettingsNumber } from 'api/questionnairesApi/types/number.types';

import styles from './NumberSettings.module.scss';

interface Props extends QuestionnairesBlocksSettingsNumber {
  control: any;
  errors: any;
}

const NumberSettings = ({ use_float, min_value, max_value, control, errors }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Controller
        control={control}
        render={({ field: { value, ...rest } }) => (
          <Checkbox className={styles.checkBox} {...rest} checked={value}>
            Разрешить вводить дробное число в поле
          </Checkbox>
        )}
        name="NUMBER.settings.use_float"
      />
      <Divider />

      <Space direction="vertical" size={16}>
        <Space size={8}>
          <span>Минимальная граница значений:</span>

          <Controller
            control={control}
            render={({ field }) => (
              <InputNumber
                {...field}
                status={!!errors?.NUMBER?.settings?.min_value ? 'error' : ''}
                min={0}
                max={100000}
              />
            )}
            name="NUMBER.settings.min_value"
          />
        </Space>
        <Space size={8}>
          <span>Максимальная граница значений:</span>
          <Controller
            control={control}
            render={({ field }) => (
              <InputNumber
                status={!!errors?.NUMBER?.settings?.max_value ? 'error' : ''}
                {...field}
                min={0}
                max={100000}
              />
            )}
            name="NUMBER.settings.max_value"
          />
        </Space>
        {!!errors?.NUMBER?.settings?.min_value?.message && <div>{errors?.NUMBER?.settings?.min_value?.message}</div>}
        {!!errors?.NUMBER?.settings?.max_value?.message && <div>{errors?.NUMBER?.settings?.max_value?.message}</div>}
      </Space>
    </div>
  );
};

export default NumberSettings;
