import { createSlice } from '@reduxjs/toolkit';
import directoriesAction from './actions/directoriesAction';
import dictionariesAdapter, { initialState } from './adapter';
import getDirectoryByIdAction from './actions/getDirectoryByIdAction';

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(directoriesAction.pending, dictionariesAdapter.getAllDirectoriesPending);
    builder.addCase(directoriesAction.fulfilled, dictionariesAdapter.getAllDirectoriesFullfilled);
    builder.addCase(directoriesAction.rejected, dictionariesAdapter.getAllDirectoriesRejected);

    builder.addCase(getDirectoryByIdAction.pending, dictionariesAdapter.getDirectoryByIdPending);
    builder.addCase(getDirectoryByIdAction.fulfilled, dictionariesAdapter.getDirectoryByIdFullfilled);
    builder.addCase(getDirectoryByIdAction.rejected, dictionariesAdapter.getDirectoryByIdRejected);
  },
});

export default dictionariesSlice.reducer;
