// Core
import { createBrowserRouter } from 'react-router-dom';
// Layouts
import { AuthLayout, FillFormLayout, RootLayout } from 'shared/layouts';

// Pages
import { LoginPage, NotFoundPage, QuestionnairesPage, RegisterPage } from 'pages';
import ProtectedRoute from 'shared/ProtectedRoute';
import { QuestionsPage } from 'pages/ItemQuestionnairesPage/Questions';
import { Results } from 'pages/ItemQuestionnairesPage/Results';
import { Access } from 'pages/ItemQuestionnairesPage/Access';
import store from 'store';
import getQuestionnaire from 'store/QuestionnairesSlice/actions/getQuestionnaire';
import DirectoriesPage from '../../../pages/DirectoriesPage';
import FillFormPage from '../../../pages/FillFormPage';
import getFormsAction from '../../../store/FillFormSlice/actions/getFormsAction';
import PromoPage from 'pages/PromoPage';
import { PromoLayout } from 'shared/layouts/PromoLayout';
import { ROUTES } from './routes';
import { PasswordRecoveryPage } from 'pages/PasswordRecoveryPage';

export const appRouter = createBrowserRouter([
  {
    path: ROUTES.home,
    element: <PromoLayout />,
    children: [
      {
        path: ROUTES.home,
        element: <PromoPage />,
      },
    ],
  },
  {
    path: ROUTES.home,
    element: (
      <ProtectedRoute>
        <RootLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: ROUTES.questionnaires,
        element: <QuestionnairesPage />,
        children: [
          {
            path: `${ROUTES.questionnaires}/:id/questions`,
            element: <QuestionsPage />,
            loader: (props) => {
              return store.dispatch(getQuestionnaire(props.params.id));
            },
          },
          {
            path: `${ROUTES.questionnaires}/:id/access`,
            element: <Access />,
            loader: (props) => {
              return store.dispatch(getQuestionnaire(props.params.id));
            },
          },
          {
            path: `${ROUTES.questionnaires}/:id/results`,
            element: <Results />,
            loader: (props) => {
              return store.dispatch(getQuestionnaire(props.params.id));
            },
          },
          {
            path: `${ROUTES.questionnaires}/:id/general_information`,
            element: (
              <>
                <div>generalInformation</div>
              </>
            ),
          },
        ],
      },
      {
        path: ROUTES.directories,
        element: <DirectoriesPage />,
      },
    ],
  },
  {
    path: ROUTES.auth,
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'register',
        element: <RegisterPage />,
      },
      {
        path: 'passwordRecovery',
        element: <PasswordRecoveryPage />,
      },
    ],
  },
  {
    path: ROUTES.fillForm,
    element: <FillFormLayout />,
    children: [
      {
        path: `${ROUTES.fillForm}/:id`,
        element: <FillFormPage />,
        loader: (props) => {
          return store.dispatch(getFormsAction({ key: props.params?.id || '' }));
        },
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);
