import { SortType } from 'types/sortType';
import { shuffleArray } from './shuffleArray';

export function sortArrayByType<TObj, TKey extends keyof TObj>(
  array: TObj[],
  sortKey: TKey,
  sortType: SortType = 'DEFAULT',
): TObj[] {
  switch (sortType) {
    case 'ALPHABET':
      return [...array].sort((a, b) => String(a[sortKey]).localeCompare(String(b[sortKey])));
    case 'RANDOM':
      return shuffleArray(array);
    default:
      return array;
  }
}
