import { useAppDispatch, useAppSelector } from '../../store';
import { fillFormQuestionsSelector } from '../../store/FillFormSlice/selectors';
import { Layout, Popconfirm, Row } from 'antd';
import Button from '../../components/Button';
import { useForm } from 'react-hook-form';
import MainCards, { OutletProps } from '../../components/questionnaires/MainCards';
import submitFormAction from '../../store/FillFormSlice/actions/submitFormAction';
import { useOutletContext, useParams } from 'react-router-dom';
import NotActive from './components/NotActive/NotActive';
import Submitted from './components/Submitted/Submitted';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const { Content } = Layout;

const createValidation = (fields: any[]) => {
  const arr = fields
    ?.filter((item: any) => !!item?.settings?.required)
    ?.map((item: any) => {
      // if ('max_length' in item.settings) {
      //   return { [item.id]: yup.string().required('Обязательное поле').max(item.max_length).min(item.min_length) };
      // }
      return { [item.id]: yup.string().required('Обязательное поле') };
    });
  const res = {};
  arr?.forEach((item) => Object.assign(res, item));
  return yup.object().shape(res);
};

const FillFormPage = () => {
  const { questions, isSubmitted } = useAppSelector(fillFormQuestionsSelector);
  const { fields: outletFields } = useOutletContext<OutletProps>();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createValidation(outletFields)),
  });
  const { id: key } = useParams();

  const dispatch = useAppDispatch();

  console.log('errors', errors);

  useEffect(() => {
    reset();
  }, [isSubmitted]);

  const onFormSubmit = (values: any) => {
    if (key) {
      dispatch(
        submitFormAction({
          key,
          formId: questions?.id,
          values: Object.entries(values).map(([key, value]) => ({
            form_block_id: key,
            value,
            form_answer_id: questions?.id,
          })),
        }),
      );
    }
  };

  const onResetForm = () => {
    reset();
  };

  const renderContent = () => {
    if (isSubmitted) {
      return <Submitted />;
    }

    if (Array.isArray(questions) && !questions?.length) {
      return <NotActive />;
    }

    return (
      <Content>
        {!questions?.length && (
          <MainCards
            setError={setError as any}
            clearErrors={clearErrors as any}
            isDraggable={false}
            control={control}
            setValue={setValue as any}
            isEditable={false}
          />
        )}
        <Row justify="end" style={{ marginTop: 40, gap: 8 }}>
          <Popconfirm title="Подтвердите действие" description="Очистить анкету?" onConfirm={onResetForm}>
            <Button size="large">Очистить анкету</Button>
          </Popconfirm>
          <Button
            disabled={!!Object.keys(errors).length}
            onClick={handleSubmit(onFormSubmit)}
            size="large"
            type="primary"
          >
            Отправить анкету
          </Button>
        </Row>
      </Content>
    );
  };

  return renderContent();
};

export default FillFormPage;
