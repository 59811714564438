import Button from 'components/Button';
import StatisticsImage from 'shared/assets/images/statistics.png';
import StatisticsImage2x from 'shared/assets/images/statistics@2x.png';
import SurveyImage from 'shared/assets/images/survey.png';
import SurveyImage2x from 'shared/assets/images/survey@2x.png';

import styles from './HeroSection.module.scss';
import InteractiveItem from './InteractiveItem';

const interactiveContent = [
  'Инфоблок',
  'Короткий текст',
  'Длинный текст',
  'Один вариант',
  'Несколько вариантов',
  'Выпадающий список',
  'Рейтинг',
];

const scrollToDicts = () => {
  const element = document.getElementById('directories');

  element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
};

const HeroSection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.statisticsContainer}>
          <img
            className={styles.statistics}
            height={367}
            width={621}
            src={StatisticsImage}
            alt="Статистика анкеты"
            srcSet={`${StatisticsImage2x} 2x`}
          />
        </div>
        <div className={styles.surveyContainer}>
          <div className={styles.surveyInner}>
            <img height={381} width={307} src={SurveyImage} alt="Опрос" srcSet={`${SurveyImage2x} 2x`}></img>
          </div>
        </div>
        <div className={styles.interactiveContainer}>
          <ul className={styles.interactiveInner}>
            {interactiveContent.map((text) => (
              <InteractiveItem key={text} text={text} />
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.heroInfo}>
        <h1 className={styles.title}>
          «MedAnketa» — медицинская платформа опросов и тестов для проведения исследований в области здравоохранения
        </h1>
        <Button onClick={scrollToDicts} className={styles.button} size="large">
          Список справочников
        </Button>
      </div>

      <div className={styles.decorations} />
    </div>
  );
};

export default HeroSection;
