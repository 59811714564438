import { Dictionaries } from 'api/directoriesApi/types';

interface GlobalDirectories extends Dictionaries {
  vision_type: 'GLOBAL';
}

interface UsersDirectories extends Dictionaries {
  vision_type: 'USER';
}

interface DirectoriesStore {
  globalDirectories: GlobalDirectories[];
  usersDirectories: UsersDirectories[];
  selectedDirectory: any;
  loading: boolean;
}

const DEFAULTS: DirectoriesStore = {
  globalDirectories: [],
  usersDirectories: [],
  selectedDirectory: {},
  loading: false,
};

class Adapter {
  public getInitialState(): DirectoriesStore {
    return { ...DEFAULTS };
  }
  public getAllDirectoriesPending(state: DirectoriesStore): DirectoriesStore {
    return { ...state, loading: true };
  }

  public getAllDirectoriesFullfilled(state: DirectoriesStore, action: any): DirectoriesStore {
    const data = action?.payload?.data as Dictionaries[];

    const globalDirectories = data?.filter((el) => el.vision_type === 'GLOBAL') as GlobalDirectories[];
    const usersDirectories = data?.filter((el) => el.vision_type === 'USER') as UsersDirectories[];

    return { ...state, loading: false, globalDirectories, usersDirectories };
  }
  public getAllDirectoriesRejected(state: DirectoriesStore): DirectoriesStore {
    return {
      ...state,
      loading: false,
    };
  }

  public getDirectoryByIdPending(state: DirectoriesStore): DirectoriesStore {
    return { ...state, loading: true };
  }
  public getDirectoryByIdFullfilled(state: DirectoriesStore, action: any): DirectoriesStore {
    return {
      ...state,
      selectedDirectory: {
        ...state.selectedDirectory,
        [action.payload.data.id]: action.payload.data,
      },
      loading: false,
    };
  }
  public getDirectoryByIdRejected(state: DirectoriesStore): DirectoriesStore {
    return { ...state, loading: false };
  }
}

const adapter = new Adapter();

const initialState = adapter.getInitialState();

export { initialState };

export default adapter;
