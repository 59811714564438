import { Result } from 'antd';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import { ROUTES } from '../../app/providers/router/routes';
const NotFoundPage = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="К сожалению, страница, которую вы посетили, не существует."
      extra={
        <Link to={ROUTES.questionnaires}>
          <Button type="primary">Вернуться на главную</Button>
        </Link>
      }
    />
  );
};

export default NotFoundPage;
