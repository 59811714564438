import { ReactNode } from 'react';

import styles from './AdvantageCard.module.scss';

const AdvantageCard = ({ title, description, icon }: { title: string; description: string; icon: ReactNode }) => {
  return (
    <li className={styles.container}>
      <div className={styles.icon}>{icon}</div>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{description}</p>
    </li>
  );
};

export default AdvantageCard;
