import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from 'antd';
// Components
import { Title } from '../../components/Title';
import { QuestionnairesFilter } from './QuestionnairesFilter';
import { QuestionnairesTable } from './QuestionnairesTable';
import ItemQuestionnairesPage from '../ItemQuestionnairesPage';
import getAllQuestionnairesAction from 'store/QuestionnairesSlice/actions/getAllQuestionnaires';
import { useAppDispatch } from 'store';

const { Content } = Layout;

const QuestionnairesAllListPage = () => {
  const dispatch = useAppDispatch();
  const [searchedText, setSearchedText] = useState('');

  useEffect(() => {
    dispatch(getAllQuestionnairesAction(null));
  }, []);

  return (
    <>
      <Title title="Анкеты" />
      <QuestionnairesFilter search={setSearchedText} />
      <QuestionnairesTable searchedText={searchedText} />
    </>
  );
};

const QuestionnairesPage = () => {
  const { id } = useParams();

  return <Content>{id ? <ItemQuestionnairesPage /> : <QuestionnairesAllListPage />}</Content>;
};

export default QuestionnairesPage;
