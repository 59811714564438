import { createSlice } from '@reduxjs/toolkit';

import questionnairesAdapter, { initialState } from './adapter';

import getAllQuestionnairesAction from './actions/getAllQuestionnaires';
import createQuestionnairesAction from './actions/createQuestionnaires';
import deleteQuestionnairesAction from './actions/deleteQuestionnaires';
import changeQuestionnairesStatusAction from './actions/changeQuestionnairesStatus';
import getQuestionnaireAction from './actions/getQuestionnaire';
import createQuestionnaireBlockAction from './actions/createQuestionnaireBlock';
import deleteQuestionnaireBlockAction from './actions/deleteQuestionnaireBlock';
import updateQuestionnairesAction from './actions/updateQuestionnaires';
import editQuestionnaireBlockAction from './actions/editQuestionnaireBlock';
import getQuestionnaireLinksAction from './actions/getQuestionnaireLinks';
import deleteQuestionnaireLinkAction from './actions/deleteQuestionnairesLink';
import createQuestionnaireLinkAction from './actions/createQuestionnairesLink';
import updateQuestionnaireLinkAction from './actions/updateQuestionnaireLink';
import changeQuestionnaireBlockOrderAction from './actions/changeQuestionnaireBlockOrder';

const questionnairesSlice = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllQuestionnairesAction.pending, questionnairesAdapter.getAllQuestionnairesPending)
      .addCase(getAllQuestionnairesAction.fulfilled, questionnairesAdapter.getAllQuestionnairesFullfilled)
      .addCase(getAllQuestionnairesAction.rejected, questionnairesAdapter.getAllQuestionnairesRejected)

      .addCase(createQuestionnairesAction.pending, questionnairesAdapter.createQuestionnairesPending)
      .addCase(createQuestionnairesAction.fulfilled, questionnairesAdapter.createQuestionnairesFullfilled)
      .addCase(createQuestionnairesAction.rejected, questionnairesAdapter.createQuestionnairesRejected)

      .addCase(deleteQuestionnairesAction.pending, questionnairesAdapter.deleteQuestionnairesPending)
      .addCase(deleteQuestionnairesAction.fulfilled, questionnairesAdapter.deleteQuestionnairesFullfilled)
      .addCase(deleteQuestionnairesAction.rejected, questionnairesAdapter.deleteQuestionnairesRejected)

      .addCase(changeQuestionnairesStatusAction.pending, () => {})
      .addCase(changeQuestionnairesStatusAction.fulfilled, () => {})
      .addCase(changeQuestionnairesStatusAction.rejected, () => {})

      .addCase(getQuestionnaireAction.pending, questionnairesAdapter.getQuestionnairePending)
      .addCase(getQuestionnaireAction.fulfilled, questionnairesAdapter.getQuestionnaireFullfilled)
      .addCase(getQuestionnaireAction.rejected, questionnairesAdapter.getQuestionnaireRejected)

      .addCase(updateQuestionnairesAction.pending, questionnairesAdapter.updateQuestionnairePending)
      .addCase(updateQuestionnairesAction.fulfilled, questionnairesAdapter.updateQuestionnaireFullfilled)
      .addCase(updateQuestionnairesAction.rejected, questionnairesAdapter.updateQuestionnaireRejected)

      .addCase(createQuestionnaireBlockAction.pending, questionnairesAdapter.createQuestionnaireBlockPending)
      .addCase(createQuestionnaireBlockAction.fulfilled, questionnairesAdapter.createQuestionnaireBlockFullfilled)
      .addCase(createQuestionnaireBlockAction.rejected, questionnairesAdapter.createQuestionnaireBlockRejected)

      .addCase(deleteQuestionnaireBlockAction.pending, questionnairesAdapter.deleteQuestionnaireBlockPending)
      .addCase(deleteQuestionnaireBlockAction.fulfilled, questionnairesAdapter.deleteQuestionnaireBlockFullfilled)
      .addCase(deleteQuestionnaireBlockAction.rejected, questionnairesAdapter.deleteQuestionnaireBlockRejected)

      .addCase(editQuestionnaireBlockAction.pending, questionnairesAdapter.editQuestionnaireBlockPending)
      .addCase(editQuestionnaireBlockAction.fulfilled, questionnairesAdapter.editQuestionnaireBlockFullfilled)
      .addCase(editQuestionnaireBlockAction.rejected, questionnairesAdapter.editQuestionnaireBlockRejected)

      .addCase(getQuestionnaireLinksAction.pending, questionnairesAdapter.getQuestionnaireLinkPending)
      .addCase(getQuestionnaireLinksAction.fulfilled, questionnairesAdapter.getQuestionnaireLinkFullfilled)
      .addCase(getQuestionnaireLinksAction.rejected, questionnairesAdapter.getQuestionnaireLinkRejected)

      .addCase(deleteQuestionnaireLinkAction.pending, questionnairesAdapter.deleteQuestionnaireLinkPending)
      .addCase(deleteQuestionnaireLinkAction.fulfilled, questionnairesAdapter.deleteQuestionnaireLinkFullfilled)
      .addCase(deleteQuestionnaireLinkAction.rejected, questionnairesAdapter.deleteQuestionnaireLinkRejected)

      .addCase(createQuestionnaireLinkAction.pending, questionnairesAdapter.createQuestionnaireLinkPending)
      .addCase(createQuestionnaireLinkAction.fulfilled, questionnairesAdapter.createQuestionnaireLinkFullfilled)
      .addCase(createQuestionnaireLinkAction.rejected, questionnairesAdapter.createQuestionnaireLinkRejected)

      .addCase(updateQuestionnaireLinkAction.pending, questionnairesAdapter.updateQuestionnaireLinkPending)
      .addCase(updateQuestionnaireLinkAction.fulfilled, questionnairesAdapter.updateQuestionnaireLinkFullfilled)
      .addCase(updateQuestionnaireLinkAction.rejected, questionnairesAdapter.updateQuestionnaireLinkRejected)

      .addCase(changeQuestionnaireBlockOrderAction.pending, questionnairesAdapter.changeQuestionnaireBlockOrderPending)
      .addCase(
        changeQuestionnaireBlockOrderAction.rejected,
        questionnairesAdapter.changeQuestionnaireBlockOrderRejected,
      )
      .addCase(changeQuestionnaireBlockOrderAction.fulfilled, questionnairesAdapter.changeQuestionnaireBlockOrderFullfilled)

  },
});

export default questionnairesSlice.reducer;
