interface AnswersStore {
  formAnswers: any;
  answersById: any;
  statistics: any;
}

const DEFAULTS: AnswersStore = {
  formAnswers: [],
  answersById: [],
  statistics: null,
};

class Adapter {
  public getInitialState(): AnswersStore {
    return { ...DEFAULTS };
  }

  public getResultsPending(state: AnswersStore): AnswersStore {
    return { ...state };
  }
  public getResultsFullfilled(state: AnswersStore, action: any): AnswersStore {
    return { ...state, formAnswers: action.payload.data };
  }
  public getResultsRejected(state: AnswersStore): AnswersStore {
    return { ...state };
  }

  public getAnswersByIdPending(state: AnswersStore): AnswersStore {
    return { ...state };
  }
  public getAnswersByIdFullfilled(state: AnswersStore, action: any): AnswersStore {
    return { ...state, answersById: action.payload.data };
  }
  public getAnswersByIdRejected(state: AnswersStore): AnswersStore {
    return { ...state };
  }

  public getStatisticsPending(state: AnswersStore): AnswersStore {
    return { ...state };
  }
  public getStatisticsFullfilled(state: AnswersStore, action: any): AnswersStore {
    return { ...state, statistics: action.payload.data };
  }
  public getStatisticsRejected(state: AnswersStore): AnswersStore {
    return { ...state };
  }
}

const adapter = new Adapter();

const initialState = adapter.getInitialState();

export { initialState };

export default adapter;
