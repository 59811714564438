import { Fragment, useContext } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import clsx from 'clsx';
import { Col, Input, Row, Space } from 'antd';

import { ContextFormUtilities } from '../../../EditCardContainer';

import { ReactComponent as ArrowSVG } from 'shared/assets/icons/arrow.svg';
import { ReactComponent as PlusSVG } from 'shared/assets/icons/plus.svg';
import { ReactComponent as GarbageSVG } from 'shared/assets/icons/garbage.svg';

import styles from './GradeScaleSubCard.module.scss';
import { nanoid } from 'nanoid';

const GradeScaleSubCard = () => {
  const context = useContext(ContextFormUtilities);
  const control = context.control;

  const {
    fields: paramFields,
    append: paramsAppend,
    remove: paramsRemove,
    insert: paramsInsert,
    move: paramsMove,
  } = useFieldArray({
    keyName: 'customParamsId',
    name: 'params',
    control,
  });

  const {
    fields: valueFields,
    append: valuesAppend,
    remove: valuesRemove,
    insert: valuesInsert,
    move: valuesMove,
  } = useFieldArray({
    keyName: 'customValuesId',
    name: 'values',
    control,
  });

  const addMoreParams = (index?: number) => {
    if (index !== undefined) {
      paramsInsert(index + 1, { id: nanoid(), value: '' });
    } else {
      paramsAppend({ id: nanoid(), value: '' });
    }
  };

  const addMoreValues = (index?: number) => {
    if (index !== undefined) {
      valueFields.length < 5 && valuesInsert(index + 1, { id: nanoid(), value: '' });
    } else {
      valueFields.length < 5 && valuesAppend({ id: nanoid(), value: '' });
    }
  };

  return (
    <>
      <span className={styles.label}>Критерии</span>
      {paramFields.map((item: any, index: number) => {
        return (
          <Fragment key={item.id}>
            <Row gutter={[8, 0]} wrap={false} className={clsx(styles.rowWidth, styles.flex)}>
              <Col flex="5%" className={styles.flex}>
                <ArrowSVG
                  onClick={() => {
                    if (index !== 0) {
                      paramsMove(index, index - 1);
                    }
                  }}
                  className={clsx(styles.arrow, styles.icon)}
                />
                <ArrowSVG
                  onClick={() => {
                    if (index !== paramFields.length - 1) {
                      paramsMove(index, index + 1);
                    }
                  }}
                  className={styles.icon}
                />
              </Col>
              <Col flex="90%" className={styles.flex}>
                <Controller
                  control={control}
                  name={`params.${index}.value`}
                  render={({ field }) => <Input placeholder="Введите ответ" className={styles.input} {...field} />}
                />
              </Col>
              <Col flex="6%" className={clsx(styles.flex)} style={{ display: 'flex', justifyContent: 'space-around' }}>
                <PlusSVG onClick={() => addMoreParams(index)} className={styles.icon} />
                <GarbageSVG onClick={() => paramsRemove(index)} className={styles.icon} />
              </Col>
            </Row>
          </Fragment>
        );
      })}

      <Space direction="vertical">
        <Col onClick={() => addMoreParams()} flex="auto" className={styles.addMoreContainer}>
          <PlusSVG className={styles.addMore} />
          <span>Добавить еще</span>
        </Col>
      </Space>

      <Row gutter={[8, 0]} wrap={false} className={clsx(styles.rowWidth, styles.flex)}>
        <Col className={styles.label2}>Ответы</Col>
      </Row>
      {valueFields.map((item: any, index: number) => {
        return (
          <Fragment key={item.id}>
            <Row gutter={[8, 0]} wrap={false} className={clsx(styles.rowWidth, styles.flex)}>
              <Col flex="5%" className={styles.flex}>
                <ArrowSVG
                  onClick={() => {
                    if (index !== 0) {
                      valuesMove(index, index - 1);
                    }
                  }}
                  className={clsx(styles.arrow, styles.icon)}
                />
                <ArrowSVG
                  onClick={() => {
                    if (index !== valueFields.length - 1) {
                      valuesMove(index, index + 1);
                    }
                  }}
                  className={styles.icon}
                />
              </Col>
              <Col flex="90%" className={styles.flex}>
                <Controller
                  control={control}
                  name={`values.${index}.value`}
                  render={({ field }) => <Input placeholder="Введите ответ" className={styles.input} {...field} />}
                />
              </Col>
              <Col flex="6%" className={clsx(styles.flex)} style={{ display: 'flex', justifyContent: 'space-around' }}>
                <PlusSVG onClick={() => addMoreValues(index)} className={styles.icon} />
                <GarbageSVG onClick={() => valueFields.length > 3 && valuesRemove(index)} className={styles.icon} />
              </Col>
            </Row>
          </Fragment>
        );
      })}

      <Space direction="vertical">
        <Col onClick={() => addMoreValues()} flex="auto" className={styles.addMoreContainer}>
          <PlusSVG className={styles.addMore} />
          <span>Добавить еще</span>
        </Col>
      </Space>
    </>
  );
};

export default GradeScaleSubCard;
