import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { FieldArray, FieldArrayMethodProps } from 'react-hook-form';
import { QuestionnairesBlocks } from 'api/questionnairesApi/types';

import { useAppDispatch } from 'store';
import changeQuestionnaireBlockOrder from 'store/QuestionnairesSlice/actions/changeQuestionnaireBlockOrder';

import InformationCard from './components/InformationCard';
import BinaryChoiceCard from './components/BinaryChoiceCard';
import DropdownCard from './components/DropdownCard';
import NumberCard from './components/NumberCard';
import ShortTextCard from './components/ShortTextCard';
import DateCard from './components/DateCard';
import LongTextCard from './components/LongTextCard';
import RatingCard from './components/RatingCard';
import GradeScaleCard from './components/GradeScaleCard';
import CardContainer from 'hoc/CardContainer';
import MultiSelectCard from './components/MultiSelectCard';
import { SortableList } from 'components/SortableList';

import styles from './MainCards.module.scss';

export interface OutletProps {
  fields: QuestionnairesBlocks[];
  insert: (index: number, value: Partial<FieldArray> | Partial<FieldArray>[], options?: FieldArrayMethodProps) => void;
  remove: (index?: number | number[]) => void;
  swap: (indexA: number, indexB: number) => void;
}

interface IProps {
  control?: any;
  propsFields?: any;
  isDraggable?: boolean;
  isEditable?: boolean;
  readOnly?: boolean;
  setValue?: (name: string, value: any) => void;
  setError?: (name: string, value: any) => void;
  clearErrors?: (name: string) => void;
}

function calculateQuestionNum(items: QuestionnairesBlocks[], currentIndex: number): number {
  let questionNum = 1;
  if (Array.isArray(items)) {
    for (let i = 0; i < currentIndex; i++) {
      if (items[i].type !== 'INFO') {
        questionNum++;
      }
    }
  }
  return questionNum;
}

const MainCards = ({
  control,
  propsFields,
  setValue,
  setError,
  clearErrors,
  isDraggable = true,
  isEditable = true,
  readOnly = false,
}: IProps) => {
  const { id: formID } = useParams();
  const dispatch = useAppDispatch();
  const { fields: outletFields, insert, remove, swap } = useOutletContext<OutletProps>();

  const fields = outletFields?.length ? outletFields : propsFields;
  const [sortableItems, setSortableItems] = useState(fields);

  useEffect(() => {
    setSortableItems(fields);
  }, [fields]);

  const onSort = async (items: QuestionnairesBlocks[]) => {
    if (!formID) return;

    const sortedIds = items.map((item) => item.id);

    setSortableItems(items);

    dispatch(changeQuestionnaireBlockOrder({ formID, sortedIds, sortedItems: items })).catch(() =>
      setSortableItems(fields),
    );
  };

  const renderCards = () => {
    const childrens = (item: any) => {
      switch (item.type) {
        case 'INFO':
          return <InformationCard sub_content={item?.sub_content} />;
        case 'BINARY':
          return (
            <BinaryChoiceCard
              id={item.id}
              control={control}
              true_label={item?.true_label}
              false_label={item?.false_label}
              readOnly={readOnly}
            />
          );
        case 'NUMBER':
          return (
            <NumberCard
              id={item.id}
              control={control}
              max={item.settings.max_value}
              min={item.settings.min_value}
              readOnly={readOnly}
            />
          );
        case 'LONG_TEXT':
          return (
            <LongTextCard
              id={item.id}
              max_length={item?.settings?.max_length}
              min={item?.settings?.min_length}
              control={control}
              readOnly={readOnly}
            />
          );
        case 'SHORT_TEXT':
          return (
            <ShortTextCard
              setError={setError}
              clearErrors={clearErrors}
              id={item.id}
              control={control}
              max={item?.settings?.max_length}
              min={item?.settings?.min_length}
              validation={item?.settings?.validation_type}
              readOnly={readOnly}
            />
          );
        case 'DATE':
          return (
            <DateCard
              id={item.id}
              control={control}
              default_value={item?.settings?.default_value}
              max_date_value={item?.settings?.max_date_value}
              min_date_value={item?.settings?.min_date_value}
              readOnly={readOnly}
            />
          );
        case 'DROPDOWN':
          return (
            <DropdownCard
              id={item.id}
              control={control}
              values={item.values}
              dictId={item['values_dictionary_id']}
              setValue={setValue}
              readOnly={readOnly}
              settings={item.settings}
            />
          );
        case 'MULTI_SELECT':
          return (
            <MultiSelectCard
              id={item.id}
              control={control}
              values={item.values}
              dictId={item['values_dictionary_id']}
              setValue={setValue}
              readOnly={readOnly}
              settings={item.settings}
            />
          );
        case 'GRADE_SCALE':
          return (
            <GradeScaleCard
              id={item.id}
              control={control}
              values={item.values}
              params={item.params}
              readOnly={readOnly}
            />
          );
        case 'RATING':
          return (
            <RatingCard
              id={item.id}
              values={item.values}
              control={control}
              readOnly={readOnly}
              settings={item.settings}
            />
          );
      }
    };

    if (!fields) {
      return null;
    }

    return isDraggable ? (
      <SortableList
        items={sortableItems}
        onChange={onSort}
        renderItem={(item, idx) => {
          const containerProps = {
            ...item,
            idx,
            insert,
            remove,
            swap,
            isEditable,
            currentArray: fields,
            order_num: calculateQuestionNum(sortableItems, idx),
          };

          return (
            <SortableList.Item id={item.id}>
              <CardContainer key={`${item.id}${JSON.stringify(item?.settings)}`} {...containerProps}>
                {childrens(item)}
              </CardContainer>
            </SortableList.Item>
          );
        }}
      />
    ) : (
      fields.map((item: any, idx: number, currentArray: any) => {
        const containerProps = {
          ...item,
          idx,
          insert,
          remove,
          swap,
          currentArray,
          order_num: calculateQuestionNum(sortableItems, idx),
        };
        return (
          <CardContainer key={`${item.id}${JSON.stringify(item?.settings)}`} {...containerProps}>
            {childrens(item)}
          </CardContainer>
        );
      })
    );
  };

  if (!fields) {
    return null;
  }

  return <div className={styles.wrapper}>{fields?.length === 0 ? <div>Карточек нет</div> : renderCards()}</div>;
};

export default MainCards;
