import axios, { AxiosError, AxiosResponse } from 'axios';

import instance from 'connection';
import {
  RequestChangePasswordData,
  RequestLoginData,
  RequestRecoveryData,
  RequestRegisterData,
  ResponseLoginData,
  ResponseRecoveryCode,
  ResponseRegisterData,
} from './types';
import { writeLocalStorage } from 'utils/writeLocalStorage';
import readFromLocalStorage from 'utils/readFromLocalStorage';
import removeFromLocalStorage from 'utils/removeFromLocalStorage';

export const login = async (data: RequestLoginData, thunkAPI: any) => {
  try {
    const response = await instance.post<RequestLoginData, AxiosResponse<ResponseLoginData>>('/auth/login', data);

    writeLocalStorage<typeof response.data>('token', response.data);

    return response.data;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;
    console.log(error);
  }
};

export const register = async (data: RequestRegisterData, thunkAPI: any) => {
  try {
    const response = await instance.post<RequestRegisterData, AxiosResponse<ResponseRegisterData>>(
      '/auth/register',
      data,
    );

    return response;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;
    console.log(error);
  }
};

export const sendRecoveryCode = async (data: RequestRecoveryData) => {
  try {
    await instance.get<ResponseRecoveryCode>(`/auth/register/sendRecoverCode`, { params: { email: data.email } });
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      console.error('Error sending recovery code:', e.response);
      throw e.response;
    }
    console.error('Unexpected error:', e);
    throw new Error('Unexpected error occurred');
  }
};

export const verifyCode = async (code: string) => {
  try {
    const response = await instance.get<ResponseRecoveryCode>(`/auth/register/verifyRecoverCode`, {
      params: { recoverCode: code },
    });

    if (!response.data || !response.data.confirm_token) {
      throw new Error('Ошибка: Токен подтверждения отсутствует в ответе.');
    }

    writeLocalStorage('confirmToken', response.data.confirm_token);

    return response.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      console.error('Error verifying code:', e.response);
      throw e.response;
    }
    throw new Error('Unexpected error occurred');
  }
};
export const changePassword = async (data: RequestChangePasswordData) => {
  try {
    const { password } = data;
    const confirmToken = readFromLocalStorage('confirmToken');
    const formData = {
      confirm_token: confirmToken,
      new_password: password,
    };
    await instance.post(`/auth/register/resetPassword`, formData);
    removeFromLocalStorage('confirmToken');
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      console.error('Error changing password:', e.response);
      throw e.response;
    }
    console.error('Unexpected error:', e);
    throw new Error('Unexpected error occurred');
  }
};
