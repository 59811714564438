import { createSlice } from '@reduxjs/toolkit';
import resultsAction from './actions/resultsAction';
import resultsAdapter, { initialState } from './adapter';
import getAnswersByIdAction from './actions/getAnswersAction';
import getStatisticsAction from './actions/getStatisticsAction';

const dictionariesSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resultsAction.pending, resultsAdapter.getResultsPending);
    builder.addCase(resultsAction.fulfilled, resultsAdapter.getResultsFullfilled);
    builder.addCase(resultsAction.rejected, resultsAdapter.getResultsRejected);

    builder.addCase(getAnswersByIdAction.pending, resultsAdapter.getAnswersByIdPending);
    builder.addCase(getAnswersByIdAction.fulfilled, resultsAdapter.getAnswersByIdFullfilled);
    builder.addCase(getAnswersByIdAction.rejected, resultsAdapter.getAnswersByIdRejected);

    builder.addCase(getStatisticsAction.pending, resultsAdapter.getStatisticsPending);
    builder.addCase(getStatisticsAction.fulfilled, resultsAdapter.getStatisticsFullfilled);
    builder.addCase(getStatisticsAction.rejected, resultsAdapter.getStatisticsRejected);
  },
});

export default dictionariesSlice.reducer;
