import { memo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { DatePicker, Divider, Radio, Space } from 'antd';
import dayJS from 'dayjs';

import { QuestionnairesBlocksSettingsDate } from 'api/questionnairesApi/types/date.types';

import styles from './DateSettings.module.scss';

interface Props extends QuestionnairesBlocksSettingsDate {
  control: any;
  errors: any;
}

export const dateFormat = 'YYYY-MM-DD';

const DateSettings = ({ default_value, control, max_date_value, min_date_value }: Props) => {
  const [defaultGroup, setDefaultGroup] = useState<0 | 1>(0);
  const [minimalGroup, setMinimalGroup] = useState(0);
  const [maximumGroup, setMaximumGroup] = useState(0);

  const [date, setDate] = useState(!!default_value ? dayJS(default_value, dateFormat) : dayJS());
  const [minDate, setMinDate] = useState(
    !!min_date_value ? dayJS(min_date_value, dateFormat) : dayJS().subtract(1, 'day'),
  );
  const [maxDate, setMaxDate] = useState(!!max_date_value ? dayJS(max_date_value, dateFormat) : dayJS().add(1, 'day'));

  return (
    <div className={styles.wrapper}>
      <Controller
        control={control}
        render={({ field: { value, onChange, ...rest } }) => (
          <Space size={16} direction="vertical">
            <span>Значение по умолчанию:</span>
            <Radio.Group
              value={defaultGroup}
              onChange={(e) => {
                setDefaultGroup(e.target.value);
              }}
            >
              <Space direction="vertical" size={10}>
                <Radio value={0}>
                  <span>Дата</span>
                  <DatePicker
                    {...rest}
                    onChange={(e) => {
                      e && onChange(e.format(dateFormat));
                      e && setDate(dayJS(e.format(dateFormat)));
                    }}
                    value={date}
                    format={dateFormat}
                    style={{ marginLeft: 10, minWidth: 210 }}
                    disabled={defaultGroup !== 0}
                  />
                </Radio>

                <Radio
                  {...rest}
                  onChange={(e) => {
                    onChange(dayJS().format(dateFormat));
                  }}
                  value={1}
                >
                  Сегодня
                </Radio>
              </Space>
            </Radio.Group>
          </Space>
        )}
        name="DATE.settings.default_value"
      />

      <Divider />

      <Controller
        control={control}
        render={({ field: { value, onChange, ...rest } }) => (
          <Space size={16} direction="vertical">
            <span>Минимальная дата:</span>

            <Radio.Group
              value={minimalGroup}
              onChange={(e) => {
                setMinimalGroup(e.target.value);
              }}
            >
              <Space direction="vertical" size={10}>
                <Radio value={0}>
                  <span>Дата</span>
                  <DatePicker
                    {...rest}
                    onChange={(e) => {
                      e && onChange(e.format(dateFormat));
                      e && setMinDate(dayJS(e.format(dateFormat)));
                    }}
                    value={minDate}
                    disabled={minimalGroup !== 0}
                    format={dateFormat}
                    style={{ marginLeft: 10, minWidth: 210 }}
                  />
                </Radio>

                <Radio
                  {...rest}
                  onChange={(e) => {
                    onChange(dayJS().format(dateFormat));
                    setMinDate(dayJS());
                  }}
                  value={1}
                >
                  Сегодня
                </Radio>

                <Radio
                  {...rest}
                  onChange={(e) => {
                    onChange(dayJS().subtract(7, 'days').format(dateFormat));
                    setMinDate(dayJS().subtract(7, 'days'));
                  }}
                  value={2}
                >
                  Неделя назад
                </Radio>

                <Radio
                  {...rest}
                  onChange={(e) => {
                    onChange(dayJS().subtract(1, 'month').format(dateFormat));
                    setMinDate(dayJS().subtract(1, 'month'));
                  }}
                  value={3}
                >
                  Месяц назад
                </Radio>

                <Radio
                  {...rest}
                  onChange={(e) => {
                    onChange(dayJS().subtract(1, 'year').format(dateFormat));
                    setMinDate(dayJS().subtract(1, 'year'));
                  }}
                  value={4}
                >
                  Год назад
                </Radio>
              </Space>
            </Radio.Group>
          </Space>
        )}
        name="DATE.settings.min_date_value"
      />

      <Divider />

      <Controller
        control={control}
        render={({ field: { value, onChange, ...rest } }) => (
          <Space size={16} direction="vertical">
            <span>Максимальная дата:</span>

            <Radio.Group
              value={maximumGroup}
              onChange={(e) => {
                setMaximumGroup(e.target.value);
              }}
            >
              <Space direction="vertical" size={10}>
                <Radio value={0}>
                  <span>Дата</span>
                  <DatePicker
                    {...rest}
                    onChange={(e) => {
                      e && onChange(e.format(dateFormat));
                      e && setMaxDate(dayJS(e.format(dateFormat)));
                    }}
                    value={maxDate}
                    disabled={maximumGroup !== 0}
                    format={dateFormat}
                    style={{ marginLeft: 10, minWidth: 210 }}
                  />
                </Radio>

                <Radio
                  {...rest}
                  onChange={(e) => {
                    onChange(dayJS().format(dateFormat));
                    setMaxDate(dayJS());
                  }}
                  value={1}
                >
                  Сегодня
                </Radio>

                <Radio
                  {...rest}
                  onChange={(e) => {
                    onChange(dayJS().add(7, 'days').format(dateFormat));
                    setMaxDate(dayJS().add(7, 'days'));
                  }}
                  value={2}
                >
                  Неделя вперед
                </Radio>

                <Radio
                  {...rest}
                  onChange={(e) => {
                    onChange(dayJS().add(1, 'month').format(dateFormat));
                    setMaxDate(dayJS().add(1, 'month'));
                  }}
                  value={3}
                >
                  Месяц вперед
                </Radio>

                <Radio
                  {...rest}
                  onChange={(e) => {
                    onChange(dayJS().add(1, 'year').format(dateFormat));
                    setMaxDate(dayJS().add(1, 'year'));
                  }}
                  value={4}
                >
                  Год вперед
                </Radio>
              </Space>
            </Radio.Group>
          </Space>
        )}
        name="DATE.settings.max_date_value"
      />
    </div>
  );
};

export default memo(DateSettings);
