import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { Typography } from 'antd';

import Button from 'components/Button';

import styles from '../Access.module.scss';

interface FormItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  label: string;
  children: ReactNode;
}

interface TitleProps {
  children: ReactNode;
}

const { Text } = Typography;

export const FormItem = ({ label, children, ...props }: FormItemProps) => (
  <div className={styles.item} {...props}>
    <Text className={styles.text}>{label}</Text>
    {children}
  </div>
);

export const SaveCancelButton = () => {
  return (
    <div className={styles.saveCancelButton}>
      <Button htmlType="submit" size="middle" type="primary">
        Сохранить
      </Button>
      <Button size="middle" type="link">
        Отмена
      </Button>
    </div>
  );
};

export const Title = ({ children }: TitleProps) => {
  return <Text className={styles.title}>{children}</Text>;
};
