import clsx from 'clsx';

import { ReactComponent as EditContentSVG } from 'shared/assets/icons/editContent.svg';

import styles from './ExtraContent.module.scss';
import { SortableList } from 'components/SortableList';

interface Props {
  onClick: () => void;
}
const ExtraContent = ({ onClick }: Props) => (
  <div className={styles.extraContent} key="QuestionsTable-extraContent-buttons">
    <EditContentSVG onClick={onClick} className={clsx(styles.editIcon)} />
    <div className={clsx(styles.grabIcon)}>
      <SortableList.DragHandle />
    </div>
  </div>
);

export default ExtraContent;
