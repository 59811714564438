import { QuestionnairesType } from 'api/questionnairesApi/types';

export const showMarginForSubContent = (type: QuestionnairesType) => {
  switch (type) {
    case 'NUMBER':
    case 'LONG_TEXT':
    case 'DATE':
    case 'SHORT_TEXT':
      return false;
    default:
      return true;
  }
};
