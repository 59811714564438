import React, { useRef } from 'react';
import styles from './CodeInput.module.scss';

interface CodeInputComponentProps {
  value: string;
  onChange: (value: string) => void;
}

export const CodeInputComponent: React.FC<CodeInputComponentProps> = ({ value = '', onChange }) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const val = e.target.value;
    if (/^\d$/.test(val) || val === '') {
      const newValue = value.split('');
      newValue[index] = val;
      onChange(newValue.join(''));

      if (val !== '' && index < 3) {
        // Переместить фокус на следующее поле ввода, если оно доступно
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      if (value[index]) {
        // Удалить текущее значение и обновить
        const newValue = value.split('');
        newValue[index] = '';
        onChange(newValue.join(''));
      } else if (index > 0) {
        // Переместить фокус на предыдущее поле ввода и удалить значение
        inputRefs.current[index - 1]?.focus();
        const newValue = value.split('');
        newValue[index - 1] = '';
        onChange(newValue.join(''));
      }
    }
  };

  return (
    <div className={styles.codeInputContainer}>
      {[...Array(4)].map((_, i) => (
        <input
          key={i}
          type="text"
          maxLength={1}
          value={value[i] || ''}
          onChange={(e) => handleChange(e, i)}
          onKeyDown={(e) => handleKeyDown(e, i)}
          ref={(el) => (inputRefs.current[i] = el)}
          className={styles.codeInput}
          placeholder="*"
        />
      ))}
    </div>
  );
};
