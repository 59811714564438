// Core
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { useAppDispatch } from 'store';
import authenticateAction from 'store/authSlice/actions/authenticate';
import { appRouter } from './providers/router/appRouter';

import './styles/global.scss';

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authenticateAction());
  }, []);

  return <RouterProvider router={appRouter} />;
};
