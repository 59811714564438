import { Card, Col, Collapse, List, Row, Skeleton } from 'antd';
import { Empty } from '../Empty';

import styles from './Directory.module.scss';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import getDirectoryByIdAction from '../../../store/DirectoriesSlice/actions/getDirectoryByIdAction';
import { directoriesSelector } from '../../../store/DirectoriesSlice/selectors';

interface Props {
  id: string;
}

export const Directory = ({ id }: Props) => {
  const dispatch = useAppDispatch();
  const { selectedDirectory, loading } = useAppSelector(directoriesSelector);

  const mappedDirectory = useMemo(
    () =>
      selectedDirectory?.[id]?.items?.map((item: any) => {
        const baseInfo = {
          key: item.id,
          label: item.value,
        };

        if ('children' in item) {
          return {
            ...baseInfo,
            children: (
              <List
                key={item.id}
                dataSource={item.children.map((child: any) => child.value)}
                renderItem={(item: any) => <List.Item>{item}</List.Item>}
              />
            ),
          };
        }
        return {
          ...baseInfo,
          showArrow: false,
          collapsible: 'icon',
        };
      }),
    [selectedDirectory, id],
  );

  useEffect(() => {
    if (!selectedDirectory?.[id]) {
      dispatch(getDirectoryByIdAction(id));
    }
  }, [id]);

  return (
    <Card>
      <Row wrap={false} style={{ marginBottom: 25 }}>
        <Col flex="50%">
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <span className={styles.title}>{selectedDirectory?.[id]?.name}</span>
            {/*<SettingsVG />*/}
          </div>
        </Col>
        {/*<Col flex="auto" style={{ marginRight: 8 }}>*/}
        {/*  <Input className={styles.inputSearch} placeholder="Поиск" suffix={<SearchSVG />} />*/}
        {/*</Col>*/}
        {/*<Col flex="20%">*/}
        {/*  <Button type="primary" className={styles.button}>*/}
        {/*    Добавить пункт*/}
        {/*  </Button>*/}
        {/*</Col>*/}
      </Row>
      {!mappedDirectory?.length ? (
        <>
          {loading ? (
            <Skeleton loading={loading} />
          ) : (
            <div className={styles.wrapper}>
              <Empty
                label="В справочнике пусто"
                text="Пока что тут пусто, вы можете добавить или дождаться когда тут что-то появиться"
              />
            </div>
          )}
        </>
      ) : (
        <Skeleton loading={loading}>
          <Collapse items={mappedDirectory} />
        </Skeleton>
      )}
    </Card>
  );
};
