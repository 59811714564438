import AdvantageCard from './AdvantageCard';
import styles from './AdvantagesSection.module.scss';

import { features } from './features';

const AdvantagesSection = () => {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {features.map(({ description, icon, title }, index) => (
          <AdvantageCard description={description} icon={icon} title={title} key={index} />
        ))}
      </ul>
    </div>
  );
};

export default AdvantagesSection;
