import { Input as AntdInput, InputProps } from 'antd';
import clsx from 'clsx';

import styles from './Input.module.scss';

interface Props extends InputProps {
  inputType?: 'default' | 'search';
}

const Input = ({ className, inputType = 'default', children, ...props }: Props) => {
  return (
    <AntdInput
      className={clsx(styles.default, className, {
        [styles.search]: inputType === 'search',
      })}
      {...props}
    >
      {children}
    </AntdInput>
  );
};

export default Input;
