import instance from 'connection';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dictionaries } from './types';

// Список всех справочников
export const getAllDirectories = async (_?: never, thunkAPI?: any) => {
  try {
    return await instance.get<never, AxiosResponse<Dictionaries[]>>('/dictionaries');
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

// Получить справочник по id
export const getDirectoryById = async (id: string, thunkAPI?: any) => {
  try {
    return await instance.get<never, AxiosResponse<Dictionaries>>(`/dictionaries/${id}`);
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};
