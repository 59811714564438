// Core
import { Link, useLocation } from 'react-router-dom';
import { Button, Menu } from 'antd';
import { Layout } from 'antd';
// Ui
import AppLogoPNG from 'shared/assets/icons/appLogo.png';
import { ReactComponent as LoginSVG } from 'shared/assets/icons/login.svg';
import removeFromLocalStorage from 'utils/removeFromLocalStorage';
import { useAppDispatch } from 'store';
import logoutAction from 'store/authSlice/actions/logout';
// Styles
import styles from './Header.module.scss';
import { ROUTES } from '../../../../app/providers/router/routes';

const { Header: HeaderAntd } = Layout;

export const Header = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const menuItems = [
    { key: '1', path: ROUTES.questionnaires, label: 'Анкеты' },
    // { key: '2', path: '/templates', label: 'Шаблоны' },
    { key: '3', path: ROUTES.directories, label: 'Справочники' },
  ];

  const currentKey = menuItems.find((item) => location.pathname.includes(item.path))?.key || '1';

  const handleLogOut = async () => {
    dispatch(logoutAction());
    removeFromLocalStorage('token');
  };

  return (
    <HeaderAntd className={styles.header}>
      <div className={styles.logo}>
        <Link to={ROUTES.questionnaires}>
          <div className={styles.logo}>
            <img src={AppLogoPNG} alt="App Logo" className={styles.icon} />
          </div>
        </Link>
      </div>
      <Menu className={styles.menu} mode="horizontal" defaultSelectedKeys={[currentKey]} selectedKeys={[currentKey]}>
        {menuItems.map((item) => (
          <Menu.Item key={item.key}>
            <Link to={item.path}>{item.label}</Link>
          </Menu.Item>
        ))}
      </Menu>
      <Button onClick={handleLogOut} className={styles.button} icon={<LoginSVG />} type="link">
        Выйти
      </Button>
    </HeaderAntd>
  );
};
