import axios, { AxiosError, AxiosResponse } from 'axios';
import instance from 'connection';
import {
  Questionnaire,
  QuestionnairesBlocks,
  QuestionnairesBlocksIDs,
  QuestionnairesCreateRequest,
  QuestionnairesDeleteRequest,
  QuestionnairesID,
  QuestionnairesResponseData,
  QuestionnairesStatusRequest,
} from './types';

// Анкеты без вопросов
export const getAllQuestionnaires = async (_?: unknown, thunkAPI?: any) => {
  try {
    const response = await instance.get<never, AxiosResponse<QuestionnairesResponseData>>('/constructor/forms');

    return response;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

// Анкеты с вопросами
export const getQuestionnaire = async (id?: QuestionnairesID, thunkAPI?: any) => {
  try {
    const response = await instance.get<void, AxiosResponse<Questionnaire>>(`/constructor/forms/${id}`);

    return response;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

// Создание анкеты
export const createQuestionnaires = async (data: QuestionnairesCreateRequest, thunkAPI?: any) => {
  try {
    const response = await instance.post<QuestionnairesCreateRequest, AxiosResponse<QuestionnairesResponseData>>(
      '/constructor/forms',
      data,
    );

    return response;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

// Обнавление названия анкеты
export const updateQuestionnaires = async (
  data: { id: QuestionnairesID } & QuestionnairesCreateRequest,
  thunkAPI?: any,
) => {
  try {
    const response = await instance.put<QuestionnairesCreateRequest, AxiosResponse<QuestionnairesResponseData>>(
      '/constructor/forms',
      data,
    );

    return response;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

// Удаление анкеты
export const deleteQuestionnaires = async (id: QuestionnairesDeleteRequest, thunkAPI?: any) => {
  try {
    const response = await instance.delete<QuestionnairesDeleteRequest, AxiosResponse>(`/constructor/forms/${id}`);
    return response;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

// Установить статус для анкеты
export const changeQuestionnairesStatus = async (data: QuestionnairesStatusRequest, thunkAPI?: any) => {
  try {
    const { id, status } = data;
    const response = await instance.put<QuestionnairesStatusRequest, AxiosResponse>(
      `/constructor/forms/${id}/status/${status}`,
    );
    return response;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

// Создать блок анкеты
export const createQuestionnaireBlock = async (payload: any, thunkAPI?: any) => {
  try {
    const { formID, ...data } = payload;

    if (
      data &&
      (data?.type === 'DROPDOWN' ||
        data?.type === 'RATING' ||
        data?.type === 'GRADE_SCALE' ||
        data?.type === 'MULTI_SELECT')
    ) {
      data?.values?.forEach((item: any) => {
        delete item.id;
      });
    }
    if (data && data?.type === 'SHORT_TEXT') {
      if (data.settings.validation_type === '') {
        delete data.settings.validation_type;
      }
    }
    const link =
      !payload.prev_block_id || !payload.next_block_id
        ? `/constructor/forms/${formID}/blocks`
        : `/constructor/forms/${formID}/blocks?beforeId=${payload.next_block_id}&afterId=${payload.prev_block_id}`;
    const response = await instance.post(link, data);
    return response;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

// Редактировать блок анкеты
export const editQuestionnaireBlock = async (payload: any, thunkAPI?: any) => {
  try {
    const { formID, prev_block_id, next_block_id, ...data } = payload;

    const params = new URLSearchParams();

    if (next_block_id) params.append('beforeId', next_block_id);
    if (prev_block_id) params.append('afterId', prev_block_id);

    if (data && data?.type === 'SHORT_TEXT') {
      if (data.settings.validation_type === '') {
        delete data.settings.validation_type;
      }
    }

    const response = await instance.put<QuestionnairesBlocks, AxiosResponse<QuestionnairesBlocks>>(
      `/constructor/forms/${formID}/blocks?${params.toString()}`,
      data,
    );
    return response;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

// Удалить блок анкеты
export const deleteQuestionnaireBlock = async (payload: { formID: string; blockID: string }, thunkAPI?: any) => {
  try {
    const response = await instance.delete(`/constructor/forms/${payload.formID}/blocks/${payload.blockID}`);
    return response;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

// Изменить порядок блоков
export const changeQuestionnaireBlockOrder = async (
  payload: { formID: string; sortedIds: QuestionnairesBlocksIDs; sortedItems: QuestionnairesBlocks[] },
  thunkAPI?: any,
) => {
  try {
    const { formID, sortedIds, sortedItems } = payload;

    await instance.put<QuestionnairesBlocksIDs, AxiosResponse<QuestionnairesBlocks>>(
      `/constructor/forms/${formID}/block-order`,
      sortedIds,
    );

    return sortedItems;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response?.data);
    }
    const error = e as Error;

    console.log(error);
  }
};
