import { createAsyncThunk } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

import { RootState } from 'store';
import { payloadIsValid } from 'utils/payloadIsValid';
import removeFromLocalStorage from 'utils/removeFromLocalStorage';

import logoutAction from './logout';

const ACTION = 'auth/authenticate';

const authenticateAction = createAsyncThunk(ACTION, (_, thunkAPI) => {
  const {
    auth: { user },
  } = thunkAPI.getState() as RootState;

  if (payloadIsValid(jwtDecode(user.refresh_token))) {
    return;
  }

  thunkAPI.dispatch(logoutAction());
  removeFromLocalStorage('token');
});

export default authenticateAction;
