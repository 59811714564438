// Types
import { QuestionnairesType } from 'api/questionnairesApi/types';

export const getPlaceholder = (type: QuestionnairesType): string => {
  switch (type) {
    case 'INFO':
    case 'BINARY':
    case 'NUMBER':
    case 'SHORT_TEXT':
    case 'DATE':
    case 'LONG_TEXT':
    case 'DROPDOWN':
    case 'GRADE_SCALE':
    case 'RATING':
    case 'MULTI_SELECT':
    default:
      return 'Введите текст вопроса';
  }
};
