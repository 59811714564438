import { Collapse, Modal, Spin } from 'antd';
import { CaretRightOutlined, CloseCircleOutlined } from '@ant-design/icons';

import styles from './DictDetails.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { getDirectoryById } from 'api/directoriesApi';
import { Dictionaries } from 'api/directoriesApi/types';

const DictDetails = ({
  isOpen,
  onCancel,
  activeDictId,
}: {
  isOpen: boolean;
  onCancel: () => void;
  activeDictId: number | string;
}) => {
  const [dict, setDict] = useState<Dictionaries>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDirectoryById(String(activeDictId))
      .then((res) => setDict(res.data))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [activeDictId]);

  const formattedItems = useMemo(() => {
    if (!dict?.items) return [];

    return dict?.items.slice(0, 10).map(({ id, value, children }) => {
      const hasChildren = Boolean(children && children.length > 0);

      return {
        key: id,
        label: value,
        showArrow: hasChildren,
        style: !hasChildren ? { pointerEvents: 'none' } : undefined,
        children: children?.map(({ id, value }) => (
          <div className={styles.dictItem} key={id}>
            {value}
          </div>
        )),
      };
    });
  }, [dict]);

  return (
    <Modal
      closeIcon={<CloseCircleOutlined className={styles.closeIcon} />}
      footer={null}
      title={<div className={styles.title}>{dict?.name}</div>}
      open={isOpen}
      onCancel={onCancel}
    >
      <Spin spinning={isLoading}>
        <Collapse
          className={styles.collapse}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          items={formattedItems}
        />
      </Spin>
    </Modal>
  );
};

export default DictDetails;
