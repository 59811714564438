import { Input } from 'antd';

import styles from './LongTextCard.module.scss';
import { Controller } from 'react-hook-form';

interface Props {
  max_length: number;
  min?: number;
  control?: any;
  id?: string;
  readOnly?: boolean;
}

const LongTextCard = ({ control, readOnly, id = '', max_length = 10000, min = 0 }: Props) => {
  return (
    <Controller
      control={control}
      name={id}
      disabled={readOnly}
      render={({ field }) => (
        <Input.TextArea
          maxLength={max_length}
          minLength={min}
          autoSize={{ minRows: 2, maxRows: 6 }}
          className={styles.input}
          placeholder="Введите текст вопроса"
          {...field}
        />
      )}
    />
  );
};

export default LongTextCard;
