import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Divider, Input, Modal } from 'antd';

import { QuestionnairesCreateRequest } from 'api/questionnairesApi/types';
import { ReactComponent as CloseSVG } from 'shared/assets/icons/close.svg';
import { questionnairesModalValidation } from './validation';

import Button from 'components/Button';

import styles from './QuestionnairesModal.module.scss';
import { useAppDispatch } from '../../../store';
import createQuestionnairesAction from '../../../store/QuestionnairesSlice/actions/createQuestionnaires';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (arg: boolean) => void;
}

export const QuestionnairesModal = ({ isModalOpen, setIsModalOpen }: Props) => {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(questionnairesModalValidation) });

  useEffect(() => {
    return () => {
      clearErrors();
      reset();
    };
  }, [isModalOpen]);

  const handleOk = async (data: QuestionnairesCreateRequest) => {
    await dispatch(createQuestionnairesAction(data));
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const footerButtons = [
    <Button key="back" type="link" className={styles.button} size="large" onClick={handleCancel}>
      Отменить
    </Button>,
    <Button key="submit" size="large" className={styles.button} type="primary" onClick={handleSubmit(handleOk)}>
      Создать
    </Button>,
  ];

  return (
    <Modal
      centered
      footer={footerButtons}
      title="Новая анкета"
      closeIcon={<CloseSVG />}
      open={isModalOpen}
      onCancel={handleCancel}
    >
      <Divider />

      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            className={!errors.name ? undefined : styles.error}
            id="formAnswerId"
            size="large"
            placeholder="Название"
          />
        )}
      />
      {errors.name && <Alert className={styles.alert} message={errors.name.message} type="error" showIcon />}
      <Divider />
    </Modal>
  );
};
