import { QuestionnairesResponseData, Questionnaire } from 'api/questionnairesApi/types';

export interface QuestionnairesStore {
  questionnaires: QuestionnairesResponseData[];
  questionnaire: Questionnaire;
  questionnaireLink: any;
  loading: boolean;
}

const DEFAULTS: QuestionnairesStore = {
  questionnaires: [],
  questionnaire: {} as Questionnaire,
  questionnaireLink: {
    public_link: {},
    private_links: [],
  },
  loading: false,
};

class Adapter {
  public getInitialState(): QuestionnairesStore {
    return { ...DEFAULTS };
  }

  public getAllQuestionnairesPending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state, loading: true };
  }

  public getAllQuestionnairesFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    return { ...state, loading: false, questionnaires: [...action.payload.data].reverse() };
  }

  public getAllQuestionnairesRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state, loading: false };
  }

  public createQuestionnairesPending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state, loading: true };
  }

  public createQuestionnairesFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    return { ...state, loading: false, questionnaires: [action.payload.data, ...state.questionnaires] };
  }

  public createQuestionnairesRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state, loading: false };
  }

  public deleteQuestionnairesPending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state, loading: true };
  }

  public deleteQuestionnairesFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    return {
      ...state,
      loading: false,
      questionnaires: state.questionnaires.filter((item) => item.id !== action?.meta?.arg),
    };
  }

  public deleteQuestionnairesRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state, loading: false };
  }

  public getQuestionnairePending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }
  public getQuestionnaireFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    return { ...state, questionnaire: action.payload.data };
  }
  public getQuestionnaireRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }

  public updateQuestionnairePending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }
  public updateQuestionnaireFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    return { ...state, questionnaire: { ...state.questionnaire, ...action.payload.data } };
  }
  public updateQuestionnaireRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }

  public createQuestionnaireBlockPending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }
  public createQuestionnaireBlockFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    const prevID = action?.meta?.arg?.prev_block_id;
    const nextID = action?.meta?.arg?.next_block_id;

    const prevBlockIDX = state.questionnaire.blocks.map((el) => el.id).indexOf(prevID);
    const nextBlockIDX = state.questionnaire.blocks.map((el) => el.id).indexOf(nextID);

    let blocks;

    if (prevBlockIDX >= 0 && nextBlockIDX > 0) {
      blocks = [
        ...state.questionnaire.blocks.slice(0, nextBlockIDX),
        action.payload.data,
        ...state.questionnaire.blocks.slice(nextBlockIDX),
      ];
    } else {
      blocks = [...state.questionnaire.blocks, action.payload.data];
    }

    return {
      ...state,
      questionnaire: { ...state.questionnaire, blocks },
    };
  }
  public createQuestionnaireBlockRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }
  public deleteQuestionnaireBlockPending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }
  public deleteQuestionnaireBlockFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    return {
      ...state,
      questionnaire: {
        ...state.questionnaire,
        blocks: state.questionnaire.blocks.filter((item) => item.id !== action?.meta?.arg?.blockID),
      },
    };
  }
  public deleteQuestionnaireBlockRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }

  // Редактирование Анкеты с вопросами
  public editQuestionnaireBlockPending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }

  public editQuestionnaireBlockFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    return {
      ...state,
      questionnaire: {
        ...state.questionnaire,
        blocks: state.questionnaire.blocks.map((block) => {
          if (block.id === action.payload.data.id) {
            console.log('update', action.payload.data);
            return {
              ...block,
              ...action.payload.data,
            };
          }
          return block;
        }),
      },
    };
  }
  public editQuestionnaireBlockRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }

  // ссылки

  public getQuestionnaireLinkPending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }
  public getQuestionnaireLinkFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    return { ...state, questionnaireLink: action.payload.data };
  }
  public getQuestionnaireLinkRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }

  public deleteQuestionnaireLinkPending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }
  public deleteQuestionnaireLinkFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    const firstId: string = action.meta.arg.at(0) || '';

    if (state.questionnaireLink.public_link?.id === firstId) {
      return {
        ...state,
        questionnaireLink: {
          public_link: {},
          private_links: state.questionnaireLink.private_links,
        },
      };
    }

    return {
      ...state,
      questionnaireLink: {
        public_link: state.questionnaireLink.public_link,
        private_links: state.questionnaireLink.private_links.filter(
          (value: any) => !action.meta.arg.includes(value?.id),
        ),
      },
    };
  }
  public deleteQuestionnaireLinkRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state };
  }

  public createQuestionnaireLinkPending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state, loading: true };
  }
  public createQuestionnaireLinkFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    if ('pass' in action.meta.arg) {
      return {
        ...state,
        loading: false,
        questionnaireLink: {
          ...state.questionnaireLink,
          private_links: [...state.questionnaireLink.private_links, action.payload.data],
        },
      };
    }
    return {
      ...state,
      loading: false,
      questionnaireLink: { ...state.questionnaireLink, public_link: action.payload.data },
    };
  }
  public createQuestionnaireLinkRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state, loading: false };
  }

  // Изменить порядок блоков
  public changeQuestionnaireBlockOrderPending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state, loading: true };
  }

  public changeQuestionnaireBlockOrderFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    return {
      ...state,
      loading: false,
      questionnaire: {
        ...state.questionnaire,
        blocks: action.payload,
      },
    };
  }

  public changeQuestionnaireBlockOrderRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state, loading: false };
  }

  public updateQuestionnaireLinkPending(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state, loading: true };
  }
  public updateQuestionnaireLinkFullfilled(state: QuestionnairesStore, action: any): QuestionnairesStore {
    const linkId: string = action.meta.arg.linkID;
    const index = state.questionnaireLink.private_links.findIndex((el: any) => el?.id === linkId);

    return {
      ...state,
      questionnaireLink: {
        public_link: state.questionnaireLink.public_link,
        private_links: [
          ...state.questionnaireLink.private_links.slice(0, index),
          action.payload.data,
          ...state.questionnaireLink.private_links.slice(index + 1),
        ],
      },
    };
  }
  public updateQuestionnaireLinkRejected(state: QuestionnairesStore): QuestionnairesStore {
    return { ...state, loading: false };
  }
}

const adapter = new Adapter();

const initialState = adapter.getInitialState();

export { initialState };

export default adapter;
