import { startAppListening } from '../../listenerMiddleware';
import { toastify } from 'utils/toastify';
import submitFormAction from '../actions/submitFormAction';
import getFormsAction from '../actions/getFormsAction';

startAppListening({
  actionCreator: submitFormAction.rejected,
  effect: async (action, listenerApi) => {
    toastify('error', 'Ошибка отправления анкеты');
  },
});

startAppListening({
  actionCreator: getFormsAction.rejected,
  effect: async (action, listenerApi) => {
    // @ts-ignore
    switch (action?.payload?.data?.code) {
      case 'form.wrong.password': {
        if ('pass' in action?.meta?.arg) {
          toastify(
            'error',
            'Извините, но введен неверный пароль. Пожалуйста, проверьте правильность ввода и повторите попытку.',
          );
        }
        break;
      }
      case 'form.validation.finish.date.excess': {
        toastify('error', 'Срок заполнения анкеты истек');
        break;
      }
      case 'form.not.accessible':
      default: {
        toastify('error', 'Анкета не активна');
        break;
      }
    }
  },
});
