import { Key, useState } from 'react';

import { Popconfirm, TablePaginationConfig } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { FilterValue } from 'antd/es/table/interface';

import { useAppDispatch, useAppSelector } from 'store';
import { QuestionnairesResponseData } from 'api/questionnairesApi/types';
import { questionnairesListSelector } from 'store/QuestionnairesSlice/selectors/getAllQuestionnaire';
import deleteQuestionnairesAction from 'store/QuestionnairesSlice/actions/deleteQuestionnaires';

import { ReactComponent as GarbageSVG } from 'shared/assets/icons/garbage.svg';

import { tableColumns } from './constants';

import styles from './QuestionnairesTable.module.scss';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface Props {
  searchedText: string;
}

export const QuestionnairesTable = ({ searchedText }: Props) => {
  const questionnaires = useAppSelector(questionnairesListSelector);
  const [columns, setColumns] = useState(tableColumns);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleDelete = async (id: string) => {
    await dispatch(deleteQuestionnairesAction(id));
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   setData([]);
    // }
  };

  const customizeColumns = columns.map((el) => {
    const item = el as ColumnsType & { dataIndex: string };

    if (item.dataIndex === 'actions') {
      return {
        ...item,
        render: (_: string, record: QuestionnairesResponseData) => {
          return questionnaires.length >= 1 ? (
            <Popconfirm title="Удалить?" onConfirm={() => handleDelete(record?.id)}>
              <span style={{ cursor: 'pointer' }}>
                <GarbageSVG />
              </span>
            </Popconfirm>
          ) : null;
        },
      };
    }
    if (item.dataIndex === 'id' || item.dataIndex === 'name') {
      return {
        ...item,
        filteredValue: [searchedText],
        onFilter: (text: boolean | Key, record: QuestionnairesResponseData) => {
          return record.id.includes(text as string) || String(record.name).includes(text as string);
        },
      };
    }
    return item;
  });

  return (
    <Table
      columns={customizeColumns}
      dataSource={questionnaires}
      pagination={false}
      loading={loading}
      onChange={handleTableChange}
      scroll={{ x: 850 }}
    />
  );
};
