import { Select } from 'antd';

import {
  QuestionnairesBlocksDropdownValues,
  QuestionnairesBlocksSettingsDropdown,
} from 'api/questionnairesApi/types/dropdown.types';

import { ReactComponent as SelectIconSvg } from 'shared/assets/icons/arrow2.svg';
import { Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import getDirectoryByIdAction from '../../../../../store/DirectoriesSlice/actions/getDirectoryByIdAction';
import { directoriesSelector } from '../../../../../store/DirectoriesSlice/selectors';
import { useEffect, useState } from 'react';
import { Dictionaries } from 'api/directoriesApi/types';
import { transformToOptions } from 'utils/transformToOptions';

interface Props {
  values: QuestionnairesBlocksDropdownValues[];
  control?: any;
  id?: string;
  dictId?: string;
  setValue?: (name: string, value: any) => void;
  readOnly?: boolean;
  settings?: QuestionnairesBlocksSettingsDropdown;
}

const DropdownCard = ({ readOnly, control, setValue, dictId, id = '', values = [], settings }: Props) => {
  const dispatch = useAppDispatch();
  const { selectedDirectory } = useAppSelector(directoriesSelector);
  const sortType = settings?.sort_type;
  const [options, setOptions] = useState(() => transformToOptions(values, sortType));

  useEffect(() => {
    if (dictId) {
      if (!selectedDirectory?.[dictId]) {
        dispatch(getDirectoryByIdAction(dictId)).then((data) => {
          const response = data.payload.data as Dictionaries;

          const transformedOptions = transformToOptions(response?.items, sortType);
          setOptions(transformedOptions);
        });
      } else {
        const transformedOptions = transformToOptions(selectedDirectory?.[dictId].items, sortType);
        setOptions(transformedOptions);
      }
    }
  }, []);

  useEffect(() => {
    // чтобы сработал ререндер после установки значения
    queueMicrotask(() => {
      if (setValue && settings?.select_first) {
        if (options?.length > 0 && 'options' in options[0]) {
          setValue(id, options[0].options[0]);
        } else {
          setValue(id, options[0]);
        }
      }
    });
  }, [options, setValue, settings?.select_first]);

  return (
    <>
      Выберите ответ:
      <Controller
        disabled={readOnly}
        render={({ field }) => (
          <Select
            showSearch
            placeholder="Выберите значение из списка"
            suffixIcon={<SelectIconSvg />}
            style={{ marginLeft: 8, minWidth: 300 }}
            options={options}
            {...field}
          />
        )}
        name={id}
        control={control}
      />
    </>
  );
};

export default DropdownCard;
