import { ReactNode, useState } from 'react';
import { Card } from 'antd';

import { IndividualCountdown } from './IndividualCountdown';
import Statistics from './Statistics/Statistics';

const tabList = [
  {
    key: 'statisticsIssues',
    tab: 'Статистика по вопросам',
  },
  {
    key: 'individualCountdown',
    tab: 'Индивидуальный отчет',
  },
];

const contentList: Record<string, ReactNode> = {
  statisticsIssues: <Statistics />,
  individualCountdown: <IndividualCountdown />,
};

type TabListKey = 'statisticsIssues' | 'individualCountdown';

export const Results = () => {
  const [activeTabKey, setActiveTabKey] = useState<TabListKey>('individualCountdown');

  const onTab1Change = (key: string) => {
    setActiveTabKey(key as TabListKey);
  };

  return (
    <Card tabList={tabList} activeTabKey={activeTabKey} onTabChange={onTab1Change}>
      {contentList[activeTabKey]}
    </Card>
  );
};
