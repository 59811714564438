// CookieNotice.tsx
import React, { useState } from 'react';
import styles from './CookieNotice.module.scss';

export const CookieNotice: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleAccept = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className={styles.container}>
      <p className={styles.text}>
        Мы используем cookies для улучшения вашего опыта. Продолжая, вы соглашаетесь на их использование.
      </p>
      <button className={styles.button} onClick={handleAccept}>OK</button>
    </div>
  );
};
