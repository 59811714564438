interface FillFormStore {
  questions: any;
  loading: boolean;
  isSubmitted: boolean;
  errors: any;
}

const DEFAULTS: FillFormStore = {
  questions: [],
  loading: false,
  isSubmitted: false,
  errors: null,
};

class Adapter {
  public getInitialState(): FillFormStore {
    return { ...DEFAULTS };
  }
  public getFillFormQuestionsPending(state: FillFormStore): FillFormStore {
    return { ...state, loading: true };
  }

  public getFillFormQuestionsFullfilled(state: FillFormStore, action: any): FillFormStore {
    return { ...state, errors: null, loading: false, questions: action.payload };
  }
  public getFillFormQuestionsRejected(state: FillFormStore, action: any): FillFormStore {
    return {
      ...state,
      errors: action.payload,
      loading: false,
    };
  }

  public submitFormPending(state: FillFormStore): FillFormStore {
    return { ...state, loading: true };
  }

  public submitFormFullfilled(state: FillFormStore): FillFormStore {
    return { ...state, loading: false, isSubmitted: true };
  }
  public submitFormRejected(state: FillFormStore): FillFormStore {
    return {
      ...state,
      loading: false,
    };
  }

  public setIsSubmittedFullfilled(state: FillFormStore, action: any): FillFormStore {
    return { ...state, isSubmitted: action.payload.isSubmitted };
  }
}

const adapter = new Adapter();

const initialState = adapter.getInitialState();

export { initialState };

export default adapter;
