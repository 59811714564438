import React from 'react';
import { useAppSelector } from 'store';
import { authSelector } from 'store/authSlice/selectors';
import { Navigate } from 'react-router-dom';

interface Props {
  children: any;
}

const ProtectedRoute = ({ children }: Props) => {
  const { isAuthenticated, user } = useAppSelector(authSelector);

  return !isAuthenticated || !user ? <Navigate to="/auth/login" replace /> : children;
};

export default ProtectedRoute;
