import { Checkbox, Divider, Select, Space } from 'antd';
import { Controller } from 'react-hook-form';

import { QuestionnairesBlocksSettingsDropdown } from 'api/questionnairesApi/types/dropdown.types';

import styles from './DropdownSettings.module.scss';
import { QUESTION_SORT_OPTIONS } from 'shared/constants/questionSortOptions';

interface Props extends QuestionnairesBlocksSettingsDropdown {
  control: any;
  errors: any;
}
const DropdownSettings = ({ control, errors, select_first, use_filter, sort_type }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Space size={8}>
        <span>Сортировка ответов:</span>
        <Controller
          control={control}
          render={({ field }) => <Select className={styles.select} {...field} options={QUESTION_SORT_OPTIONS} />}
          name="DROPDOWN.settings.sort_type"
        />
      </Space>

      <Divider />

      <Space direction="vertical" size={16}>
        <Space size={8} className={styles.checkbox}>
          <Controller
            control={control}
            render={({ field: { value, ...rest } }) => (
              <Checkbox {...rest} checked={value}>
                Выбрать первый вариант ответа в поле
              </Checkbox>
            )}
            name="DROPDOWN.settings.select_first"
          />
        </Space>
        <Space size={8} className={styles.checkbox}>
          <Controller
            control={control}
            render={({ field: { value, ...rest } }) => (
              <Checkbox {...rest} checked={value}>
                Фильтровать ответы при вводе текста в поле
              </Checkbox>
            )}
            name="DROPDOWN.settings.use_filter"
          />
        </Space>
      </Space>
    </div>
  );
};

export default DropdownSettings;
