import { useState } from 'react';

import { QuestionnairesModal } from '../QuestionnairesModal';

import Button from 'components/Button';
import Input from 'components/Input';

import { ReactComponent as SearchSVG } from 'shared/assets/icons/search.svg';
import { ReactComponent as PlusSVG } from 'shared/assets/icons/plus.svg';

import styles from './QuestionnairesFilter.module.scss';

interface Props {
  search: (text: string) => void;
}

export const QuestionnairesFilter = ({ search }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className={styles.container}>
      <Input
        placeholder="Поиск по названию анкеты и ID"
        type="search"
        onChange={(e) => search(e.target.value)}
        suffix={<SearchSVG />}
      />
      <Button onClick={showModal} icon={<PlusSVG />} className={styles.button} type="primary" size="large">
        Новая анкета
      </Button>
      <QuestionnairesModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};
