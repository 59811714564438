import React, { useState } from 'react';
import { Alert } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAppDispatch } from 'store';
import Button from 'components/Button';
import Input from 'components/Input';

import styles from './PasswordRecoveryPage.module.scss';
import { ROUTES } from '../../app/providers/router/routes';
import { passwordRecoveryValidation, codeValidation, newPasswordValidation } from './validation';
import {
  changePasswordAction,
  sendRecoveryCodeAction,
  verifyCodeAction,
} from 'store/authSlice/actions/passwordRecovery';
import { CodeInputComponent } from 'components/CodeInput';

interface FormData {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
}

export const PasswordRecoveryPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);

  const getValidationSchema = (step: number): yup.ObjectSchema<any> => {
    switch (step) {
      case 1:
        return passwordRecoveryValidation;
      case 2:
        return codeValidation;
      case 3:
        return newPasswordValidation;
      default:
        return passwordRecoveryValidation;
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(getValidationSchema(step)),
  });

  const onSubmit = async (data: FormData) => {
    switch (step) {
      case 1:
        const normalizedEmail = data.email.toLowerCase();
        dispatch(sendRecoveryCodeAction({ email: normalizedEmail }));
        setStep(2);
        break;
      case 2:
        try {
          await dispatch(verifyCodeAction(data.code)).unwrap();
          setStep(3);
        } catch (error) {
          setValue('code', '');
          console.error('Неверный код:', error);
        }
        break;
      case 3:
        const { password } = data;
        dispatch(changePasswordAction({ password }));
        navigate(ROUTES.login);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.form}>
      <h2 className={styles.title}>Восстановление пароля</h2>
      <p className={styles.instructions}>
        {step === 1 && 'Введите свой электронный адрес и мы отправим код для восстановления пароля:'}
        {step === 2 && 'Введите код из письма:'}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        {step === 1 && (
          <label className={styles.label} htmlFor="email">
            Е-майл:
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  inputType="default"
                  {...field}
                  className={!errors.email ? undefined : styles.error}
                  id="email"
                  size="large"
                  placeholder="email@email.com"
                />
              )}
            />
            {errors.email && <Alert message={errors.email.message} type="error" showIcon />}
          </label>
        )}
        {step === 2 && (
          <div className={styles.codeInput}>
            <Controller
              name="code"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CodeInputComponent value={value || ''} onChange={onChange} />
              )}
            />
            {errors.code && <Alert message={errors.code?.message} type="error" showIcon />}
          </div>
        )}
        {step === 3 && (
          <>
            <label className={styles.label} htmlFor="newPassword">
              Новый пароль:
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input
                    inputType="default"
                    type="password"
                    {...field}
                    className={!errors.password ? undefined : styles.error}
                    id="newPassword"
                    size="large"
                    placeholder="Новый пароль"
                  />
                )}
              />
              {errors.password && <Alert message={errors.password.message} type="error" showIcon />}
            </label>
            <label className={styles.label} htmlFor="confirmNewPassword">
              Подтвердить новый пароль:
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <Input
                    inputType="default"
                    type="password"
                    {...field}
                    className={!errors.confirmPassword ? undefined : styles.error}
                    id="confirmNewPassword"
                    size="large"
                    placeholder="Подтвердить новый пароль"
                  />
                )}
              />
              {errors.confirmPassword && <Alert message={errors.confirmPassword.message} type="error" showIcon />}
            </label>
          </>
        )}
        <Button block size="large" type="primary" htmlType="submit" className={styles.submitButton}>
          {step === 1 ? 'Отправить' : step === 2 ? 'Подтвердить' : 'Сохранить'}
        </Button>
        {step === 1 && (
          <Link className={styles.backLink} to={ROUTES.login}>
            Вернуться ко входу
          </Link>
        )}
        {step === 2 && (
          <div className={styles.repeatLink}>
            <Link to="" onClick={() => setStep(1)}>
              <span>Отправить еще раз</span>
            </Link>
          </div>
        )}
      </form>
    </div>
  );
};
