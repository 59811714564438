import { Id, toast, ToastContent, ToastOptions, ToastPromiseParams } from 'react-toastify';

type ToastFunction = {
  (content: ToastContent<unknown>, options?: ToastOptions): Id;
  (
    promise: Promise<unknown> | (() => Promise<unknown>),
    params: ToastPromiseParams,
    options?: ToastOptions,
  ): Promise<Id>;
};

export const toastify = (type: keyof typeof toast, text: string) => {
  const options: ToastOptions = {
    position: 'top-right',
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  };

  (toast[type] as ToastFunction)(text, options);
};
