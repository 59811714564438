import {
  QuestionnairesBlocksMultiSelectValues,
  QuestionnairesBlocksSettingsMultiSelect,
} from 'api/questionnairesApi/types/multiSelect.types';
import { Checkbox, Select, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import getDirectoryByIdAction from '../../../../../store/DirectoriesSlice/actions/getDirectoryByIdAction';
import { Dictionaries } from '../../../../../api/directoriesApi/types';
import { transformToOptions } from '../../../../../utils/transformToOptions';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { directoriesSelector } from '../../../../../store/DirectoriesSlice/selectors';
import { ReactComponent as SelectIconSvg } from 'shared/assets/icons/arrow2.svg';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { sortArrayByType } from 'utils/sortArrayByType';

interface Props {
  values: QuestionnairesBlocksMultiSelectValues[];
  control?: any;
  id?: string;
  readOnly?: boolean;
  dictId?: string;
  setValue?: (name: string, value: any) => void;
  settings: QuestionnairesBlocksSettingsMultiSelect;
}

const MultiSelectCard = ({ readOnly, control, id = '', setValue, values = [], settings, dictId }: Props) => {
  const dispatch = useAppDispatch();
  const { selectedDirectory } = useAppSelector(directoriesSelector);

  const sortType = settings?.sort_type;
  const [options, setOptions] = useState(() => transformToOptions(values, sortType));

  const sortedValues = useMemo(
    () => sortArrayByType(values, 'value', settings?.sort_type),
    [values, settings?.sort_type],
  );

  useEffect(() => {
    if (dictId) {
      if (!selectedDirectory?.[dictId]) {
        dispatch(getDirectoryByIdAction(dictId)).then((data) => {
          const response = data.payload.data as Dictionaries;
          const transformedOptions = transformToOptions(response?.items, sortType);
          setOptions(transformedOptions);
        });
      } else {
        const transformedOptions = transformToOptions(selectedDirectory?.[dictId].items, sortType);
        setOptions(transformedOptions);
      }
    }
  }, []);

  useEffect(() => {
    // чтобы сработал ререндер после установки значения
    queueMicrotask(() => {
      if (setValue && settings?.select_first) {
        if (options?.length > 0 && 'options' in options[0]) {
          setValue(id, options[0].options[0]);
        } else {
          setValue(id, options[0]);
        }
      }
    });
  }, [options, setValue, settings?.select_first]);

  const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    if (settings?.max_answers && checkedValues.length > settings.max_answers) {
      checkedValues = checkedValues.slice(0, settings.max_answers);
    }

    if (setValue) {
      setValue(id, checkedValues);
    }
  };

  return (
    <>
      {dictId ? (
        <Controller
          render={({ field }) => (
            <Select
              showSearch
              placeholder="Выберите значение из списка"
              mode="multiple"
              allowClear
              suffixIcon={<SelectIconSvg />}
              style={{ marginLeft: 8, minWidth: 300 }}
              options={options}
              {...field}
            />
          )}
          name={id}
          control={control}
        />
      ) : (
        <Controller
          render={({ field }) => (
            <Checkbox.Group {...field} disabled={readOnly} onChange={handleCheckboxChange}>
              <Space direction="vertical" size={8}>
                {sortedValues.map(({ id: checkboxID, value }) => (
                  <>
                    {value && (
                      <Checkbox key={checkboxID} value={checkboxID}>
                        {value}
                      </Checkbox>
                    )}
                  </>
                ))}
              </Space>
            </Checkbox.Group>
          )}
          name={id}
          control={control}
        />
      )}
    </>
  );
};

export default MultiSelectCard;
