import { ReactComponent as ChartIcon } from 'shared/assets/icons/chart_colored.svg';
import { ReactComponent as DocumentIcon } from 'shared/assets/icons/document_colored.svg';
import { ReactComponent as FolderIcon } from 'shared/assets/icons/folder_colored.svg';
import { ReactComponent as LockIcon } from 'shared/assets/icons/lock_colored.svg';
import { ReactComponent as PaperIcon } from 'shared/assets/icons/paper_colored.svg';
import { ReactComponent as SettingIcon } from 'shared/assets/icons/setting_colored.svg';

export const features = [
  {
    title: 'Гибкая кастомизация и настройка параметров опросов',
    description:
      'Платформа обладает большим количеством различных настроек опросов. Вы быстро и удобно создадите действительно уникальный опрос, который поможет выполнить цели и задачи.',
    icon: <SettingIcon />,
  },
  {
    title: 'Большое количество типов опросов',
    description:
      'Одиночный выбор, множественный выбор, ввод числа, ввод текста, ответ в свободной форме, установление последовательности, установление соответствий, бинарный выбор.',
    icon: <FolderIcon />,
  },
  {
    title: 'Доступные справочники',
    description:
      'На платформу на постоянной основе добавляются новые справочники, что позволяет не тратить время на формирование выборки ответов, а использовать готовые',
    icon: <PaperIcon />,
  },
  {
    title: 'Аналитика результатов опросов',
    description:
      'Вам доступен анализ результатов, статистика по данным ответам и по каждому вопросу. Результаты представлены в табличном виде, ответы на все вопросы и статистику вы можете выгрузить в формате Excel.',
    icon: <ChartIcon />,
  },
  {
    title: 'Доступ к опросам',
    description:
      'Созданный вами опрос доступен респондентам в виде ссылки, вы можете ограничивать время доступа ссылки и установить на неё пароль.',
    icon: <LockIcon />,
  },
  {
    title: 'Адаптирован под все устройства',
    description:
      'Интерфейс опросов адаптирован под все устройства, под любые размеры и разрешения экранов. Респонденты могут использовать и телефон и планшет и компьютер',
    icon: <DocumentIcon />,
  },
];
