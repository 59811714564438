import { Typography } from 'antd';

import styles from './QuestionCardTitle.module.scss';

interface TitleProps {
  numberQuestion?: number;
  title: string | number;
  isInfo?: boolean;
  isRequired?: boolean;
}

const { Text } = Typography;

const QuestionCardTitle = ({ numberQuestion, title, isInfo = false, isRequired = false }: TitleProps) => {
  return (
    <Text>
      {!isInfo && numberQuestion && <span className={styles.titleLeft}>Вопрос №{numberQuestion}</span>}
      <span style={{ position: 'relative' }} className={styles.titleDefault}>
        {title}
        {isRequired && <span className={styles.required}>*</span>}
      </span>
    </Text>
  );
};

export default QuestionCardTitle;
