import { useEffect, useMemo, useState } from 'react';
import dayJS, { Dayjs } from 'dayjs';
import { Modal, Table } from 'antd';

import { DataType } from './constants';
import { ColumnsType } from 'antd/es/table';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../app/providers/router/routes';
import resultsAction from '../../../../store/ResultSlice/actions/resultsAction';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { resultSelector } from '../../../../store/ResultSlice/selectors';
import getAnswersByIdAction from '../../../../store/ResultSlice/actions/getAnswersAction';
import MainCards from '../../../../components/questionnaires/MainCards';
import { useForm } from 'react-hook-form';
import { dateFormat } from '../../../../components/questionnaires/MainCards/components/DateCard/DateSettings';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

// const { RangePicker } = DatePicker;

export const IndividualCountdown = () => {
  const [dates, setDates] = useState<RangeValue>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { control, setValue } = useForm();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { formAnswers, answersById } = useAppSelector(resultSelector);
  const { id } = useParams();
  const MAX_SEARCH_DAYS = 31;

  useEffect(() => {
    if (id) {
      dispatch(resultsAction(id));
    }
  }, []);

  useEffect(() => {
    if (answersById) {
      answersById?.answers?.forEach((item: any) => {
        setValue(item.form_block_id, item.value);
      });
    }
  }, [answersById]);

  const mappedAnswers: DataType[] = useMemo(
    () =>
      formAnswers
        .map((item: any) => ({
          key: item.id,
          id: item.id,
          duration: item?.filling_minutes || 0,
          date: item?.finish ? dayJS(item.finish).format(dateFormat) : '-',
        }))
        ?.reverse(),
    [formAnswers],
  );

  const onIdClick = (id: string) => {
    setIsModalOpen(true);
    dispatch(getAnswersByIdAction(id));
  };

  const columns: ColumnsType<DataType> = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        width: '33%',
        render: (text, data) => {
          return (
            <Link to={`${ROUTES.questionnaires}/${id}/results#${data.id}`} onClick={() => onIdClick(data.id)}>
              {text}
            </Link>
          );
        },
      },
      {
        title: 'Длительность, мин',
        dataIndex: 'duration',
        width: '33%',
      },
      {
        title: 'Дата',
        dataIndex: 'date',
        width: '33%',
      },
    ],
    [],
  );

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') >= MAX_SEARCH_DAYS;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= MAX_SEARCH_DAYS;

    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    navigate(location.pathname);
    setIsModalOpen(false);
  };

  return (
    <>
      {/*<RangePicker*/}
      {/*  style={{ marginBottom: 16 }}*/}
      {/*  value={dates || value}*/}
      {/*  disabledDate={disabledDate}*/}
      {/*  onCalendarChange={(val) => {*/}
      {/*    setDates(val);*/}
      {/*  }}*/}
      {/*  onChange={(val) => {*/}
      {/*    setValue(val);*/}
      {/*  }}*/}
      {/*  onOpenChange={onOpenChange}*/}
      {/*  changeOnBlur*/}
      {/*/>*/}
      <Table pagination={false} columns={columns} dataSource={mappedAnswers}   />

      <Modal title="Вопросы" width={800} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {answersById && (
          <>
            <MainCards
              control={control}
              propsFields={answersById?.form?.blocks}
              isDraggable={false}
              isEditable={false}
              readOnly={true}
            />
          </>
        )}
      </Modal>
    </>
  );
};
