import { ReactNode, useMemo } from 'react';
// Types
import { QuestionnairesBlocks, QuestionnairesType } from 'api/questionnairesApi/types';
// Components
import { ActualSettings } from './Settings/ActualSettings';
import { Content } from './Content';

interface QuestionContent extends QuestionnairesBlocks {
  selectedSubContent: QuestionnairesType;
  setSelectedSubContent: (type: QuestionnairesType) => void;
  setContent: (content: string) => void;
  setSelectedDict: (dictId: string) => void;
}

export const questionContent = ({ ...props }: QuestionContent): Record<string, ReactNode> => {
  const { settings, selectedSubContent } = props;

  const memoizedSettings = useMemo(() => settings, []);

  return {
    content: <Content {...props} />,
    settings: <ActualSettings type={selectedSubContent} settings={memoizedSettings} />,
    // preview: <ActualPreviewCard />,
  };
};
