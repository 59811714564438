import dayJS from 'dayjs';

import { QuestionnairesType } from 'api/questionnairesApi/types';
import { dateFormat } from 'components/questionnaires/MainCards/components/DateCard/DateSettings';

export const createCardSettings = (type: QuestionnairesType) => {
  switch (type) {
    case 'BINARY':
      return {
        required: false,
      };

    case 'NUMBER':
      return {
        required: false,
        use_float: false,
        max_value: 100000,
        min_value: 0,
      };
    case 'LONG_TEXT':
      return {
        required: false,
        min_length: 0,
        max_length: 10000,
      };

    case 'SHORT_TEXT':
      return {
        validation_type: '',
        required: false,
        min_length: 0,
        max_length: 10000,
      };
    case 'DATE':
      return {
        required: false,
        default_value: dayJS().format(dateFormat),
        max_date_value: dayJS().subtract(1, 'day').format(dateFormat),
        min_date_value: dayJS().add(1, 'day').format(dateFormat),
      };
    case 'DROPDOWN':
      return {
        required: false,
        use_filter: false,
        select_first: false,
        sort_type: 'DEFAULT',
      };

    case 'RATING':
      return {
        required: false,
        sort_type: 'DEFAULT',
      };
    case 'MULTI_SELECT':
      return {
        required: false,
        max_answers: 5,
        min_answers: 0,
        sort_type: 'DEFAULT',
      };

    default:
      return {};
  }
};
