import { Alert } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch } from 'store';
import loginAction from 'store/authSlice/actions/login';

import { loginValidation } from './validation';
import { RequestLoginData } from 'api/authApi/types';

import Button from 'components/Button';
import Input from 'components/Input';

import styles from './LoginPage.module.scss';
import { ROUTES } from '../../app/providers/router/routes';

interface Props {
  className?: string;
}

const LoginPage = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginValidation) });

  const onSubmit = async (data: RequestLoginData) => {
    const normalizedData = {
      ...data,
      email: data.email.toLowerCase(),
    };

    await dispatch(loginAction(normalizedData));

    navigate(ROUTES.questionnaires);
  };

  return (
    <div className={styles.form}>
      <h2 className={styles.title}>Авторизация</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className={styles.label} htmlFor="email">
          Е-майл:
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                inputType="default"
                {...field}
                className={!errors.email ? undefined : styles.error}
                id="email"
                size="large"
                placeholder="Е-майл"
              />
            )}
          />
          {errors.email && <Alert message={errors.email.message} type="error" showIcon />}
        </label>

        <label className={styles.label} htmlFor="password">
          Пароль:
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                inputType="default"
                type="password"
                className={!errors.password ? undefined : styles.error}
                {...field}
                id="password"
                size="large"
                placeholder="Пароль"
              />
            )}
          />
          {errors.password && <Alert message={errors.password.message} type="error" showIcon />}
        </label>

        <Button block size="large" type="primary" htmlType="submit">
          Вход
        </Button>
      </form>
      <span className={styles.text}>
        Ещё не зарегистрированы?&nbsp;
        <Link to="/auth/register">
          <span>Регистрация</span>
        </Link>
      </span>
      <div className={styles.text} style={{ marginTop: '30px' }}>
        <Link to={ROUTES.passwordRecovery}>
          <span>Восстановление пароля</span>
        </Link>
      </div>
    </div>
  );
};

export default LoginPage;
