// Core
import { useEffect } from 'react';
import { Alert } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from 'store';
import { registerValidation } from './validation';
import { RequestRegisterData } from '../../api/authApi/types';
import registerAction from '../../store/authSlice/actions/register';
import { authSelector } from '../../store/authSlice/selectors';

import Button from 'components/Button';
import Input from 'components/Input';

import styles from '../LoginPage/LoginPage.module.scss';
import { ROUTES } from 'app/providers/router/routes';

interface Props {
  className?: string;
}

const RegisterPage = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { serverError } = useAppSelector(authSelector);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerValidation),
  });

  useEffect(() => {
    if (!serverError) return;
    if (serverError === 'email.already.used') {
      setError('email', { type: 'custom', message: 'Е-майл уже используется' });
    }
  }, [serverError]);

  const onSubmit = async (data: RequestRegisterData) => {
    const normalizedData = {
      ...data,
      email: data.email.toLowerCase(),
    };

    await dispatch(registerAction(normalizedData));
  };

  return (
    <div className={styles.form}>
      <h2 className={styles.title}>Регистрация</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className={styles.label} htmlFor="nickName">
          Никнейм:
          <Controller
            name="nickName"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                className={!errors.nickName ? undefined : styles.error}
                id="nickName"
                size="large"
                placeholder="Никнейм"
              />
            )}
          />
          {errors.nickName && <Alert message={errors.nickName.message} type="error" showIcon />}
        </label>
        <label className={styles.label} htmlFor="email">
          Е-майл:
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                className={!errors.email ? undefined : styles.error}
                id="email"
                size="large"
                placeholder="Е-майл"
              />
            )}
          />
          {errors.email && <Alert message={errors.email.message} type="error" showIcon />}
        </label>
        <label className={styles.label} htmlFor="password">
          Пароль:
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                type="password"
                {...field}
                className={!errors.password ? undefined : styles.error}
                id="password"
                size="large"
                placeholder="Пароль"
              />
            )}
          />
          {errors.password && <Alert message={errors.password.message} type="error" showIcon />}
        </label>
        <Button block size="large" type="primary" htmlType="submit">
          Создать
        </Button>
      </form>
      <span className={styles.text}>
        Уже есть аккаунт?&nbsp;
        <Link to={ROUTES.login}>
          <span>Войти</span>
        </Link>
      </span>
      <div className={styles.text} style={{ marginTop: '30px' }}>
        <Link to={ROUTES.passwordRecovery}>
          <span>Восстановление пароля</span>
        </Link>
      </div>
    </div>
  );
};

export default RegisterPage;
