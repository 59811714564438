import styles from './InformationCard.module.scss';

interface Props {
  sub_content: string;
}

const InformationCard = ({ sub_content }: Props) => {
  return <span className={styles.subContent}>{sub_content}</span>;
};

export default InformationCard;
