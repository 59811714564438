import { Controller } from 'react-hook-form';
import { InputNumber, Space } from 'antd';
import { QuestionnairesBlocksSettingsLongText } from 'api/questionnairesApi/types';

interface Props extends QuestionnairesBlocksSettingsLongText {
  control: any;
  errors: any;
}

const LongTextSettings = ({ control, errors }: Props) => {
  return (
    <>
      <Space direction="horizontal" size={16}>
        <Space size={18} direction="vertical">
          <span>Минимальное кол-во символов:</span>
          <span>Максимальное кол-во символов:</span>
        </Space>
        <Space size={8} direction="vertical" align="baseline">
          <Controller
            control={control}
            render={({ field }) => (
              <InputNumber {...field} status={!!errors?.LONG_TEXT?.settings?.min_value ? 'error' : ''} min={0} />
            )}
            name="LONG_TEXT.settings.min_length"
          />
          <Controller
            control={control}
            render={({ field }) => (
              <InputNumber status={!!errors?.LONG_TEXT?.settings?.max_value ? 'error' : ''} {...field} min={0} />
            )}
            name="LONG_TEXT.settings.max_length"
          />
        </Space>
      </Space>
      <Space direction="vertical" style={{ marginTop: 10 }}>
        {!!errors?.LONG_TEXT?.settings?.min_length?.message && (
          <div>{errors?.LONG_TEXT?.settings?.min_length?.message}</div>
        )}
        {!!errors?.LONG_TEXT?.settings?.max_length?.message && (
          <div>{errors?.LONG_TEXT?.settings?.min_length?.message}</div>
        )}
      </Space>
    </>
  );
};

export default LongTextSettings;
