import React, { useMemo } from 'react';
import {
  QuestionnairesBlocksGradeScaleParams,
  QuestionnairesBlocksGradeScaleValues,
} from 'api/questionnairesApi/types/gradeScale.types';
import styles from './GradeScaleCard.module.scss';
import { Controller } from 'react-hook-form';

interface Props {
  values: QuestionnairesBlocksGradeScaleValues[];
  params: QuestionnairesBlocksGradeScaleParams[];
  control?: any;
  id?: string;
  readOnly?: boolean;
}

const GradeScaleCard = ({ readOnly, values = [], params = [], control, id = '' }: Props) => {
  const headerValues = useMemo(() => [{ value: 'Название' }, ...values], [values]);

  // Инициализируем объект для хранения выбранных значений радиобаттонов
  let selectedValues: { [key: string]: string } = {};

  const CustomTableControl = ({ value, onChange }: { value: any; onChange: (params: any) => void }) => {
    selectedValues = value;
    return (
      <div className={styles.tableBody}>
        {params?.map((param: any, index: number) => (
          <div className={styles.tableBodyRow} key={index + param?.value}>
            <div className={styles.tableBodyCell}>{param.value}</div>
            {values.map((column, indexRow: number) => {
              const radioButtonName = `${param.id}_${column.id}`; // Уникальный идентификатор для каждого радиобаттона
              const checked = selectedValues[param.id] === column.id; // Проверяем, выбран ли этот радиобаттон

              return (
                <div className={styles.tableBodyCell} key={column.value + param?.value + indexRow}>
                  <div className={styles.radio}>
                    <input
                      onChange={() => {
                        // Обновляем объект выбранных значений
                        selectedValues[param.id] = column.id;
                        onChange(selectedValues);
                      }}
                      type="radio"
                      name={radioButtonName} // Устанавливаем уникальное имя
                      disabled={readOnly}
                      checked={checked}
                      value={column.value}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.table}>
      <div className={styles.tableHead}>
        {headerValues?.map((el: any, index: number) => (
          <div className={styles.tableHeadCell} key={index + el?.value}>
            {el?.value}
          </div>
        ))}
      </div>
      <Controller
        defaultValue={{}}
        render={({ field }) => <CustomTableControl value={field.value} onChange={field.onChange} />}
        name={id}
        control={control}
      />
    </div>
  );
};

export default GradeScaleCard;
