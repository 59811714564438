import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

import authSlice from './authSlice';
import questionnaireSlice from './QuestionnairesSlice';
import directoriesSlice from './DirectoriesSlice';
import fillFormSlice from './FillFormSlice';

import { listenerMiddleware } from './listenerMiddleware';

// Notifications
import './authSlice/middleware';
import './QuestionnairesSlice/middleware';
import './FillFormSlice/middleware';
import resultSlice from './ResultSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    questionnaires: questionnaireSlice,
    directories: directoriesSlice,
    fillForm: fillFormSlice,
    results: resultSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(listenerMiddleware.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
