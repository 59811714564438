import { DatePicker } from 'antd';
import dayJS from 'dayjs';

import { dateFormat } from './DateSettings';
import { Controller } from 'react-hook-form';

interface Props {
  default_value: string;
  min_date_value?: string;
  max_date_value?: string;
  control?: any;
  id?: string;
  readOnly?: boolean;
}

const DateCard = ({ default_value, min_date_value, max_date_value, readOnly, control, id = '' }: Props) => {
  return (
    <div>
      Укажите дату:
      <Controller
        defaultValue={dayJS(default_value, dateFormat)}
        disabled={readOnly}
        render={({ field }) => (
          <DatePicker
            disabledDate={(date) =>
              !date ||
              date.isBefore(dayJS(min_date_value).format(dateFormat)) ||
              date.isAfter(dayJS(max_date_value).format(dateFormat))
            }
            format={dateFormat}
            style={{ marginLeft: 8, width: 186 }}
            defaultValue={dayJS(default_value, dateFormat)}
            {...field}
          />
        )}
        name={id}
        control={control}
      />
    </div>
  );
};

export default DateCard;
