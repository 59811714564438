import { Col, Row } from 'antd';
import { clsx } from 'clsx';

import { ReactComponent as SendIcon } from 'shared/assets/icons/send_colored.svg';
import { ReactComponent as ChatIcon } from 'shared/assets/icons/chat_colored.svg';
import { ReactComponent as MessageIcon } from 'shared/assets/icons/message_colored.svg';

import styles from './Footer.module.scss';

const pdfPath = process.env.PUBLIC_URL + '/file/personalData.pdf';

const Footer = () => {
  return (
    <footer className={styles.container}>
      <div className={styles.contacts}>
        <div>
          <h2 className={styles.title}>Контакты</h2>
          <p className={styles.rights}>2024 © АО «Ланцет»</p>
        </div>
        <div>
          <a className={clsx(styles.link, styles.phone)} href="tel:+74956465665">
            +7 (495) 646-56-65
          </a>
          <a className={clsx(styles.link, styles.fax)} href="tel:+74956465666">
            +7 (495) 646-56-66 (факс)
          </a>
          <a className={clsx(styles.link, styles.email)} href="mailto:medanketa@lancetpharm.ru">
            medanketa@lancetpharm.ru
          </a>
          <a href={pdfPath} className={clsx(styles.link, styles.file)} target="_blank" rel="noopener noreferrer">
            Политика защиты персональных данных
          </a>
        </div>
      </div>

      <div className={styles.decorations}>
        <SendIcon className={styles.send} />
        <ChatIcon className={styles.chat} />
        <MessageIcon className={styles.message} />
      </div>
    </footer>
  );
};

export default Footer;
