import { useContext } from 'react';
import { Col } from 'antd';
import { Controller } from 'react-hook-form';

import Input from '../../../Input';
import { ContextFormUtilities } from '../../../EditCardContainer';

import styles from './BinaryChoiceSubCard.module.scss';

const BinaryChoiceSubCard = () => {
  const context = useContext(ContextFormUtilities);
  const control = context.control;

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <Col flex="190px" className={styles.text}>
          Положительный ответ (Да):
        </Col>
        <Col flex="auto">
          <Controller
            control={control}
            render={({ field }) => (
              <Input className={styles.input} {...field} placeholder="Введите текст положительного ответа" />
            )}
            name="BINARY.true_label"
          />
        </Col>
      </div>
      <div className={styles.item}>
        <Col flex="190px" className={styles.text}>
          Отрицательный ответ (Нет):
        </Col>
        <Col flex="auto">
          <Controller
            control={control}
            render={({ field }) => (
              <Input
                defaultValue=""
                className={styles.input}
                {...field}
                placeholder="Введите текст отрицательного ответа"
              />
            )}
            name="BINARY.false_label"
          />
        </Col>
      </div>
    </div>
  );
};

export default BinaryChoiceSubCard;
