import { Fragment, useContext, useMemo } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import clsx from 'clsx';
import { Col, Collapse, Input, List, Row, Space, Typography } from 'antd';

import { ContextFormUtilities } from '../../../EditCardContainer';

import { ReactComponent as ArrowSVG } from 'shared/assets/icons/arrow.svg';
import { ReactComponent as PlusSVG } from 'shared/assets/icons/plus.svg';
import { ReactComponent as GarbageSVG } from 'shared/assets/icons/garbage.svg';

import styles from './DropdownSubCard.module.scss';
import { nanoid } from 'nanoid';

const { Title } = Typography;

interface IProps {
  dictValue?: any;
}

const DropdownSubCard = ({ dictValue }: IProps) => {
  const context = useContext(ContextFormUtilities);
  const control = context.control;

  const { fields, append, remove, move, insert } = useFieldArray({
    keyName: 'customValuesId',
    name: 'values',
    control,
  });

  const mappedDirectory = useMemo(
    () =>
      dictValue?.items?.map((el: any) => {
        if ('children' in el) {
          return {
            key: el.id,
            label: el.value,
            children: (
              <List
                style={{ marginLeft: 20, marginTop: 0 }}
                key={el.id}
                split={false}
                dataSource={el.children.map((child: any) => child.value)}
                renderItem={(item: any) => <List.Item>{item}</List.Item>}
              />
            ),
          };
        }
        return {
          key: el.id,
          label: el.value,
          showArrow: false,
          collapsible: 'icon',
        };
      }),
    [dictValue],
  );

  const addMore = (index?: number) => {
    if (index !== undefined) {
      insert(index + 1, { id: nanoid(), value: '' });
    } else {
      append({ id: nanoid(), value: '' });
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {dictValue ? (
        <div style={{ width: '100%' }}>
          <Title level={5}>{dictValue?.name}</Title>
          <div className={styles.hideScrollbar}>
            <Collapse ghost items={mappedDirectory} />
          </div>
        </div>
      ) : (
        <div className={styles.hideScrollbar}>
          {fields.map((item: any, index: number) => {
            return (
              <Fragment key={item.id}>
                <Row gutter={[8, 0]} wrap={false} className={clsx(styles.rowWidth, styles.flex)}>
                  <Col flex="5%" className={styles.flex}>
                    <ArrowSVG
                      onClick={() => {
                        if (index !== 0) {
                          move(index, index - 1);
                        }
                      }}
                      className={clsx(styles.arrow, styles.icon)}
                    />
                    <ArrowSVG
                      onClick={() => {
                        if (index !== fields.length - 1) {
                          move(index, index + 1);
                        }
                      }}
                      className={styles.icon}
                    />
                  </Col>
                  <Col flex="90%" className={styles.flex}>
                    <Controller
                      control={control}
                      name={`values.${index}.value`}
                      render={({ field }) => <Input placeholder="Введите ответ" className={styles.input} {...field} />}
                    />
                  </Col>
                  <Col
                    flex="6%"
                    className={clsx(styles.flex)}
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <PlusSVG onClick={() => addMore(index)} className={styles.icon} />
                    <GarbageSVG onClick={() => remove(index)} className={styles.icon} />
                  </Col>
                </Row>
              </Fragment>
            );
          })}
          <Space direction="vertical">
            <Col onClick={() => addMore()} flex="auto" className={styles.addMoreContainer}>
              <PlusSVG className={styles.addMore} />
              <span>Добавить еще</span>
            </Col>
          </Space>
        </div>
      )}
    </div>
  );
};

export default DropdownSubCard;
