import { createContext, useState } from 'react';
import { Card, Popconfirm, Space, Switch } from 'antd';
import { Controller, FieldArray, FieldArrayMethodProps, useForm } from 'react-hook-form';
import { unstable_usePrompt, useParams } from 'react-router-dom';
import { QuestionnairesBlocks } from 'api/questionnairesApi/types';
import createQuestionnaireBlockAction from 'store/QuestionnairesSlice/actions/createQuestionnaireBlock';
import { useAppDispatch } from 'store';
import deleteQuestionnaireBlockAction from 'store/QuestionnairesSlice/actions/deleteQuestionnaireBlock';
import editQuestionnaireBlockAction from 'store/QuestionnairesSlice/actions/editQuestionnaireBlock';

import { ActiveTabs, editCardOptions } from './headers';
import { valuesConstructor } from './defaultValuesEditAndCreateCard';

import Button from 'components/Button';

import { ReactComponent as GarbageSVG } from 'shared/assets/icons/garbage.svg';

import { questionContent } from './questionContent';
import { getFormattedDataByType } from './getFormattedDataByType';

import { yupResolver } from '@hookform/resolvers/yup';
import { getValidationSchema } from './validation';
import clsx from 'clsx';

import styles from './EditCardContainer.module.scss';

interface Props {
  onClose: () => void;
  isEdit?: boolean;
  isCreate?: boolean;
  onSave: () => void;
  insert: (index: number, value: Partial<FieldArray> | Partial<FieldArray>[], options?: FieldArrayMethodProps) => void;
  idx: number;
  prevAndNextIDS: { prev_block_id: string; next_block_id: string };
}

export const ContextFormUtilities = createContext<any>(null);

const EditCardContainer = ({
  onClose,
  onSave,
  insert,
  idx,
  isCreate,
  prevAndNextIDS,
  ...props
}: Props & QuestionnairesBlocks) => {
  const [activeTab, setActiveTab] = useState<ActiveTabs>('content');
  const { id: formID } = useParams();
  const [selectedSubContent, setSelectedSubContent] = useState(props.type);
  const [content, setContent] = useState(props.content);
  const [isRequiredQuestion, setIsRequiredQuestion] = useState(props?.settings?.required);
  const [previewState, setPreviewState] = useState<any>(null);
  const [isSelectedDictId, setSelectedDict] = useState<string>('');
  const dispatch = useAppDispatch();

  unstable_usePrompt({
    message: 'Несохраненные данные будут потеряны!',
    when: ({ currentLocation, nextLocation }) => !!props.isEdit && currentLocation.pathname !== nextLocation.pathname,
  });

  const {
    control,
    getValues,
    setValue,
    resetField,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...valuesConstructor(selectedSubContent, undefined, content),
    },
    mode: 'all',
    values: {
      ...valuesConstructor(selectedSubContent, props, content),
    },
    resolver: yupResolver(getValidationSchema(selectedSubContent)) as any,
  });

  const onSubmit = async (data: any) => {
    if (!formID) return;
    let actualData = getFormattedDataByType(data, formID, props.id);
    if (isCreate) {
      actualData = {
        ...actualData,
        values_dictionary_id: isSelectedDictId,
      };
      await dispatch(createQuestionnaireBlockAction({ ...actualData, ...prevAndNextIDS }));
    } else {
      await dispatch(editQuestionnaireBlockAction({ ...actualData, ...prevAndNextIDS }));
    }

    onSave();
  };

  const onChangeSwitch = (checked: boolean) => {
    setIsRequiredQuestion(checked);
  };

  const handleDelete = async () => {
    if (!formID) return;
    await dispatch(deleteQuestionnaireBlockAction({ formID: formID, blockID: props.id }));
    onClose();
  };

  const onTab2Change = (tab: string) => {
    setActiveTab(tab as ActiveTabs);
  };

  // const onCopyClick = () => {
  //   insert && insert(idx, { ...props, isInsert: true });
  // };

  return (
    <ContextFormUtilities.Provider value={{ resetField, previewState, errors, control, setValue, getValues }}>
      <Card
        className={styles.card}
        tabList={editCardOptions(selectedSubContent)}
        activeTabKey={activeTab}
        onTabChange={onTab2Change}
        actions={[
          <div key="footerCard" className={styles.footer}>
            <Space size={16}>
              <Button onClick={handleSubmit(onSubmit)} type="primary" value="large">
                Сохранить
              </Button>
              <Button type="link" onClick={onClose} value="large">
                Отмена
              </Button>
            </Space>

            <Space align="center" size={24}>
              {selectedSubContent !== 'INFO' && (
                <Space align="center" size={8}>
                  Обязательный вопрос:
                  <Controller
                    control={control}
                    // @ts-ignore
                    name={`${selectedSubContent}.settings.required`}
                    render={({ field }) => {
                      return (
                        <Switch
                          {...field}
                          checked={isRequiredQuestion}
                          onChange={(e) => {
                            field.onChange(e);
                            onChangeSwitch(e);
                          }}
                        />
                      );
                    }}
                  />
                </Space>
              )}

              {/*<CopySVG onClick={onCopyClick} className={clsx(styles.svg && styles.disabled)} />*/}
              <Popconfirm
                title="Подтвердите действие"
                description="Удалить вопрос?"
                onConfirm={handleDelete}
                disabled={isCreate}
              >
                <GarbageSVG className={clsx(styles.svg, isCreate && styles.disabled)} />
              </Popconfirm>
            </Space>
          </div>,
        ]}
        bodyStyle={{ padding: '16px 24px ' }}
        tabProps={{
          size: 'small',
          onTabClick: () => setPreviewState(getValues()),
        }}
      >
        {
          questionContent({
            selectedSubContent,
            setContent,
            setSelectedSubContent,
            setSelectedDict,
            ...props,
          })[activeTab]
        }
      </Card>
    </ContextFormUtilities.Provider>
  );
};

export default EditCardContainer;
