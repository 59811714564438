import HeroSection from './HeroSection';
import AdvantagesSection from './AdvantagesSection';
import DictsSection from './DictsSection';
import Footer from './Footer';

const PromoPage = () => {
  return (
    <div>
      <main>
        <section id="about">
          <HeroSection />
        </section>

        <section id="advantages">
          <AdvantagesSection />
        </section>

        <section style={{ scrollMargin: '100px' }} id="directories">
          <DictsSection />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default PromoPage;
