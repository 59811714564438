import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import directoriesAction from 'store/DirectoriesSlice/actions/directoriesAction';
import { directoriesSelector } from 'store/DirectoriesSlice/selectors';

import DictItem from './DictItem';
import DictDetails from './DictDetails';

import styles from './DictsSection.module.scss';
import { Skeleton } from 'antd';

const DictsSection = () => {
  const dispatch = useAppDispatch();
  const { globalDirectories, loading } = useAppSelector(directoriesSelector);

  const dicts = globalDirectories?.map(({ name, id }) => ({ label: name, key: id }));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeDictId, setActiveDictId] = useState<string | number | null>(null);

  const openDict = (id: number | string) => {
    setActiveDictId(id);
    setIsModalOpen(true);
  };

  const closeDict = () => {
    setActiveDictId(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(directoriesAction());
  }, []);

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.title}>Справочники уже на платформе</h2>
      </div>

      <ul className={styles.list}>
        {loading ? (
          <Skeleton />
        ) : (
          <>
            {dicts?.map(({ label, key }) => (
              <DictItem title={label} id={key} onClick={openDict} key={key} />
            ))}
          </>
        )}
      </ul>

      {activeDictId !== null && <DictDetails isOpen={isModalOpen} onCancel={closeDict} activeDictId={activeDictId} />}
    </div>
  );
};

export default DictsSection;
