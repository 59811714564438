import instance from '../../connection';
import axios, { AxiosError, AxiosResponse } from 'axios';

interface IGetFormsProps {
  key: string;
  pass?: string;
}

export const getForms = async ({ key, pass }: IGetFormsProps, thunkAPI: any) => {
  try {
    const response = await instance.get<any, AxiosResponse<any>>(`/user/forms/${key}`, {
      headers: {
        pass: pass,
      },
    });

    return response.data;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;
    console.log(error);
  }
};

export const submitForm = async (
  { key, values, formId }: { key: string; values: any[]; formId: string },
  thunkAPI: any,
) => {
  try {
    const response = await instance.post<any, AxiosResponse<any>>(`/user/forms/${key}/answer`, values, {
      params: {
        formAnswerId: formId,
      },
    });

    return response.data;
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;
    console.log(error);
  }
};
