// Core
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'store';
import ErrorBoundary from 'app/providers/ErrorBoundary';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Antd theme
import theme from 'app/styles/themes/antd.theme.styles';
// Components
import { App } from 'app';

const container = document.getElementById('root') as HTMLElement;

if (!container) {
  throw new Error('Контейнер root не найден. НЕ удалось вмонтировать реакт приложение');
}

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <ConfigProvider theme={theme} locale={ruRU}>
        <ToastContainer />
        <App />
      </ConfigProvider>
    </ErrorBoundary>
  </Provider>,
);
