import dayJS from 'dayjs';

import { QuestionnairesType } from 'api/questionnairesApi/types';
import { QuestionnairesBlocksInfo, QuestionnairesBlocksInfoForm } from 'api/questionnairesApi/types/info.types';
import { QuestionnairesBlocksBinary, QuestionnairesBlocksBinaryForm } from 'api/questionnairesApi/types/binary.types';
import { QuestionnairesBlocksNumbers, QuestionnairesBlocksNumbersForm } from 'api/questionnairesApi/types/number.types';
import {
  QuestionnairesBlocksLongText,
  QuestionnairesBlocksLongTextForm,
} from 'api/questionnairesApi/types/longText.types';
import { QuestionnairesBlocksDate, QuestionnairesBlocksDateForm } from 'api/questionnairesApi/types/date.types';
import {
  QuestionnairesBlocksDropdown,
  QuestionnairesBlocksDropdownForm,
} from 'api/questionnairesApi/types/dropdown.types';
import { QuestionnairesBlocksRating, QuestionnairesBlocksRatingForm } from 'api/questionnairesApi/types/rating.types';
import {
  QuestionnairesBlocksShortText,
  QuestionnairesBlocksShortTextForm,
} from 'api/questionnairesApi/types/shortText.types';
import {
  QuestionnairesBlocksMultiSelect,
  QuestionnairesBlocksMultiSelectForm,
} from 'api/questionnairesApi/types/multiSelect.types';
import { dateFormat } from '../questionnaires/MainCards/components/DateCard/DateSettings';
import {
  QuestionnairesBlocksGradeScale,
  QuestionnairesBlocksGradeScaleForm,
} from 'api/questionnairesApi/types/gradeScale.types';
import { nanoid } from 'nanoid';

export interface IForm {
  INFO: QuestionnairesBlocksInfoForm;
  BINARY: QuestionnairesBlocksBinaryForm;
  NUMBER: QuestionnairesBlocksNumbersForm;
  LONG_TEXT: QuestionnairesBlocksLongTextForm;
  DATE: QuestionnairesBlocksDateForm;
}

export const valuesConstructor = <T extends QuestionnairesType>(type: T, props: any, content?: string) => {
  const addId = (arrObj: any[] | undefined) => {
    if (!arrObj) return null;
    return arrObj?.map((obj, index) => ({
      id: String(index + 100),
      ...obj,
    }));
  };

  switch (type) {
    case 'INFO':
      const infoProps = props as QuestionnairesBlocksInfo;

      return {
        type,
        content: content ?? infoProps?.content ?? '',
        INFO: { sub_content: infoProps?.sub_content ?? '' },
      } as QuestionnairesBlocksInfoForm;
    case 'BINARY':
      const binaryProps = props as QuestionnairesBlocksBinary;

      return {
        type,
        content: content ?? binaryProps?.content ?? '',
        BINARY: {
          true_label: binaryProps?.true_label ?? '',
          false_label: binaryProps?.false_label ?? '',
          settings: {
            required: binaryProps?.settings?.required ?? false,
          },
        },
      } as QuestionnairesBlocksBinaryForm;
    case 'NUMBER':
      const numberProps = props as QuestionnairesBlocksNumbers;

      return {
        type,
        content: content ?? numberProps?.content ?? '',
        NUMBER: {
          settings: {
            required: numberProps?.settings?.required ?? false,
            use_float: numberProps?.settings?.use_float ?? false,
            max_value: numberProps?.settings?.max_value ?? 100000,
            min_value: numberProps?.settings?.min_value ?? 0,
          },
        },
      } as QuestionnairesBlocksNumbersForm;
    case 'LONG_TEXT':
      const longTextProps = props as QuestionnairesBlocksLongText;

      return {
        type,
        content: content ?? longTextProps?.content ?? '',
        LONG_TEXT: {
          settings: {
            required: longTextProps?.settings?.required ?? false,
            min_length: longTextProps?.settings?.min_length ?? 0,
            max_length: longTextProps?.settings?.max_length ?? 10000,
          },
        },
      } as QuestionnairesBlocksLongTextForm;
    case 'SHORT_TEXT':
      const shortTextProps = props as QuestionnairesBlocksShortText;

      return {
        type,
        content: content ?? shortTextProps?.content ?? '',
        SHORT_TEXT: {
          settings: {
            validation_type: shortTextProps?.settings?.validation_type ?? '',
            required: shortTextProps?.settings?.required ?? false,
            min_length: shortTextProps?.settings?.min_length ?? 0,
            max_length: shortTextProps?.settings?.max_length ?? 10000,
          },
        },
      } as QuestionnairesBlocksShortTextForm;
    case 'DATE':
      const dateProps = props as QuestionnairesBlocksDate;

      return {
        type,
        content: content ?? dateProps?.content ?? '',
        DATE: {
          settings: {
            required: dateProps?.settings?.required ?? false,
            default_value: dateProps?.settings?.default_value ?? dayJS().format(dateFormat),
            max_date_value: dateProps?.settings?.max_date_value ?? dayJS().subtract(1, 'day').format(dateFormat),
            min_date_value: dateProps?.settings?.min_date_value ?? dayJS().add(1, 'day').format(dateFormat),
          },
        },
      } as QuestionnairesBlocksDateForm;
    case 'DROPDOWN':
      const dropDownProps = props as QuestionnairesBlocksDropdown;

      return {
        type,
        content: content ?? dropDownProps?.content ?? '',
        values: addId(dropDownProps?.values) ?? [{ id: '1', value: '' }],
        DROPDOWN: {
          settings: {
            required: dropDownProps?.settings?.required ?? false,
            use_filter: dropDownProps?.settings?.use_filter ?? false,
            select_first: dropDownProps?.settings?.select_first ?? false,
            sort_type: dropDownProps?.settings?.sort_type ?? 'DEFAULT',
          },
        },
        values_dictionary_id: dropDownProps?.values_dictionary_id ?? undefined,
      } as QuestionnairesBlocksDropdownForm;

    case 'RATING':
      const ratingProps = props as QuestionnairesBlocksRating;

      return {
        type,
        content: content ?? ratingProps?.content ?? '',
        values: addId(ratingProps?.values) ?? [{ id: '1', value: '' }],
        RATING: {
          settings: {
            required: ratingProps?.settings?.required ?? false,
            sort_type: ratingProps?.settings?.sort_type ?? 'DEFAULT',
          },
        },
      } as QuestionnairesBlocksRatingForm;
    case 'MULTI_SELECT':
      const multiSelectProps = props as QuestionnairesBlocksMultiSelect;

      return {
        type,
        content: content ?? multiSelectProps?.content ?? '',
        values: addId(multiSelectProps?.values) ?? [{ id: '1', value: '' }],
        MULTI_SELECT: {
          settings: {
            required: multiSelectProps?.settings?.required ?? false,
            max_answers: multiSelectProps?.settings?.max_answers ?? 5,
            min_answers: multiSelectProps?.settings?.min_answers ?? 0,
            sort_type: multiSelectProps?.settings?.sort_type ?? 'DEFAULT',
          },
        },
        values_dictionary_id: multiSelectProps?.values_dictionary_id ?? undefined,
      } as QuestionnairesBlocksMultiSelectForm;

    case 'GRADE_SCALE':
      const gradeScaleProps = props as QuestionnairesBlocksGradeScale;

      return {
        type,
        content: content ?? gradeScaleProps?.content ?? '',
        values: addId(gradeScaleProps?.values) ?? [
          { id: '1', value: 'Плохо' },
          { id: '2', value: 'Нейтрально' },
          { id: '3', value: 'Хорошо' },
        ],
        params: gradeScaleProps?.params ?? [{ id: '1', value: '' }],
        GRADE_SCALE: {
          settings: {
            required: gradeScaleProps?.settings?.required ?? false,
          },
        },
      } as QuestionnairesBlocksGradeScaleForm;
  }
};
