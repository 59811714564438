// Core
import { ButtonProps, Button as AntdButton } from 'antd';
import clsx from 'clsx';

import styles from './Button.module.scss';

const Button = ({ children, className, ...props }: ButtonProps) => {
  return (
    <AntdButton
      className={clsx(styles.default, className, {
        [styles.primary]: props.type === 'primary',
        [styles.link]: props.type === 'link',
      })}
      {...props}
    >
      {children}
    </AntdButton>
  );
};

export default Button;
