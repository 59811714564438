import styles from './InteractiveItem.module.scss';

const InteractiveItem = ({ text }: { text: string }) => {
  return (
    <li className={styles.container}>
      {text}
    </li>
  )
};

export default InteractiveItem;
