import Input from '../../../../Input';

import styles from './ShortTextCard.module.scss';
import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { Alert } from 'antd';
import { Controller } from 'react-hook-form';

interface IProps {
  min?: number;
  max?: number;
  validation?: string;
  control?: any;
  id?: string;
  readOnly?: boolean;
  setError?: (name: string, value: any) => void;
  clearErrors?: (name: string) => void;
}

const ShortTextCard = ({
  readOnly,
  setError,
  clearErrors,
  id = '',
  control,
  min = 0,
  max = 10000,
  validation,
}: IProps) => {
  const [shortTextError, setShortTextError] = useState<string | null>(null);

  const onShortTextInputChane = debounce((el) => {
    if (validation === 'EMAIL') {
      const regexp =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isEmail = regexp.test(el.target.value);
      if (isEmail) {
        setShortTextError(null);
      } else {
        setShortTextError('Введите валидный Email');
      }
    }
    if (validation === 'PHONE') {
      const regexp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
      const isPhone = regexp.test(el.target.value);
      if (isPhone) {
        setShortTextError(null);
      } else {
        setShortTextError('Введите валидный номер телефона');
      }
    }
    if (validation === 'INN') {
      const regexp = /(?!(.)\1{6,})(^[0-9]{9}$)/;
      const isPhone = regexp.test(el.target.value);
      if (isPhone) {
        setShortTextError(null);
      } else {
        setShortTextError('Введите валидный INN');
      }
    }
  }, 300);

  useEffect(() => {
    if (!!shortTextError) {
      setError?.(id, { type: 'custom', message: shortTextError });
    } else {
      clearErrors?.(id);
    }
  }, [shortTextError, setError, clearErrors]);

  return (
    <>
      <Controller
        control={control}
        name={id}
        disabled={readOnly}
        rules={{
          validate: () => !!shortTextError,
        }}
        render={({ field }) => (
          <Input
            minLength={min}
            maxLength={max}
            className={styles.input}
            status={!!shortTextError ? 'error' : ''}
            placeholder="Введите текст вопроса"
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onShortTextInputChane(e);
            }}
          />
        )}
      />

      <div style={{ marginTop: 20 }}>
        {shortTextError && <Alert type="error" message={shortTextError} showIcon={true} />}
      </div>
    </>
  );
};

export default ShortTextCard;
