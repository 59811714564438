import * as yup from 'yup';
import { QuestionnairesType } from 'api/questionnairesApi/types';

export const getValidationSchema = (type: QuestionnairesType) => {
  switch (type) {
    case 'INFO':
      return yup.object().shape({
        content: yup.string().required('Обязательное поле'),
        type: yup.string(),
      });
    case 'BINARY':
      return yup.object().shape({
        content: yup.string().required('Обязательное поле'),
        type: yup.string(),
        BINARY: yup.object().shape({
          true_label: yup.string().required('Положительный ответ - обязательное поле для BINARY типа'),
          false_label: yup.string().required('Отрицательный ответ- обязательное поле для BINARY типа'),
          settings: yup.object().shape({
            required: yup.boolean(),
          }),
        }),
      });
    case 'NUMBER':
      return yup.object().shape({
        content: yup.string().required('Обязательное поле'),
        type: yup.string(),
        NUMBER: yup.object().shape({
          type: yup.string(),
          settings: yup.object().shape({
            required: yup.boolean(),
            use_float: yup.boolean(),
            min_value: yup
              .number()
              .required('Минимальное значение не может быть пустым')
              .when(['max_value'], ([max_value], schema) => {
                return schema.test('min_value', 'Минимальное значение не должно быть больше максимального!', (el) => {
                  return !(el && el > max_value);
                });
              }),
            max_value: yup.number().required('Максимальное значение не может быть пустым'),
          }),
        }),
      });
    case 'LONG_TEXT':
      return yup.object().shape({
        content: yup.string().required('Обязательное поле'),
        type: yup.string(),
        LONG_TEXT: yup.object().shape({
          settings: yup.object().shape({
            required: yup.boolean(),
            min_length: yup
              .number()
              .required('Минимальное значение не может быть пустым')
              .when(['max_length'], ([max_length], schema) => {
                return schema.test('min_length', 'Минимальное значение не должно быть больше максимального!', (el) => {
                  return !(el && el > max_length);
                });
              }),
            max_length: yup.number().required('Максимальное значение не может быть пустым'),
          }),
        }),
      });
    case 'SHORT_TEXT':
      return yup.object().shape({
        content: yup.string().required('Обязательное поле'),
        // .when([], ([], schema) => {
        //   return schema.test('content_short', 'aboba', (el, context) => {
        //     if (context.parent.SHORT_TEXT.settings['validation_type'] === 'EMAIL') {
        //       const regexp =
        //         /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //       return Boolean(el && regexp.test(el));
        //     }
        //     return true;
        //   });
        // }),
        type: yup.string(),
        SHORT_TEXT: yup.object().shape({
          settings: yup.object().shape({
            required: yup.boolean(),
            min_length: yup
              .number()
              .required('Минимальное значение не может быть пустым')
              .when(['max_length'], ([max_length], schema) => {
                return schema.test('min_length', 'Минимальное значение не должно быть больше максимального!', (el) => {
                  return !(el && el > max_length);
                });
              }),
            max_length: yup.number().required('Максимальное значение не может быть пустым'),
          }),
        }),
      });
    default:
      return yup.object().shape({
        content: yup.string().required('Обязательное поле'),
        type: yup.string(),
      });
  }
};
