import { FieldArray, FieldArrayMethodProps } from 'react-hook-form';

import { createCardSettings } from 'pages/ItemQuestionnairesPage/createCardSettings';
import { QuestionnairesType } from 'api/questionnairesApi/types';
import { ReactComponent as PlusSVG } from 'shared/assets/icons/plus.svg';

import styles from './AddMore.module.scss';
import { nanoid } from 'nanoid';

interface Props {
  idx: number;
  insert: (index: number, value: Partial<FieldArray> | Partial<FieldArray>[], options?: FieldArrayMethodProps) => void;
  content: string;
  sub_content?: string;
  type: QuestionnairesType;
  swap: (indexA: number, indexB: number) => void;
}

/*
  isEdit: boolean | флаг для создания нового филда с редактированием (локальный стейт) Кнопки: (добавить еще)(добавить вопрос)
  isInsert: boolean | флаг добавляется при создании копии филда (локальный стейт) Кнопка: (добавить еще)
  isCreate: boolean | флаг для создания нового филда (локальный стейт) Кнопка: (добавить вопрос)
*/

const AddMore = (props: Props) => {
  return (
    <div
      className={styles.wrapper}
      onClick={() => {
        props.insert(props.idx + 1, {
          isCreate: true,
          id: nanoid(),
          content: props.content,
          sub_content: props.sub_content,
          type: props.type || 'INFO',
          isEdit: true,
          isInsert: true,
          settings: createCardSettings(props.type),
        });
        // props.swap(props.idx, props.idx + 1);
      }}
    >
      {<div className={styles.content}>
        <PlusSVG className={styles.icon} />
        <span>Добавить еще</span>
      </div>}
    </div>
  );
};

export default AddMore;
