import { Card, Modal, Typography } from 'antd';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import getFormsAction from '../../../../store/FillFormSlice/actions/getFormsAction';
import { useAppDispatch, useAppSelector } from '../../../../store';
import styles from '../FillFormStates.module.scss';
import { fillFormQuestionsSelector } from '../../../../store/FillFormSlice/selectors';

const { Title } = Typography;

const NotActive = () => {
  const { errors } = useAppSelector(fillFormQuestionsSelector);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isSecure = searchParams.get('isSecure');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: key } = useParams();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [passwordValue, setPasswordValue] = useState<string>('');

  useEffect(() => {
    if (Boolean(isSecure)) {
      if (errors.data.code === 'form.wrong.password') {
        setIsModalOpen(true);
      }
    }
  }, [isSecure, errors]);

  const onOk = () => {
    dispatch(getFormsAction({ key: key || '', pass: passwordValue }));
    setPasswordValue('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onOk();
    }
  };

  return (
    <div className={styles.wrapper}>
      <Modal
        open={isModalOpen}
        title="Пароль"
        closeIcon={null}
        onOk={onOk}
        cancelButtonProps={{ className: styles.cancelButton }}
      >
        <Input
          onKeyDown={handleKeyDown}
          required={true}
          name="password"
          value={passwordValue}
          onChange={(e) => setPasswordValue(e.target.value)}
        />
      </Modal>
      <Card className={styles.cardWrapper}>
        <Title level={4}>Анкета не активна</Title>
      </Card>
      <Button onClick={() => navigate('/')} size="large">
        Вернуться на сайт
      </Button>
    </div>
  );
};

export default NotActive;
