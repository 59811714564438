import { memo, useContext } from 'react';
// Context
import { ContextFormUtilities } from 'components/EditCardContainer';
// Types
import { QuestionnairesBlocksSettingsLongText, QuestionnairesType } from 'api/questionnairesApi/types';
import { QuestionnairesBlocksSettingsNumber } from 'api/questionnairesApi/types/number.types';
import { QuestionnairesBlocksSettingsShortText } from 'api/questionnairesApi/types/shortText.types';
import { QuestionnairesBlocksSettingsMultiSelect } from 'api/questionnairesApi/types/multiSelect.types';
import { QuestionnairesBlocksSettingsDate } from 'api/questionnairesApi/types/date.types';
import { QuestionnairesBlocksSettingsRating } from 'api/questionnairesApi/types/rating.types';
import { QuestionnairesBlocksSettingsDropdown } from 'api/questionnairesApi/types/dropdown.types';
//Components
import NumberSettings from 'components/questionnaires/MainCards/components/NumberCard/NumberSettings';
import LongTextSettings from 'components/questionnaires/MainCards/components/LongTextCard/LongTextSettings';
import ShortTextSettings from 'components/questionnaires/MainCards/components/ShortTextCard/ShortTextSettings';
import DateSettings from 'components/questionnaires/MainCards/components/DateCard/DateSettings';
import DropdownSettings from 'components/questionnaires/MainCards/components/DropdownCard/DropdownSettings';
import RatingSettings from 'components/questionnaires/MainCards/components/RatingCard/RatingSettings';
import MultiSelectSettings from 'components/questionnaires/MainCards/components/MultiSelectCard/MultiSelectSettings';

interface Props {
  type: QuestionnairesType;
  settings: any;
}

export const ActualSettings = ({ type, settings }: Props) => {
  const context = useContext(ContextFormUtilities);
  const errors = context.errors;
  const control = context.control;

  switch (type) {
    case 'NUMBER':
      const numberSettings = settings as QuestionnairesBlocksSettingsNumber;

      return <NumberSettings control={control} errors={errors} {...numberSettings} />;
    case 'LONG_TEXT':
      const longTextSettings = settings as QuestionnairesBlocksSettingsLongText;
      return <LongTextSettings control={control} errors={errors} {...longTextSettings} />;
    case 'SHORT_TEXT':
      const shortTextSettings = settings as QuestionnairesBlocksSettingsShortText;

      return <ShortTextSettings control={control} errors={errors} {...shortTextSettings} />;
    case 'DATE':
      const dateSettings = settings as QuestionnairesBlocksSettingsDate;

      return <DateSettings control={control} errors={errors} {...dateSettings} />;

    case 'DROPDOWN':
      const dropdownSettings = settings as QuestionnairesBlocksSettingsDropdown;

      return <DropdownSettings control={control} errors={errors} {...dropdownSettings} />;

    case 'RATING':
      const ratingSettings = settings as QuestionnairesBlocksSettingsRating;

      return <RatingSettings control={control} errors={errors} {...ratingSettings} />;

    case 'MULTI_SELECT':
      const multiSelectSettings = settings as QuestionnairesBlocksSettingsMultiSelect;

      return <MultiSelectSettings control={control} errors={errors} {...multiSelectSettings} />;

    default:
      return <div>Настроек еще нет</div>;
  }
};

export default memo(ActualSettings);
