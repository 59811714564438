import { Outlet } from 'react-router-dom';

import styles from './PromoLayout.module.scss';
import Header from './Header';
import { CookieNotice } from 'components/CookieNotice';

export const PromoLayout = () => {
  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <Header />
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
      <CookieNotice />
    </div>
  );
};
