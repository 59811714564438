import { QuestionnairesBlockID, QuestionnairesID, QuestionnairesType } from 'api/questionnairesApi/types';
import { toastify } from 'utils/toastify';

export const getFormattedDataByType = (
  data: { type: QuestionnairesType } & any,
  formID: QuestionnairesID,
  blockID?: QuestionnairesBlockID,
) => {
  const generalData = {
    formID,
    id: blockID,
    content: data.content,
    type: data.type,
  };

  switch (data.type) {
    case 'INFO':
      return {
        ...generalData,
        ...data.INFO,
      };
    case 'BINARY':
      return {
        ...generalData,
        ...data.BINARY,
      };
    case 'NUMBER':
      return {
        ...generalData,
        ...data.NUMBER,
      };
    case 'LONG_TEXT':
      return {
        ...generalData,
        ...data.LONG_TEXT,
      };
    case 'SHORT_TEXT':
      return {
        ...generalData,
        ...data.SHORT_TEXT,
      };
    case 'DATE':
      return {
        ...generalData,
        settings: {
          ...data.DATE.settings,
        },
      };
    case 'DROPDOWN':
      return {
        ...generalData,
        values: data.values,
        values_dictionary_id: data?.values_dictionary_id ?? undefined,
        ...data.DROPDOWN,
      };
    case 'RATING':
      return {
        ...generalData,
        values: data.values,
        ...data.RATING,
      };
    case 'GRADE_SCALE':
      return {
        ...generalData,
        values: data.values,
        params: data.params,
        ...data.GRADE_SCALE,
      };
    case 'MULTI_SELECT':
      return {
        ...generalData,
        values: data.values,
        values_dictionary_id: data?.values_dictionary_id ?? undefined,
        ...data.MULTI_SELECT,
      };
    default:
      toastify('error', 'Некорректный тип данных');
  }
};
