import { jwtDecode } from 'jwt-decode';

type PayloadIsValid = {
  exp: number;
  sub: string;
  roles: string[]; //TODO: fix type, add enum roles
};

export const payloadIsValid = (payload: PayloadIsValid) => payload && payload.exp * 1000 > new Date().getTime();

// export const verifyJWT = (response) => {
//   const { authorization } = response.headers;
//   const token = authorization.split(' ')[1];
//   let data = response.data.client;
//
//   if (typeof token !== 'string') {
//     return Promise.reject(new Error('Token provided to verifyJWT is missing or not a string'));
//   }
//
//   try {
//     const payload = jwtDecode(token);
//
//     if (payloadIsValid(payload)) {
//       // data = _extend(data, {
//       //   token: token,
//       //   payload: payload
//       // });
//
//       return Promise.resolve(data);
//     }
//
//     return Promise.reject(new Error('Invalid token: expired'));
//   } catch (error) {
//     return Promise.reject(new Error('Cannot decode malformed token.'));
//   }
// };
