import { Card, Row, Typography } from 'antd';
import Button from '../../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../app/providers/router/routes';

import styles from '../FillFormStates.module.scss';
import { useAppDispatch } from '../../../../store';
import setIsSubmittedAction from '../../../../store/FillFormSlice/actions/setIsSubmitted';

const { Title, Text } = Typography;

const Submitted = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className={styles.wrapper}>
      <Card className={styles.cardWrapper}>
        <Title level={4}>Спасибо! Анкета успешно отправлена</Title>
        <Text>Мы рады что вам понравилось</Text>
      </Card>
      <Row className={styles.rowWrapper}>
        <Button onClick={() => dispatch(setIsSubmittedAction(false))} size="large">
          Пройти заново
        </Button>
        <Button onClick={() => navigate(ROUTES.questionnaires)} size="large" type="primary">
          Вернуться на сайт
        </Button>
      </Row>
    </div>
  );
};

export default Submitted;
