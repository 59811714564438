import { useMemo } from 'react';
import { InputNumber, Space } from 'antd';

import { QuestionnairesBlocksRatingValues } from 'api/questionnairesApi/types/rating.types';

import styles from 'pages/ItemQuestionnairesPage/Questions/Questions.module.scss';
import { Controller } from 'react-hook-form';
import { sortArrayByType } from 'utils/sortArrayByType';
import { QuestionnairesBlocksSettingsRating } from 'api/questionnairesApi/types/rating.types';

interface Props {
  values: QuestionnairesBlocksRatingValues[];
  control?: any;
  id?: string;
  readOnly?: boolean;
  settings?: QuestionnairesBlocksSettingsRating;
}

const RatingCard = ({ readOnly, control, id = '', values = [], settings }: Props) => {
  const sortedValues = useMemo(
    () => sortArrayByType(values, 'value', settings?.sort_type),
    [values, settings?.sort_type],
  );

  return (
    <>
      {sortedValues.map((item) => (
        <Space key={item.id} className={styles.spaceItem} direction="vertical" size="middle">
          <Space direction="horizontal" size={12}>
            {/*<Controller*/}
            {/*  control={control}*/}
            {/*  name={id}*/}
            {/*  defaultValue={0}*/}
            {/*  render={({ field }) => <InputNumber defaultValue={0} {...field} />}*/}
            {/*/>*/}
            <InputNumber disabled={readOnly} defaultValue={0} />
            <span className={styles.spaceItemText}>{item.value}</span>
          </Space>
        </Space>
      ))}
    </>
  );
};

export default RatingCard;
