import { AxiosResponse } from 'axios';
import { startAppListening } from '../../listenerMiddleware';
import { toastify } from 'utils/toastify';
import getAllQuestionnairesAction from '../actions/getAllQuestionnaires';
import createQuestionnairesAction from '../actions/createQuestionnaires';
import deleteQuestionnairesAction from '../actions/deleteQuestionnaires';
import changeQuestionnairesStatusAction from '../actions/changeQuestionnairesStatus';

startAppListening({
  actionCreator: getAllQuestionnairesAction.rejected,
  effect: async (action, listenerApi) => {
    const payload = action.payload as AxiosResponse;

    toastify('error', `getAllQuestionnaires status:${payload.status}`);
  },
});

startAppListening({
  actionCreator: createQuestionnairesAction.fulfilled,
  effect: async () => {
    toastify('success', 'Анкета успешно создана');
  },
});

startAppListening({
  actionCreator: createQuestionnairesAction.rejected,
  effect: async () => {
    toastify('error', 'Не удалось создать анкету');
  },
});

startAppListening({
  actionCreator: deleteQuestionnairesAction.fulfilled,
  effect: async () => {
    toastify('info', 'Анкета успешно удалена');
  },
});

startAppListening({
  actionCreator: deleteQuestionnairesAction.rejected,
  effect: async () => {
    toastify('error', 'Не удалось удалить анкету');
  },
});

startAppListening({
  actionCreator: changeQuestionnairesStatusAction.fulfilled,
  effect: async () => {
    toastify('success', 'Статус изменен');
  },
});

startAppListening({
  actionCreator: changeQuestionnairesStatusAction.rejected,
  effect: async () => {
    toastify('error', 'Статус не был изменен');
  },
});
