import { Card } from 'antd';
import styles from './Empty.module.scss';
interface Props {
  label: string;
  text: string;
}

export const Empty = ({ label, text }: Props) => {
  return (
    <div className={styles.content}>
      <span className={styles.label}>{label}</span>
      <p className={styles.text}>{text}</p>
    </div>
  );
};
