import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from 'antd';
import AutosizeInput from 'react-input-autosize';
import { useParams } from 'react-router-dom';

import { titleValidation } from './validation';
import { ReactComponent as Edit } from 'shared/assets/icons/edit.svg';
import { useAppDispatch } from 'store';
import updateQuestionnairesAction from 'store/QuestionnairesSlice/actions/updateQuestionnaires';

import styles from './Title.module.scss';

interface Props {
  title: string;
  isEdit?: boolean;
}

export const Title = ({ title, isEdit = false }: Props) => {
  const inputRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
    },
    values: {
      name: title,
    },
    resolver: yupResolver(titleValidation),
  });

  const onSubmit = async (data: any, e: any) => {
    if (data.name !== title) {
      const da = {
        ...data,
        name: data.name.trim(),
      };

      await dispatch(updateQuestionnairesAction({ ...da, id }));
      inputRef.current?.blur();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
      {isEdit ? (
        <>
          <div className={styles.content}>
            <Controller
              render={({ field: { value, onChange }, fieldState, formState }) => (
                <AutosizeInput
                  className={styles.input}
                  title="Пожалуйста, убедитесь, что между словами не более двух пробелов."
                  inputStyle={{
                    border: 'none',
                    height: 40,
                    fontSize: 30,
                    color: '#1B1B26',
                    lineHeight: 40,
                    background: 'inherit',
                    minWidth: 50,
                    maxWidth: 1188,
                    display: '-webkit-box',
                    lineClamp: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  pattern="^((?!\s{3}).)*$"
                  ref={inputRef}
                  value={value}
                  onChange={onChange}
                  onBlur={handleSubmit(onSubmit)}
                />
              )}
              control={control}
              name="name"
            />

            <Edit className={styles.icon} onClick={() => inputRef.current?.focus()} />
          </div>

          {errors.name && <Alert className={styles.alert} message={errors.name.message} type="error" showIcon />}
        </>
      ) : (
        <h1 className={styles.title}>{title}</h1>
      )}
    </form>
  );
};
