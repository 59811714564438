import { Key, ReactNode, useEffect, useMemo, useState } from 'react';
import { Card, Input, Layout, Menu, MenuProps, Skeleton, Spin } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';

import { useAppDispatch, useAppSelector } from 'store';
import directoriesAction from 'store/DirectoriesSlice/actions/directoriesAction';
import { directoriesSelector } from 'store/DirectoriesSlice/selectors';

import { Title } from 'components/Title';
import { ReactComponent as SearchSVG } from 'shared/assets/icons/search.svg';

import styles from './DirectoriesPage.module.scss';
import { Empty } from './Empty';
import { Directory } from './Directory';
import { Simulate } from 'react-dom/test-utils';
import load = Simulate.load;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: ReactNode, key: Key, icon?: ReactNode, children?: MenuItem[], type?: 'divider'): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const DirectoriesPage = () => {
  const dispatch = useAppDispatch();
  const { globalDirectories, loading } = useAppSelector(directoriesSelector);
  const [directoryID, setDirectoryID] = useState<string | null>(null);

  useEffect(() => {
    dispatch(directoriesAction());
  }, []);

  const globalItems = useMemo(() => {
    return globalDirectories.map((item) => getItem(item.name, item.id, null));
  }, [globalDirectories]);

  const handleSelectDirectory = (id: string) => {
    setDirectoryID(id);
  };

  return (
    <>
      <Title title="Справочники" />
      <Layout className={styles.layout} hasSider>
        <Sider className={styles.sider} width="30%">
          <Card bodyStyle={{ padding: 0, height: '100%' }}>
            <div className={styles.cardContent}>
              {loading && globalDirectories?.length === 0 && (
                <div className={styles.cardInput}>
                  <Skeleton />
                  {/*<Input*/}
                  {/*  readOnly={loading}*/}
                  {/*  className={styles.inputSearch}*/}
                  {/*  placeholder="Поиск справочника"*/}
                  {/*  suffix={<SearchSVG />}*/}
                  {/*/>*/}
                </div>
              )}

              <div className={styles.cardMenuWrapper}>
                <Menu
                  onSelect={(el) => handleSelectDirectory(el.key)}
                  disabled={loading}
                  mode="inline"
                  items={globalItems}
                  style={{ height: '100%', borderInlineEnd: 'none' }}
                />
              </div>
            </div>
          </Card>
        </Sider>
        <Content>
          {directoryID ? (
            <Directory id={directoryID} />
          ) : (
            <Card className={styles.empty}>
              <Empty label="Пока что тут пусто" text="Выберите спарвочник из списка, или создайте новый" />
            </Card>
          )}
        </Content>
      </Layout>
    </>
  );
};

export default DirectoriesPage;
