import instance from 'connection';
import axios, { AxiosError, AxiosResponse } from 'axios';

export const getResults = async (id: string, thunkAPI?: any) => {
  try {
    return await instance.get<never, AxiosResponse<any>>(`/analytics/formsAnswer/listByForm`, {
      params: {
        formId: id,
      },
    });
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

export const getAnswersById = async (id: string, thunkAPI?: any) => {
  try {
    return await instance.get<never, AxiosResponse<any>>(`/analytics/formsAnswer/${id}`);
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

export const getStatistics = async (id: string, thunkAPI?: any) => {
  try {
    return await instance.get<never, AxiosResponse<any>>(`/analytics/byForm/byBlock`, {
      params: {
        formId: id,
      },
    });
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};
