import { ColumnsType } from 'antd/es/table';
import { Badge } from 'antd';
import { Link } from 'react-router-dom';

import { QuestionnairesResponseData } from 'api/questionnairesApi/types';
import { ROUTES } from '../../../app/providers/router/routes';

export const tableColumns: ColumnsType<QuestionnairesResponseData> = [
  {
    title: 'Название',
    dataIndex: 'name',
    render: (text, record) => {
      return <Link to={`${ROUTES.questionnaires}/${record.id}/questions`}>{text}</Link>;
    },
    width: '20%',
  },
  {
    title: 'Идентификатор',
    dataIndex: 'id',
    render: (text, record) => text.slice(0, 8),
    width: '20%',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    sorter: true,
    render: (text, record) => {
      let activeText = null;
      let status = null;

      switch (record.status) {
        case 'ACTIVE':
          activeText = 'Активно';
          status = 'success';
          break;
        default:
        case 'DRAFT':
          activeText = 'Отключено';
          status = 'error';
          break;
        case 'FINISHED':
          activeText = 'Завершено';
          status = 'warning';
          break;
      }

      // @ts-ignore
      return <Badge status={status} text={activeText} />;
    },
    width: '20%',
  },
  {
    title: 'Действия',
    dataIndex: 'actions',
    width: '1%',
  },
];
