import { Link, Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { ReactComponent as AppLogoSVG } from 'shared/assets/icons/appLogo.svg';
import AppLogoPNG from 'shared/assets/icons/appLogo.png';
import { Typography } from 'antd';

const { Title } = Typography;
const { Header: HeaderAntd } = Layout;
import styles from './FillForm.module.scss';
import { useAppSelector } from '../../../store';
import { fillFormQuestionsSelector } from '../../../store/FillFormSlice/selectors';
import { ROUTES } from '../../../app/providers/router/routes';

export const FillFormLayout = () => {
  const { questions } = useAppSelector(fillFormQuestionsSelector);

  return (
    <div className={styles.content}>
      <HeaderAntd className={styles.header}>
        <Title level={2}>{questions?.form?.name}</Title>
        <Link to={ROUTES.questionnaires}>
          <div className={styles.logo}>
            <img src={AppLogoPNG} alt="App Logo" className={styles.icon} />
          </div>
        </Link>
      </HeaderAntd>
      <Outlet context={{ fields: questions?.form?.blocks }} />
    </div>
  );
};
