import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Badge, Checkbox, Col, Input, List, Modal, Row, Select, Skeleton, Space, Tooltip } from 'antd';
import clsx from 'clsx';
// Context
import { ContextFormUtilities } from '../index';
// Types
import { QuestionnairesType } from 'api/questionnairesApi/types';
// Utils
import { showMarginForSubContent } from './utilts/showMarginForSubContent';
import { chooseSubContent } from './utilts/chooseSubContent';
import { getPlaceholder } from './utilts/getPlaceholder';
// Options
import { options } from '../headers';
// SVG
import { ReactComponent as LoginSVG } from 'shared/assets/icons/login.svg';
// Styles
import styles from '../EditCardContainer.module.scss';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../../store';
import { directoriesSelector } from '../../../store/DirectoriesSlice/selectors';
import directoriesAction from '../../../store/DirectoriesSlice/actions/directoriesAction';
import Button from '../../Button';
import getDirectoryByIdAction from '../../../store/DirectoriesSlice/actions/getDirectoryByIdAction';

interface Props {
  selectedSubContent: QuestionnairesType;
  setSelectedSubContent: (type: QuestionnairesType) => void;
}

export const Content = ({ selectedSubContent, setSelectedSubContent, setContent, setSelectedDict, ...props }: Props & any) => {
  const dispatch = useAppDispatch();
  const { globalDirectories, selectedDirectory, loading } = useAppSelector(directoriesSelector);

  const [fillFromDictModal, setFillFromDictModal] = useState<boolean>(false);

  const [dictValue, setDictValue] = useState<null | any>(null);

  const context = useContext(ContextFormUtilities);
  const control = context.control;
  const errors = context.errors;
  const setValue = context.setValue;
  const getValues = context.getValues;
  const dictId = getValues('values_dictionary_id');

  const [checkboxGroupValue, setCheckboxGroupValue] = useState<string>(dictId ?? '');

  useEffect(() => {
    if (dictId) {
      setCheckboxGroupValue(dictId);
      if (!selectedDirectory?.[dictId]) {
        dispatch(getDirectoryByIdAction(dictId)).then((data) => {
          setDictValue(data.payload.data);
        });
      } else {
        setDictValue(selectedDirectory?.[dictId]);
      }
    }
  }, [dictId, selectedDirectory, dispatch]);

  const mappedDicts = useMemo(
    () => globalDirectories?.map((el) => ({ title: el.name, key: el.id })),
    [globalDirectories],
  );

  const renderErrors = (): string | null => {
    if (errors?.content) {
      return errors.content.message;
    }
    if (errors?.[selectedSubContent]) {
      return (Object.values(errors[selectedSubContent]) as any[]).at(0)?.message || null;
    }
    return null;
  };

  const onFillFromDictionaryClick = () => {
    setFillFromDictModal(true);
    dispatch(directoriesAction());
  };

  const onFillDicts = async () => {
    if (checkboxGroupValue) {
      setSelectedDict(checkboxGroupValue);
      if (!selectedDirectory?.[checkboxGroupValue]) {
        dispatch(getDirectoryByIdAction(checkboxGroupValue)).then((data) => {
          setDictValue(data.payload.data);
          setFillFromDictModal(false);
          setValue('values_dictionary_id', checkboxGroupValue);
          setValue('values', undefined);
        });
      } else {
        setFillFromDictModal(false);
        setValue('values_dictionary_id', checkboxGroupValue);
        setValue('values', undefined);
      }
    } else {
      setValue('values_dictionary_id', undefined);
      setCheckboxGroupValue('');
      setDictValue(null);
      setFillFromDictModal(false);
    }
  };

  return (
    <>
      <Modal
        closeIcon={<CloseCircleOutlined className={styles.closeIcon} />}
        footer={
          <div>
            <Button onClick={onFillDicts} type="primary">
              Сохранить
            </Button>
          </div>
        }
        open={fillFromDictModal}
        onCancel={() => setFillFromDictModal(false)}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <List
            style={{ marginTop: 20 }}
            dataSource={mappedDicts}
            renderItem={(item) => (
              <List.Item>
                <div style={{ display: 'flex', gap: 10 }}>
                  <div>
                    <Checkbox
                      checked={item.key === checkboxGroupValue}
                      onChange={() => {
                        if (item.key === checkboxGroupValue) {
                          setCheckboxGroupValue('');
                        } else {
                          setCheckboxGroupValue(item.key);
                        }
                      }}
                    />
                  </div>
                  <span>{item.title}</span>
                </div>
              </List.Item>
            )}
          />
        )}
      </Modal>
      <Row gutter={[16, 0]} wrap={false}>
        <Col flex="auto" className={styles.selectWrapper}>
          <Col flex="auto">
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select
                  showSearch
                  listHeight={500}
                  className={styles.select}
                  options={options}
                  {...field}
                  onChange={(type) => setSelectedSubContent(type)}
                />
              )}
            />
          </Col>
          {(selectedSubContent === 'DROPDOWN' || selectedSubContent === 'MULTI_SELECT') && (
            <Col className={styles.colResetPadding}>
              <Space
                onClick={onFillFromDictionaryClick}
                style={{ marginTop: 5, display: 'flex', justifyContent: 'space-between' }}
              >
                <Tooltip title="Заполнить из справочника" color="#5586FC">
                  <Badge dot={Boolean(dictId)} offset={[-3, 2]}>
                    <LoginSVG className={styles.dictionary} />
                  </Badge>
                </Tooltip>
              </Space>
            </Col>
          )}
        </Col>
        <Col flex="60%">
          <Controller
            control={control}
            render={({ field }) => (
              <Input.TextArea
                className={styles.input}
                autoSize={{
                  minRows: 1,
                  maxRows: 4,
                }}
                status={errors?.content ? 'error' : ''}
                placeholder={getPlaceholder(selectedSubContent)}
                {...field}
                onChange={(e) => setContent(e.target.value)}
              />
            )}
            name="content"
          />
        </Col>
      </Row>
      <div>{errors && !!Object.keys(errors).length && <span className={styles.errorText}>{renderErrors()}</span>}</div>
      <Row
        className={clsx({
          [styles.subContentWrapper]: showMarginForSubContent(selectedSubContent),
        })}
      >
        {chooseSubContent(selectedSubContent, props, dictValue)}
      </Row>
    </>
  );
};
