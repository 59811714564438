import { QuestionnairesType } from 'api/questionnairesApi/types';

import styles from './EditCardContainer.module.scss';

export type ActiveTabs = 'content' | 'settings' | 'preview';

export const editCardOptions = (type: QuestionnairesType) => {
  if (type === 'INFO' || type === 'BINARY' || type === 'GRADE_SCALE') {
    return [
      {
        key: 'content',
        label: 'Содержание',
      },
      // {
      //   key: 'preview',
      //   label: 'Предварительный просмотр',
      // },
    ];
  }

  return [
    {
      key: 'content',
      label: 'Содержание',
    },
    {
      key: 'settings',
      label: 'Настройки',
    },
    // {
    //   key: 'preview',
    //   label: 'Предварительный просмотр',
    // },
  ];
};

export const options = [
  {
    value: 'INFO',
    label: (
      <div className={styles.wrapperItem}>
        <span className={styles.text}>Инфоблок</span>
      </div>
    ),
  },
  {
    value: 'BINARY',
    label: (
      <div className={styles.wrapperItem}>
        <span className={styles.text}>Бинарный выбор</span>
      </div>
    ),
  },
  {
    value: 'NUMBER',
    label: (
      <div className={styles.wrapperItem}>
        <span className={styles.text}>Число</span>
      </div>
    ),
  },
  {
    value: 'LONG_TEXT',
    label: (
      <div className={styles.wrapperItem}>
        <span className={styles.text}>Длинный текст</span>
      </div>
    ),
  },
  {
    value: 'SHORT_TEXT',
    label: (
      <div className={styles.wrapperItem}>
        <span className={styles.text}>Короткий текст</span>
      </div>
    ),
  },
  {
    value: 'DATE',
    label: (
      <div className={styles.wrapperItem}>
        <span className={styles.text}>Дата</span>
      </div>
    ),
  },
  {
    value: 'DROPDOWN',
    label: (
      <div className={styles.wrapperItem}>
        <span className={styles.text}>Выпадающий список</span>
      </div>
    ),
  },
  {
    value: 'RATING',
    label: (
      <div className={styles.wrapperItem}>
        <span className={styles.text}>Рейтинг</span>
      </div>
    ),
  },
  {
    value: 'GRADE_SCALE',
    label: (
      <div className={styles.wrapperItem}>
        <span className={styles.text}>Оценка по шкале</span>
      </div>
    ),
  },
  {
    value: 'MULTI_SELECT',
    label: (
      <div className={styles.wrapperItem}>
        <span className={styles.text}>Несколько ответов</span>
      </div>
    ),
  },
];
