import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { Col, Input, Space } from 'antd';

import { ContextFormUtilities } from '../../../EditCardContainer';

import styles from './InformationCard.module.scss';

const InformationSubCard = () => {
  const context = useContext(ContextFormUtilities);
  const control = context.control;

  return (
    <>
      <Col className={styles.text}>Дополнительный текст:</Col>
      <Col flex="auto">
        <Space className={styles.space} direction="vertical">
          <Controller
            control={control}
            render={({ field }) => (
              <Input.TextArea
                className={styles.input}
                autoSize={{
                  minRows: 2,
                  maxRows: 4,
                }}
                placeholder="Введите текст"
                {...field}
              />
            )}
            name="INFO.sub_content"
          />
          <span className={styles.info}>*поле можно оставить не заполненным</span>
        </Space>
      </Col>
    </>
  );
};

export default InformationSubCard;
