import { createSlice } from '@reduxjs/toolkit';

import authAdapter, { initialState } from './adapter';
import registerAction from './actions/register';
import loginAction from './actions/login';
import logoutAction from './actions/logout';

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerAction.pending, authAdapter.registerPending)
      .addCase(registerAction.fulfilled, authAdapter.registerFullfilled)
      .addCase(registerAction.rejected, authAdapter.registerRejected)
      .addCase(loginAction.pending, authAdapter.loginPending)
      .addCase(loginAction.fulfilled, authAdapter.loginFulfilled)
      .addCase(loginAction.rejected, authAdapter.loginRejected)
      .addCase(logoutAction, authAdapter.logoutFulfilled);
  },
});

export default authSlice.reducer;
