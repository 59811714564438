import { Radio, Space } from 'antd';

import styles from './BinaryChoiceCard.module.scss';
import { Controller } from 'react-hook-form';

interface Props {
  true_label: string;
  false_label: string;
  control?: any;
  id?: string;
  readOnly?: boolean;
}

const BinaryChoiceCard = ({ id = '', true_label, false_label, control, readOnly }: Props) => {
  return (
    <Controller
      control={control}
      defaultValue={1}
      disabled={readOnly}
      render={({ field }) => (
        <Radio.Group defaultValue={1} {...field}>
          <Space direction="vertical" size={8}>
            <Radio className={styles.radio} value={1}>
              {true_label || 'Да'}
            </Radio>
            <Radio className={styles.radio} value={2}>
              {false_label || 'Нет'}
            </Radio>
          </Space>
        </Radio.Group>
      )}
      name={id}
    />
  );
};

export default BinaryChoiceCard;
