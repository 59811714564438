const COLOR_PRIMARY = '#5586FC';
const COLOR_TEXT = '#7D7F8D';
const COLOR_PRIMARY_BG = '#F1F5FE';
// Table
const TABLE_HEADER_BG = '#F8FAFF';

export default {
  components: {
    Menu: {
      colorPrimary: COLOR_PRIMARY,
      colorText: COLOR_TEXT,
      itemColor: '#1B1B26',
      itemHoverBg: '#EFF4FE',
      itemSelectedColor: '#5586FC',
      itemActiveBg: '#EFF4FE',
      subMenuItemBg: '#fff',
    },
    Button: {
      borderRadius: 100,
      borderRadiusLG: 100,
      borderRadiusSM: 100,
    },
    Card: {
      borderRadius: 16,
      borderRadiusLG: 16,
      borderRadiusSM: 16,
    },
    Input: {
      colorBgContainer: `${COLOR_PRIMARY_BG} !important`,
      borderRadius: 16,
    },
    Tabs: {
      itemColor: COLOR_TEXT,
      itemSelectedColor: COLOR_PRIMARY,
      itemHoverColor: COLOR_PRIMARY,
      inkBarColor: COLOR_PRIMARY,
      colorBorderSecondary: 'none',
      horizontalItemPaddingSM: '16px 0',
    },
    Table: {
      headerBg: TABLE_HEADER_BG,
      headerBorderRadius: 16,
    },
    Select: {
      colorBorder: 'none',
      colorBgContainer: '#F1F5FE',
      optionSelectedColor: COLOR_PRIMARY,
    },
    InputNumber: {
      colorBgContainer: COLOR_PRIMARY_BG,
      colorBorder: COLOR_PRIMARY_BG,
    },
    DatePicker: {
      colorBgContainer: COLOR_PRIMARY_BG,
      colorBorder: COLOR_PRIMARY_BG,
    },
  },
};
