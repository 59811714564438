import { useAppDispatch, useAppSelector } from '../../../../store';
import { useEffect } from 'react';
import getStatisticsAction from '../../../../store/ResultSlice/actions/getStatisticsAction';
import { useParams } from 'react-router-dom';
import { resultSelector } from '../../../../store/ResultSlice/selectors';
import { Typography } from 'antd';
import styles from './Statistics.module.scss';
import Table from 'antd/es/table';
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const { Text } = Typography;

const COLUMNS = [
  {
    title: 'Ответ',
    dataIndex: 'answer',
  },
  {
    title: 'Абс. количество',
    dataIndex: 'abs',
  },
  {
    title: 'Процент',
    dataIndex: 'percent',
  },
  {
    title: 'Всего',
    dataIndex: 'total',
  },
];

const xKey = 'value';
const yKey = 'count_answers';

const blues = [
  ['#457AA6'],
  ['#457AA6', '#E3EBF2'],
  ['#264F73', '#457AA6', '#E3EBF2'],
  ['#264F73', '#457AA6', '#A2BBD2', '#E3EBF2'],
  ['#1A334A', '#264F73', '#457AA6', '#A2BBD2', '#E3EBF2'],
];

const getColor = (length: number, index: number) => {
  if (length <= blues.length) {
    return blues[length - 1][index];
  }

  return blues[blues.length - 1][index % blues.length];
};

const Statistics = () => {
  const dispatch = useAppDispatch();
  const { statistics } = useAppSelector(resultSelector);
  const { id } = useParams();

  const getMappedStatistics = (rows: any[]) =>
    rows?.map((item: any) => ({
      answer: item.value,
      abs: item.count_answers,
      total: rows.length,
      percent: `${Math.round((item.count_answers / rows.length) * 100)}%`,
    }));

  useEffect(() => {
    if (id) {
      dispatch(getStatisticsAction(id));
    }
  }, [id]);

  if (!statistics) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {statistics.length === 0 ? (
        <Text>Подробная статистика по вопросам отсутствует</Text>
      ) : (
        statistics
          .filter((item: any) => item?.form_block?.type !== 'INFO')
          .map((item: any, index: number) => {
            const type = item?.form_block?.type;
            switch (type) {
              case 'BINARY':
              case 'DROPDOWN':
              case 'MULTI_SELECT':
              case 'GRADE_SCALE': {
                return (
                  <div key={item?.form_block?.id} className={styles.itemWrapper}>
                    <Text>
                      <span className={styles.titleLeft}>Вопрос №{index + 1}</span>
                      <span className={styles.titleDefault}>{item?.form_block?.content}</span>
                    </Text>
                    <ResponsiveContainer width={'65%'} height={25 * item.rows.length} debounce={50}>
                      <BarChart data={item.rows} layout="vertical" margin={{ left: 8 }}>
                        <XAxis hide axisLine={false} type="number" />
                        <YAxis yAxisId={0} dataKey={xKey} type="category" axisLine={false} tickLine={false} />
                        <YAxis
                          orientation="right"
                          yAxisId={1}
                          dataKey={yKey}
                          type="category"
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={(value) => value.toLocaleString()}
                          mirror
                        />
                        <Bar dataKey={yKey} minPointSize={2} barSize={17}>
                          {item.rows.map((d: any, idx: number) => {
                            return (
                              <Cell radius={20} width={200} key={d[xKey]} fill={getColor(item.rows.length, idx)} />
                            );
                          })}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                    <Table pagination={false} columns={COLUMNS} dataSource={getMappedStatistics(item.rows)} />
                  </div>
                );
              }
              default: {
                return (
                  <div key={item?.form_block?.id} className={styles.itemWrapper}>
                    <Text>
                      <span className={styles.titleLeft}>Вопрос №{index + 1}</span>
                      <span className={styles.titleDefault}>{item?.form_block?.content}</span>
                    </Text>
                    <Text>Для данного типа вопросов отсутствует статистика</Text>
                  </div>
                );
              }
            }
          })
      )}
    </div>
  );
};

export default Statistics;
