import { SortType } from 'types/sortType';
import { sortArrayByType } from './sortArrayByType';

export type TransformableToOptions = {
  value: string;
  id?: string;
  children?: Omit<TransformableToOptions, 'children'>[];
};

export const transformToOptions = <T extends TransformableToOptions>(data: T[], sort?: SortType) => {
  const res = data.map((el) => {
    const baseOption = {
      id: el?.id,
      value: el.id,
      label: el.value,
    };
    if (el?.children && el.children.length > 0) {
      const childrenOptions =
        el?.children?.map((child) => ({
          value: child.id,
          label: child.value,
        })) ?? [];
      return {
        ...baseOption,
        options: sortArrayByType(childrenOptions, 'value', sort),
      };
    }
    return baseOption;
  });

  const sorted = sortArrayByType(res, 'value', sort);

  return sorted;
};
