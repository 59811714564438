import { Controller } from 'react-hook-form';
import { Divider, InputNumber, Select, Space } from 'antd';

import { QuestionnairesBlocksSettingsShortText } from 'api/questionnairesApi/types/shortText.types';
import OPTIONS from './options';

import styles from './ShortTextSettings.module.scss';

interface Props extends QuestionnairesBlocksSettingsShortText {
  control: any;
  errors: any;
}

const ShortTextSettings = ({ max_length, min_length, control, validation_type, errors }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Space size={8}>
        <span>Валидация:</span>
        <Controller
          control={control}
          render={({ field }) => <Select className={styles.select} {...field} options={OPTIONS} />}
          name="SHORT_TEXT.settings.validation_type"
        />
      </Space>

      <Divider />

      <Space direction="vertical" size={16}>
        <Space size={8}>
          <span>Минимальное кол-во символов:</span>

          <Controller
            control={control}
            render={({ field }) => (
              <InputNumber
                {...field}
                status={!!errors?.SHORT_TEXT?.settings?.min_length ? 'error' : ''}
                min={0}
                max={100000}
              />
            )}
            name="SHORT_TEXT.settings.min_length"
          />
        </Space>
        <Space size={8}>
          <span>Максимальное кол-во символов:</span>
          <Controller
            control={control}
            render={({ field }) => (
              <InputNumber
                status={!!errors?.SHORT_TEXT?.settings?.max_length ? 'error' : ''}
                {...field}
                min={0}
                max={100000}
              />
            )}
            name="SHORT_TEXT.settings.max_length"
          ></Controller>
        </Space>
        {!!errors?.SHORT_TEXT?.settings?.min_length?.message && (
          <div>{errors?.SHORT_TEXT?.settings?.min_length?.message}</div>
        )}
        {!!errors?.SHORT_TEXT?.settings?.max_length?.message && (
          <div>{errors?.SHORT_TEXT?.settings?.max_length?.message}</div>
        )}
      </Space>
    </div>
  );
};

export default ShortTextSettings;
