import { startAppListening } from '../../listenerMiddleware';
import { toastify } from 'utils/toastify';

import registerAction from '../actions/register';
import loginAction from '../actions/login';
import { changePasswordAction, verifyCodeAction } from '../actions/passwordRecovery';

startAppListening({
  actionCreator: registerAction.rejected,
  effect: async (action, listenerApi) => {
    //TODO  console.log(action.payload.data.ok);
    // if (action.payload?.data?.code === 'email.already.used') return;
    toastify('error', 'Произошла непредвиденная ошибка.');
  },
});

startAppListening({
  actionCreator: registerAction.fulfilled,
  effect: async (action, listenerApi) => {
    toastify('success', 'Для подтверждения аккаунта перейдите на свою почту.');
  },
});

startAppListening({
  actionCreator: loginAction.rejected,
  effect: async (action, listenerApi) => {
    const result = action.payload as { data: { code: string } };

    if (result?.data?.code === 'user.not.found') {
      toastify('error', 'Такого пользователя не существует.');
    }
  },
});

startAppListening({
  actionCreator: verifyCodeAction.rejected,
  effect: async () => {
    toastify('error', 'Неверный код');
  },
});

startAppListening({
  actionCreator: changePasswordAction.fulfilled,
  effect: async (action, listenerApi) => {
    toastify('success', 'Пароль успешно изменен');
  },
});
