import * as yup from 'yup';

export const passwordRecoveryValidation = yup.object().shape({
  email: yup.string().email('Неверный формат e-mail').required('E-mail обязателен'),
});

export const codeValidation = yup.object().shape({
  code: yup.string().length(4, 'Код должен содержать 4 цифры').required('Код обязателен'),
});

export const newPasswordValidation = yup.object().shape({
  password: yup.string().min(8, 'Пароль должен содержать минимум 8 символов').required('Пароль обязателен'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Пароли должны совпадать')
    .required('Подтверждение пароля обязательно'),
});
