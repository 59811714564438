import React, { ReactNode, useEffect, useState } from 'react';
import { Card } from 'antd';
import { FieldArray, FieldArrayMethodProps } from 'react-hook-form';

import { QuestionnairesBlocks } from 'api/questionnairesApi/types';

import EditCardContainer from 'components/EditCardContainer';
import QuestionCardTitle from 'components/QuestionCardTitle';
import ExtraContent from 'components/ExtraContent';
import AddMore from 'components/AddMore/AddMore';

interface Props extends QuestionnairesBlocks {
  children: ReactNode;
  isEdit?: boolean;
  isCreate?: boolean;
  isInsert?: boolean;
  idx: number;
  currentArray: any[];
  remove: (index?: number | number[]) => void;
  insert: (index: number, value: Partial<FieldArray> | Partial<FieldArray>[], options?: FieldArrayMethodProps) => void;
  swap: (indexA: number, indexB: number) => void;
  isEditable?: boolean;
}

function CardContainer({ children, ...props }: Props) {
  const { swap, insert, remove, isInsert, isEdit: edit, idx, currentArray, isCreate, isEditable, ...rest } = props;
  const [isEdit, setIsEdit] = useState(edit);
  const isInfo = props.type === 'INFO';

  const prevAndNextIDS = {
    prev_block_id: currentArray[idx - 1]?.id || null,
    next_block_id: currentArray[idx + 1]?.id || null,
  };

  useEffect(() => {
    if (edit) {
      setIsEdit(edit);
    }
  }, [edit]);

  return (
    <>
      {isEdit ? (
        <>
          <EditCardContainer
            {...{ ...rest, isEdit, isCreate, idx, insert, prevAndNextIDS }}
            onClose={() => {
              isInsert && remove(idx);
              setIsEdit(false);
            }}
            onSave={() => {
              setIsEdit(false);
            }}
          />
          {isEditable && !edit && (
            <AddMore
              swap={swap}
              content={props.content}
              idx={idx}
              type={props.type}
              insert={insert}
              sub_content={props.sub_content}
            />
          )}
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <Card
            title={
              <QuestionCardTitle
                isRequired={props?.settings?.required}
                numberQuestion={props?.order_num}
                isInfo={isInfo}
                title={props.content}
              />
            }
            headStyle={{ borderBottom: 'none' }}
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            extra={!!isEditable ? <ExtraContent onClick={() => setIsEdit(true)} /> : null}
            bordered={false}
          >
            {children}
          </Card>
          {isEditable && (
            <AddMore
              swap={swap}
              content={props.content}
              idx={props.idx}
              type={props.type}
              insert={insert}
              sub_content={props.sub_content}
            />
          )}
        </div>
      )}
    </>
  );
}

export default CardContainer;
