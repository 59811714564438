import { createSlice } from '@reduxjs/toolkit';
import getFormsAction from './actions/getFormsAction';
import dictionariesAdapter, { initialState } from './adapter';
import submitFormAction from './actions/submitFormAction';
import setIsSubmittedAction from './actions/setIsSubmitted';

const fillFormSlice = createSlice({
  name: 'fillForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFormsAction.pending, dictionariesAdapter.getFillFormQuestionsPending);
    builder.addCase(getFormsAction.fulfilled, dictionariesAdapter.getFillFormQuestionsFullfilled);
    builder.addCase(getFormsAction.rejected, dictionariesAdapter.getFillFormQuestionsRejected);

    builder.addCase(submitFormAction.pending, dictionariesAdapter.submitFormPending);
    builder.addCase(submitFormAction.fulfilled, dictionariesAdapter.submitFormFullfilled);
    builder.addCase(submitFormAction.rejected, dictionariesAdapter.submitFormRejected);

    builder.addCase(setIsSubmittedAction, dictionariesAdapter.setIsSubmittedFullfilled);
  },
});

export default fillFormSlice.reducer;
