import styles from './DictItem.module.scss';

import { ReactComponent as DocIcon } from 'shared/assets/icons/document.svg';
import { ReactComponent as ArrowIcon } from 'shared/assets/icons/arrow_right.svg';

const DictItem = ({
  title,
  onClick,
  id,
}: {
  title: string;
  onClick: (id: number | string) => void;
  id: number | string;
}) => {
  return (
    <li className={styles.container}>
      <button onClick={() => onClick(id)}>
        <span className={styles.inner}>
          <DocIcon className={styles.doc} />
          <span className={styles.text}>{title}</span>
          <ArrowIcon className={styles.arrow} />
        </span>
      </button>
    </li>
  );
};

export default DictItem;
