import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Anchor, Button, Layout } from 'antd';

import { ReactComponent as LoginSVG } from 'shared/assets/icons/login.svg';
import AppLogoPNG from 'shared/assets/icons/appLogo.png';

import styles from './Header.module.scss';
import { authSelector } from 'store/authSlice/selectors';
import { useAppSelector } from 'store';
import { UserOutlined } from '@ant-design/icons';
import { ROUTES } from '../../../../app/providers/router/routes';

const { Header: HeaderAntd } = Layout;

const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAppSelector(authSelector);
  const [scrolled, setScrolled] = useState(false);

  const handleLK = async () => {
    navigate(ROUTES.questionnaires);
  };

  useEffect(() => {
    const scrollHandler = () => setScrolled(window.scrollY > 10);

    window.addEventListener('scroll', scrollHandler, { passive: true });

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrolled]);

  return (
    <HeaderAntd className={clsx(styles.header, scrolled && styles.scrolled)}>
      <div className={styles.logo}>
        <img src={AppLogoPNG} alt="App Logo" className={styles.icon}/>
      </div>

      <Anchor className={styles.menu} direction="horizontal" targetOffset={100}>
        <Anchor.Link className={styles.item} href="#about" title="О нас" />
        <Anchor.Link className={styles.item} href="#advantages" title="Преимущества" />
        <Anchor.Link className={styles.item} href="#directories" title="Справочники" />
      </Anchor>

      {isAuthenticated || user ? (
        <Button onClick={handleLK} className={styles.button} type="link">
          <UserOutlined />
          <span>Личный кабинет</span>
        </Button>
      ) : (
        <Button className={styles.button} type="link">
          <Link to="/auth/login">
            <LoginSVG />
            <span>Войти</span>
          </Link>
        </Button>
      )}
    </HeaderAntd>
  );
};

export default Header;
