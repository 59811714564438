import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DatePicker, InputNumber } from 'antd';
import dayJS from 'dayjs';
import clsx from 'clsx';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'dayjs/locale/ru';

import { QuestionnairesSettings } from 'api/questionnairesApi/types';

import updateQuestionnairesAction from 'store/QuestionnairesSlice/actions/updateQuestionnaires';
import { useAppDispatch } from 'store';
import { FormItem, SaveCancelButton } from '../components/helperComponents';
import { dateFormat } from 'components/questionnaires/MainCards/components/DateCard/DateSettings';

import styles from '../Access.module.scss';

type IForm = Omit<QuestionnairesSettings, 'one_per_link'>;
type Props = { name: string } & QuestionnairesSettings;

export const TestTimeForm = ({ max_form_answers, time_limit, finish_date, name, one_per_link }: Props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { handleSubmit, control, setValue } = useForm<IForm>({
    defaultValues: {
      time_limit: 60,
      max_form_answers: 10,
      finish_date: '',
    },
    values: {
      time_limit,
      max_form_answers,
      finish_date,
    },
  });

  const [date, setDate] = useState(!!finish_date ? dayJS(finish_date, dateFormat) : null);

  const onSubmit = async (data: IForm) => {
    if (!id) return;

    await dispatch(updateQuestionnairesAction({ id, name, settings: { ...data, one_per_link } }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/*TODO tmp off*/}
      {/*<FormItem label="Ограничить время заполнения">*/}
      {/*  <Controller*/}
      {/*    name="time_limit"*/}
      {/*    control={control}*/}
      {/*    defaultValue={60}*/}
      {/*    render={({ field }) => <InputNumber {...field} min={0} className={styles.input} />}*/}
      {/*  />*/}
      {/*  <span className={styles.text}>минут</span>*/}
      {/*</FormItem>*/}
      <FormItem label="Закрыть анкету по количеству ответов:">
        <Controller
          name="max_form_answers"
          control={control}
          defaultValue={0}
          render={({ field }) => <InputNumber {...field} min={0} className={clsx(styles.input, styles.inputColor)} />}
        />
      </FormItem>
      <FormItem label="Закрыть анкету к дате:">
        <Controller
          name="finish_date"
          control={control}
          render={({ field: { value, onChange, ...rest } }) => (
            <DatePicker
              {...rest}
              onChange={(e) => {
                e && onChange(e.format(dateFormat));
                e && setDate(dayJS(e.format(dateFormat)));
              }}
              value={date}
              format={dateFormat}
              className={clsx(styles.input, styles.inputColor)}
              locale={locale}
            />
          )}
        />
      </FormItem>
      <SaveCancelButton />
    </form>
  );
};
