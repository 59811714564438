import { Outlet } from 'react-router-dom';

import { Header } from './Header';
import styles from './RootLayout.module.scss';

export const RootLayout = () => {
  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <Header />
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};
