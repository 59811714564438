import React from 'react';
import { InputNumber, Space } from 'antd';

import styles from './NumberCard.module.scss';
import { Controller } from 'react-hook-form';

interface IProps {
  max?: number;
  min?: number;
  control?: any;
  id?: string;
  readOnly?: boolean;
}

const NumberCard = ({ control, readOnly, id = '', max = 10000, min = 0 }: IProps) => {
  return (
    <>
      <Space className={styles.spaceItem} direction="vertical" size="middle">
        <Space direction="horizontal" size={12}>
          <Controller
            control={control}
            defaultValue={min}
            disabled={readOnly}
            render={({ field }) => <InputNumber defaultValue={min} min={min} max={max} {...field} />}
            name={id}
          />
        </Space>
      </Space>
    </>
  );
};

export default NumberCard;
