import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendRecoveryCode, changePassword, verifyCode } from 'api/authApi';

const SEND_CODE = 'auth/recovery/sendCode';
const VERIFY_CODE = 'auth/recovery/verifyCode';
const CHANGE_PASSWORD = 'auth/recovery/changePassword';

export const sendRecoveryCodeAction = createAsyncThunk(SEND_CODE, sendRecoveryCode);

export const verifyCodeAction = createAsyncThunk(VERIFY_CODE, verifyCode);

export const changePasswordAction = createAsyncThunk(CHANGE_PASSWORD, changePassword);
