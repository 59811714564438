import { useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Layout, Row, Select } from 'antd';
import Sider from 'antd/es/layout/Sider';

import { useAppDispatch, useAppSelector } from 'store';
import changeQuestionnairesStatusAction from 'store/QuestionnairesSlice/actions/changeQuestionnairesStatus';
import { questionnaireSelector } from 'store/QuestionnairesSlice/selectors/questionnaireSelector';

import { Title } from 'components/Title';
import Button from 'components/Button';

import { ReactComponent as PlusSVG } from 'shared/assets/icons/plus.svg';
import { ContentListTitleKeys, dontShowButtonsForTab, options, QuestionnairesStatus, tabListTitle } from './constants';

import styles from './ItemQuestionnairesPage.module.scss';
import { createCardSettings } from './createCardSettings';
import { nanoid } from 'nanoid';
import { ROUTES } from '../../app/providers/router/routes';

const { Content } = Layout;

const ItemQuestionnairesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const questionnaire = useAppSelector(questionnaireSelector);
  const currentTab = location.pathname.split('/').at(-1) as ContentListTitleKeys;
  const [activeTabKey, setActiveTabKey] = useState<ContentListTitleKeys>(currentTab);
  const contentRef = useRef<HTMLElement>(null);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      cards: questionnaire.blocks as any,
    },
    values: {
      cards: questionnaire.blocks,
    },
  });

  const { fields, append, insert, swap, remove } = useFieldArray({
    keyName: 'customId',
    name: 'cards',
    control,
  });

  const handleChange = async (status: QuestionnairesStatus) => {
    if (!id) return;
    await dispatch(changeQuestionnairesStatusAction({ id, status }));
  };

  const onTabChange = (key: string) => {
    setActiveTabKey(key as ContentListTitleKeys);
  };

  const scrollToBottom = () => {
    contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  const onAddQuestion = () => {
    append({
      id: nanoid(),
      content: '',
      sub_content: '',
      type: 'INFO',
      isEdit: true,
      isInsert: true,
      isCreate: true,
      settings: createCardSettings('MULTI_SELECT'),
    });

    queueMicrotask(scrollToBottom);
  };

  return (
    <>
      <Title isEdit title={questionnaire.name} />
      <Layout className={styles.layout} hasSider>
        <Content ref={contentRef}>
          <Card
            className={styles.contentCard}
            tabList={tabListTitle}
            activeTabKey={activeTabKey}
            tabBarExtraContent={
              <>
                {dontShowButtonsForTab.includes(currentTab) ? null : (
                  <div className={styles.extraContent}>
                    <Button
                      onClick={onAddQuestion}
                      className={styles.addButton}
                      icon={<PlusSVG />}
                      type="primary"
                      value="large"
                    >
                      Добавить вопрос
                    </Button>
                    {/*<Button value="large">Создать шаблон</Button>*/}
                  </div>
                )}
              </>
            }
            onTabChange={onTabChange}
            tabProps={{
              defaultValue: currentTab,
              onTabClick: (tabName) => navigate(`${ROUTES.questionnaires}/${id}/${tabName}`),
              size: 'small',
            }}
          >
            <Outlet context={{ fields, insert, remove, swap }} />
          </Card>
        </Content>
        <Sider className={styles.slider} width={400}>
          <Card title="Описание" className={styles.card}>
            <Row>
              <Col className={styles.col} span={12}>
                Идентификатор
              </Col>
              <Col span={12}>{id?.slice(0, 8)}</Col>
            </Row>
            <Row className={styles.wrapperStatus}>
              <Col className={styles.col} span={12}>
                Статус
              </Col>
              <Col span={12}>
                <Select
                  showSearch
                  className={styles.selectContainer}
                  defaultValue={questionnaire.status || 'DRAFT'}
                  onChange={handleChange}
                  options={options}
                />
              </Col>
            </Row>
          </Card>
        </Sider>
      </Layout>
    </>
  );
};

export default ItemQuestionnairesPage;
