import { PayloadAction } from '@reduxjs/toolkit';
import readFromLocalStorage from 'utils/readFromLocalStorage';

const enum UserRole {
  MANAGER = 'manager',
}

type UserRoles = 'manager';

interface User {
  exp: number;
  roles: UserRoles;
}

const user = JSON.parse(readFromLocalStorage('token')!);

const DEFAULTS = {
  isAuthenticated: !!user,
  loading: false,
  serverError: null,
  user,
};

class Adapter {
  public getInitialState(props?: any): any {
    return { ...DEFAULTS, ...props };
  }
  public registerPending(state: any) {
    return { ...state, loading: true, serverError: null };
  }

  public registerFullfilled(state: any) {
    return { ...state, loading: false };
  }
  public registerRejected(state: any, action: any) {
    return {
      ...state,
      loading: false,
      serverError: action.payload?.data?.code,
    };
  }
  public loginPending(state: any) {
    return { ...state, loading: true, isAuthenticated: false, user: null };
  }

  public loginFulfilled(state: any, action: PayloadAction<any>) {
    return { ...state, loading: false, isAuthenticated: true, user: { ...action.payload } };
  }

  public loginRejected(state: any) {
    return { ...state, loading: false, isAuthenticated: false, user: null };
  }

  public logoutFulfilled(state: any) {
    return { ...state, loading: false, isAuthenticated: false, user: null };
  }
}

const adapter = new Adapter();

const initialState = adapter.getInitialState();

export { initialState };

export default adapter;
