import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../../../store';
import { authSelector } from '../../../store/authSlice/selectors';
import React from 'react';
import { ROUTES } from '../../../app/providers/router/routes';

export const AuthLayout = () => {
  const { isAuthenticated, user } = useAppSelector(authSelector);

  return isAuthenticated || user ? <Navigate to={ROUTES.questionnaires} replace /> : <Outlet />;
};
