import { Controller } from 'react-hook-form';
import { Select, Space } from 'antd';
import { QuestionnairesBlocksSettingsRating } from 'api/questionnairesApi/types/rating.types';

import styles from './RatingSettings.module.scss';
import { QUESTION_SORT_OPTIONS } from 'shared/constants/questionSortOptions';

interface Props extends QuestionnairesBlocksSettingsRating {
  control: any;
  errors: any;
}

const RatingSettings = ({ errors, control }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Space size={8}>
        <span>Сортировка:</span>
        <Controller
          control={control}
          render={({ field }) => (
            <Select showSearch className={styles.select} {...field} options={QUESTION_SORT_OPTIONS} />
          )}
          name="RATING.settings.sort_type"
        />
      </Space>
    </div>
  );
};

export default RatingSettings;
