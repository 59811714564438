import { createAction } from '@reduxjs/toolkit';

const setIsSubmittedAction = createAction('fillForm/isSubmitted', (isSubmitted: boolean) => {
  return {
    payload: {
      isSubmitted,
    },
  };
});

export default setIsSubmittedAction;
