import { Controller } from 'react-hook-form';
import { InputNumber, Select, Space } from 'antd';

import { QuestionnairesBlocksSettingsMultiSelect } from 'api/questionnairesApi/types/multiSelect.types';

import styles from './MultiSelectSettings.module.scss';
import { QUESTION_SORT_OPTIONS } from 'shared/constants/questionSortOptions';

interface Props extends QuestionnairesBlocksSettingsMultiSelect {
  control: any;
  errors: any;
}

const MultiSelectSettings = ({ control, errors }: Props) => {
  return (
    <Space direction="vertical" size={16}>
      <Space size={8}>
        <span>Сортировка:</span>
        <Controller
          control={control}
          render={({ field }) => <Select className={styles.select} {...field} options={QUESTION_SORT_OPTIONS} />}
          name="MULTI_SELECT.settings.sort_type"
        />
      </Space>
      <Space size={8}>
        <span>Мин. количество ответов:</span>
        <Controller
          control={control}
          render={({ field }) => (
            <InputNumber
              className={styles.select}
              {...field}
              status={!!errors?.MULTI_SELECT?.settings?.min_answers ? 'error' : ''}
              min={0}
              max={100000}
            />
          )}
          name="MULTI_SELECT.settings.min_answers"
        />
      </Space>
      <Space size={8}>
        <span>Макс. количество ответов:</span>
        <Controller
          control={control}
          render={({ field }) => (
            <InputNumber
              className={styles.select}
              status={!!errors?.MULTI_SELECT?.settings?.max_answers ? 'error' : ''}
              {...field}
              min={0}
              max={100000}
            />
          )}
          name="MULTI_SELECT.settings.max_answers"
        />
      </Space>
    </Space>
  );
};

export default MultiSelectSettings;
