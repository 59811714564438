import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Col, Input, Popconfirm, Row, Space, Switch } from 'antd';
import Button from 'components/Button';
import { FormItem, Title } from '../components/helperComponents';
import { ReactComponent as CopySVG } from 'shared/assets/icons/copy.svg';
import { ReactComponent as GarbageSVG } from 'shared/assets/icons/garbage.svg';
import { ReactComponent as PlusSVG } from 'shared/assets/icons/plus.svg';

import styles from '../Access.module.scss';
import * as process from 'process';
import getQuestionnaireLinksAction from '../../../../store/QuestionnairesSlice/actions/getQuestionnaireLinks';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getQuestionnaireLinks } from '../../../../store/QuestionnairesSlice/selectors/getQuestionnaireLinks';
import { handleCopyClick } from './onCopyLink';
import deleteQuestionnaireLinkAction from '../../../../store/QuestionnairesSlice/actions/deleteQuestionnairesLink';
import createQuestionnaireLinkAction from '../../../../store/QuestionnairesSlice/actions/createQuestionnairesLink';
import updateQuestionnaireLinkAction from '../../../../store/QuestionnairesSlice/actions/updateQuestionnaireLink';
import { ROUTES } from '../../../../app/providers/router/routes';

const editedLink = new Set();

export const SettingLinkForm = () => {
  const { id } = useParams();
  const { control, getValues, watch } = useForm();
  const dispatch = useAppDispatch();
  const links = useAppSelector(getQuestionnaireLinks);
  const [publicAccess, setPublicAccess] = useState<boolean>(false);
  const [privateAccess, setPrivateAccess] = useState<boolean>(false);

  const publicLink: any = links?.public_link || {};
  const privateLinks: any[] = links?.private_links || [];

  watch();

  useEffect(() => {
    if (id) {
      dispatch(getQuestionnaireLinksAction(id));
    }
    return () => {
      editedLink.clear();
    };
  }, []);

  useEffect(() => {
    if (publicLink?.id) {
      setPublicAccess(true);
    }
  }, [publicLink?.id]);

  useEffect(() => {
    if (privateLinks?.length) {
      setPrivateAccess(true);
    }
  }, [privateLinks?.length]);

  const onPublicAccessOff = () => {
    setPublicAccess((prevState) => !prevState);
    if (publicLink?.id) {
      dispatch(deleteQuestionnaireLinkAction([publicLink.id]));
    }
  };

  const onPublicAccessOn = () => {
    if (!publicAccess && id) {
      dispatch(createQuestionnaireLinkAction({ formID: String(id) }));
      setPublicAccess((prevState) => !prevState);
    }
  };

  const onPrivateAccessOff = () => {
    if (privateLinks?.length) {
      dispatch(deleteQuestionnaireLinkAction(privateLinks.map((link: any) => link?.id || '')));
    }
    setPrivateAccess((prevState) => !prevState);
  };

  const onPrivateAccessOn = () => {
    if (!privateAccess) {
      setPrivateAccess((prevState) => !prevState);
    }
  };

  const onAddMorePrivateLink = () => {
    if (id) {
      dispatch(
        createQuestionnaireLinkAction({
          formID: id,
          pass: Math.floor(Math.random() * 1000000000).toString(),
        }),
      );
    }
  };

  const onDeletePrivateLink = (ids: string[]) => {
    dispatch(deleteQuestionnaireLinkAction(ids));
  };

  const getAppLink = (text: string | undefined): string => {
    if (!text) {
      return '';
    }
    return `${process.env.REACT_APP_SERVER_URL}${ROUTES.fillForm}/${text}`;
  };

  const onSaveNewPassword = (linkId: string, pass: string) => {
    editedLink.delete(linkId);
    if (id) {
      dispatch(
        updateQuestionnaireLinkAction({
          pass,
          formID: id,
          linkID: linkId,
        }),
      );
    }
  };

  return (
    <>
      <div className={styles.textWrapper}>
        <Title>Настройка доступа по ссылкам</Title>
      </div>
      <FormItem label="Публичный доступ">
        <Popconfirm
          title="Подтвердите действие"
          description="Отключить публичный доступ к заполнению анкеты?"
          onConfirm={onPublicAccessOff}
          disabled={!publicAccess}
        >
          <Switch className={styles.switch} checked={publicAccess} onChange={onPublicAccessOn} />
        </Popconfirm>
      </FormItem>
      {publicAccess && (
        <Row gutter={[10, 0]} style={{ width: '100%' }}>
          <Col flex="60%">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Space direction="vertical" style={{ width: '100%', marginRight: 8 }}>
                <Input autoComplete={'off'} readOnly className={styles.inputCopy} value={getAppLink(publicLink.key)} />
              </Space>
              <CopySVG style={{ cursor: 'pointer' }} onClick={() => handleCopyClick(getAppLink(publicLink.key))} />
            </div>
          </Col>
        </Row>
      )}
      <FormItem style={{ marginBottom: privateAccess ? 10 : 0 }} label="Доступ по паролю">
        <Popconfirm
          title="Подтвердите действие"
          description="Отключить доступ по паролю к заполнению анкеты?"
          onConfirm={onPrivateAccessOff}
          disabled={!privateAccess}
        >
          <Switch className={styles.switch} checked={privateAccess} onChange={() => onPrivateAccessOn()} />
        </Popconfirm>
      </FormItem>
      {privateAccess && (
        <Row gutter={[10, 0]} style={{ width: '100%' }}>
          <Col flex="auto">
            {privateLinks.map((privateLink: any) => (
              <div
                key={privateLink.id}
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <Space direction="vertical" style={{ width: '100%', marginRight: 25 }}>
                  <span className={styles.text}>Ссылка</span>
                  <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <Input
                      autoComplete="off"
                      readOnly
                      className={styles.inputCopy}
                      value={`${getAppLink(privateLink?.key)}?isSecure=true`}
                    />
                    <CopySVG
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleCopyClick(`${getAppLink(privateLink?.key)}?isSecure=true`)}
                    />
                  </div>
                </Space>

                <Space direction="vertical" style={{ width: '100%' }}>
                  <span className={styles.text}>Пароль</span>

                  <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <Controller
                      control={control}
                      name={privateLink.id}
                      defaultValue={privateLink.pass}
                      render={({ field }) => (
                        <Input.Password
                          autoComplete="off"
                          className={styles.inputCopy}
                          style={{ maxWidth: 135 }}
                          {...field}
                          onChange={(e) => {
                            editedLink.add(privateLink.id);
                            field?.onChange(e);
                          }}
                        />
                      )}
                    ></Controller>
                    <Button
                      onClick={() => onSaveNewPassword(privateLink.id, getValues(privateLink.id))}
                      disabled={!editedLink.has(privateLink.id)}
                    >
                      Сохранить
                    </Button>
                    <Popconfirm
                      title="Подтвердите действие"
                      description="Удалить ссылку?"
                      onConfirm={() => onDeletePrivateLink([privateLink.id])}
                      disabled={!privateLinks.length}
                    >
                      <GarbageSVG style={{ cursor: 'pointer', flexShrink: 0 }} />
                    </Popconfirm>
                  </div>
                </Space>
              </div>
            ))}
            <Space direction="vertical">
              <Col onClick={onAddMorePrivateLink} flex="auto" className={styles.addMoreContainer}>
                <PlusSVG className={styles.addMore} />
                <span>Добавить еще</span>
              </Col>
            </Space>
          </Col>
        </Row>
      )}
    </>
  );
};
