import { toastify } from '../../../../utils/toastify';

const copyTextToClipboard = async (text: string) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
};

// onClick handler function for the copy button
export const handleCopyClick = (copyText: string) => {
  // Asynchronously call copyTextToClipboard
  copyTextToClipboard(copyText)
    .then(() => {
      toastify('info', copyText);
    })
    .catch((_) => {
      toastify('error', 'Ошибка');
    });
};
