// types
import { QuestionnairesType } from 'api/questionnairesApi/types';
// Components
import BinaryChoiceSubCard from 'components/questionnaires/SubCards/BinaryChoiceSubCard';
import InformationSubCard from 'components/questionnaires/SubCards/InformationSubCard';
import DropdownSubCard from 'components/questionnaires/SubCards/DropdownSubCard';
import RatingSubCard from 'components/questionnaires/SubCards/RatingSubCard';
import GradeScaleSubCard from 'components/questionnaires/SubCards/GradeScaleSubCard';
import MultiSelectSubCard from 'components/questionnaires/SubCards/MultiSelectSubCard';

export const chooseSubContent = (type: QuestionnairesType, props: any, dictValue?: any) => {
  switch (type) {
    case 'BINARY':
      return <BinaryChoiceSubCard />;
    case 'DROPDOWN':
      return <DropdownSubCard {...props} dictValue={dictValue} />;
    case 'MULTI_SELECT':
      return <MultiSelectSubCard {...props} dictValue={dictValue} />;
    case 'GRADE_SCALE':
      return <GradeScaleSubCard {...props} />;
    case 'RATING':
      return <RatingSubCard {...props} />;
    case 'INFO':
      return <InformationSubCard />;
    case 'DATE':
    case 'SHORT_TEXT':
    case 'LONG_TEXT':
    case 'NUMBER':
      return null;
  }
};
