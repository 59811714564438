import axios, { AxiosError, AxiosResponse } from 'axios';
import instance from 'connection';
import { QuestionnairesID } from 'api/questionnairesApi/types';
import { QuestionnaireLink } from 'api/questionnaireAccessApi/types';

// Список ссылок на анкету
export const getQuestionnaireLink = async (formID: QuestionnairesID, thunkAPI?: any) => {
  try {
    return await instance.get<void, AxiosResponse<QuestionnaireLink[]>>(`/constructor/links?formId=${formID}`);
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

interface ICreateQuestionnaireLinkData {
  formID: QuestionnairesID;
  pass?: string;
}

// создание ссылок на анкету
export const createQuestionnaireLink = async (data: ICreateQuestionnaireLinkData, thunkAPI?: any) => {
  try {
    return await instance.post<void, AxiosResponse<QuestionnaireLink[]>>(`/constructor/links`, {
      form_id: data.formID,
      active: true,
      max_answers: 0,
      pass: data?.pass,
    });
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

// Удалить ссылку на анкету
export const deleteQuestionnaireLink = async (linkIDs: string[], thunkAPI?: any) => {
  try {
    const params = linkIDs.map((id) => `ids=${id}`).join('&');
    return await instance.delete<never, AxiosResponse<never>>(`/constructor/links?${params}`);
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};

export const updateQuestionnaireLink = async (
  data: { pass?: string; linkID: string; formID: string },
  thunkAPI?: any,
) => {
  try {
    return await instance.put<void, AxiosResponse<QuestionnaireLink[]>>(`/constructor/links/${data.linkID}`, {
      form_id: data.formID,
      active: true,
      max_answers: 0,
      pass: data?.pass,
    });
  } catch (e: unknown | AxiosError) {
    if (axios.isAxiosError(e)) {
      return thunkAPI.rejectWithValue(e.response);
    }
    const error = e as Error;

    console.log(error);
  }
};
