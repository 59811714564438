import { ReactComponent as StatusSVG } from 'shared/assets/icons/status_menu.svg';
import clsx from 'clsx';

import styles from '../ItemQuestionnairesPage/ItemQuestionnairesPage.module.scss';

export type ContentListTitleKeys = 'questions' | 'access' | 'general_information' | 'results';
export type QuestionnairesStatus = 'ACTIVE' | 'DRAFT' | 'FINISHED';

export const tabListTitle = [
  {
    key: 'questions',
    label: 'Вопросы',
  },
  {
    key: 'access',
    label: 'Доступ',
  },
  {
    key: 'results',
    label: 'Результаты',
  },
];

export const dontShowButtonsForTab = ['access', 'results'];

export const options = [
  {
    value: 'ACTIVE',
    label: (
      <div className={styles.iconWrapper}>
        <StatusSVG className={clsx(styles.iconItem, styles.activeColor)} />
        <span className={styles.text}>Активно</span>
      </div>
    ),
  },
  {
    value: 'DRAFT',
    label: (
      <div className={styles.iconWrapper}>
        <StatusSVG className={clsx(styles.iconItem, styles.disabledColor)} />
        <span className={styles.text}>Отключено</span>
      </div>
    ),
  },
  {
    value: 'FINISHED',
    label: (
      <div className={styles.iconWrapper}>
        <StatusSVG className={clsx(styles.iconItem, styles.successColor)} />
        <span className={styles.text}>Завершено</span>
      </div>
    ),
  },
];
